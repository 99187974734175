import { Dispatch } from "redux";
import {postRequest, getRequest, putRequest} from "../utils/http";
import * as T  from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;

/**JSON: {Type": "chat", "Isviewed": 0, "Data": {}, "OrgId": "fdsaf-dfas-fdas-ss",
"EmployeeGuid":string"
}*/

export const SaveOrgFile = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/org/Create`,params)
            .then((resp: any) => {
                if (resp.status == 'error') {
                    dispatch({
                        type: T.CREATE_ORGFILE_FAILURE,
                        payload: resp
                    })
                } else {
                    dispatch({
                        type: T.CREATE_ORGFILE_SUCCESS,
                        payload: "orgfile success"
                    });
                }
            }).catch(e => {                
                dispatch({
                    type: T.CREATE_ORGFILE_FAILURE,
                    payload: { "status": "error", "message": e}
                })
            });
    } catch (e) {
        dispatch({
            type: T.CREATE_ORGFILE_FAILURE,
            payload: { "status": "error", "message": e}
        })
    }
};

export const GetOrgFiles = (orgGuid: string, type:string, active: any = null) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/org/GetFiles?OrgGuid=${orgGuid}&Type=${type}` + (active != null ? `&Active=${active}` : ``))
       .then((resp: any)=>{
            dispatch({
                type: T.GET_ORGFILES_SUCCESS,
                payload: resp.result
            });           
            dispatch({
                type: T.REMOVE_LOADING
            })
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }

 export const DeleteOrgFileByFileId = (FileId: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/org/delete?fileId=` + FileId)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: "orgfile updated successfully."
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
}

 
