export class empData {
    EmployeeId: number = 0;
    EmpGuid: string = "";
    FirstName: string = "";
    LastName: string = "";
    Email: string = "";
    Mobile: string = "";
    TeamsUserId: string = "";
    ProfileImageURL: string = "";
    AVSettings: string = "";
    About: string = '';
    DateOfBirth: string = '';
    //AVSettings: string = "{\"Meeting\":{\"showTaskBar\":false,\"allowDrawing\":false,\"selectScreenToShare\":false},\"ClickedToRoom\":{\"showTaskBar\":false,\"allowDrawing\":false,\"selectScreenToShare\":false},\"pulledToRoom\":{\"showTaskBar\":false,\"allowDrawing\":false,\"selectScreenToShare\":false}}";
}

export class Meeting {
    showTaskBar: boolean = false
    allowDrawing: boolean = false
    selectScreenToShare: boolean = false
}

export class ClickedToRoom {
    showTaskBar: boolean = false
    allowDrawing: boolean = false
    selectScreenToShare: boolean = false
}

export class PulledToRoom {
    showTaskBar: boolean = false
    allowDrawing: boolean = false
    selectScreenToShare: boolean = false
}

export class AVSettings {
    Meeting: Meeting = new Meeting();
    ClickedToRoom: ClickedToRoom = new ClickedToRoom();
    pulledToRoom: PulledToRoom = new PulledToRoom();
}

export class FilesTableBody {
    PageNo: number = 1;
    PageSize: number = 8;
    SortColumn: number = 0;
    SortOrder: string = "desc";
    Search: string = "";
}