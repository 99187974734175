
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
function WellnessPartners(){
    const media = useSelector((store: any) => store.MediaReducer);
    return (<>
    <section className="myProfilePage position-relative d-flex mih-100">
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={"/hangoutroom/"+media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                            <div className="cRTitle p-3"><h5>Health & Wellness / Wellness Partners</h5></div>
                        </div>
                    </div>
                    <h1 style={{margin:"auto",color:"white",marginTop:"200px"}}>Coming Soon!</h1>
                </div> 
            </div>
        </div> 
    </section>
    </>);
}
export default WellnessPartners;