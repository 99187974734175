import React, {useEffect, useState} from 'react';
import {  } from 'react-color';
import { CustomPicker, ChromePicker, Color} from 'react-color';

function ColorPicker(props: any) {

    return (
        <>{props.show?<div className="colorDiv">
                <ChromePicker color={props.color} onChange={props.onChange} />
                {/* <button className="btn btn-primary" onClick={props.onClose}>Ok</button> */}
            </div>
        :''}</>
    );
}

export default CustomPicker(ColorPicker);