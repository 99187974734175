import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { SaveNewPassword } from "../../services/UserActions";
import { globalStore } from "../../store/global_store";
import { useNavigate, useParams } from "react-router-dom";
import { removeSuccessStatus } from "../../services/ContactsService";
import { Loader } from "../../components/Loader";
import { t } from '../../i18n';

interface Password {
    Password: string;
    ConfirmPassword: string;
    SaveUpdatedPassword: boolean;
    PasswordToken: string;
}
interface formError {
    message: string;
}
function ResetPassword(props: any) {
    const [password, setPassword] = useState({ SaveUpdatedPassword: false, Password: '', ConfirmPassword: '' } as Password)
    const [errors, setErrors] = useState({} as formError);
    const [showError, setShowError] = useState(false)
    const history = useNavigate();
	let params: any = useParams();

    const ResetPwd = (e: any) => {
        var pwdRegEx = /(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])/;
        if (password.Password != password.ConfirmPassword) {
            setErrors({ message: 'New Password & Confirm Password did not match' });
        }
        else if ((password.Password.length <= 6 || password.Password.length > 20) && (password.ConfirmPassword.length <= 6 || password.ConfirmPassword.length > 20)) {
            setErrors({ message: 'Password must not exceed 20 characters and should be more than 6 characters.' });
        }
        else if (!pwdRegEx.test(password.Password)) {
            setErrors({ message: 'Password must contain at least one lower case, one upper case, one digit and one special character' });
        }
        else if ((password.Password == password.ConfirmPassword) && (password.Password.length > 6 || password.Password.length <= 20)) {
            setErrors({ message: '' });
            const formData = new FormData();
            formData.append('Password', password.Password);
            formData.append('PasswordToken', password.PasswordToken);
            props.SaveNewPassword(formData);
        }
        e.preventDefault();
    }

    // useEffect(() => {

    // }, [password])

    useEffect(() => {
        if (props.user.success) {
            globalStore.showToast({
                type: 'Password',
                title: "Reset Password",
                message: props.user.success
            });
            props.RemoveSuccessStatus();
            if (props.user.success === 'Password Updated Successfully') {
                if (props.user.EmailConfirmed == 0)
                    setTimeout(() => history('/pod'), 1000);
                else
                    setTimeout(() => history('/login'), 1000);
            }
        }
    }, [props.user.success])


    useEffect(() => {
        setPassword({ ...password, PasswordToken: params.tokenId });
    }, [])

    const errormsg = (<div className="alert alert-danger col-12">
        <strong>Error!</strong> {errors.message}
    </div>);
    return (<>
        <section className="bgCadet h-100 overflow-hidden position-relative d-flex">
            <div className="container align-self-center">
                {/* <PublicHeader title="Login" /> */}
                <div className="row">
                    {errors.message && errormsg}
                    <div className="col-sm-4 m-auto">
                        <div className="card loginBox">
                            <div className="card-body pl-5 pr-5">
                                <div className="row">
                                    <div className="col-12 text-center"> <img className="loginLogo" src="/assets/media/images/logo-sm.png" alt="Sute" /> </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-4 mt-4 text-center text-white">
                                        <h5 className="font-weight-normal">{t('resetpassword.reset_password')}</h5>
                                        <p>{t('resetpassword.welcome_reset')}</p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Npassword" className="text-light small m-0">{t('resetpassword.new_password')}</label>
                                    <input className="form-control" onChange={(e: any) => [setPassword({ ...password, Password: e.target.value, SaveUpdatedPassword: false }), setErrors({ message: '' })]} type="password" name="Npassword" placeholder={t('resetpassword.new_password')} minLength={6} maxLength={20} required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="Cpassword" className="text-light small m-0">{t('resetpassword.confirm_password')}</label>
                                    <input className="form-control" onChange={(e: any) => [setPassword({ ...password, ConfirmPassword: e.target.value, SaveUpdatedPassword: false }), setErrors({ message: '' })]} type="password" name="Cpassword" placeholder={t('resetpassword.confirm_password')} minLength={6} maxLength={20} required />
                                </div>
                                <div className="form-group mt-4">
                                    <button onClick={ResetPwd} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-right">{t('button.submit')}{props.user.loading ? <Loader /> : null}</button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    SaveNewPassword: (params: Password) => {
        dispatch(SaveNewPassword(params))
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
