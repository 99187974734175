import { GetPilanthropyTracksList } from "../../services/MediaService";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

var playIcon: '<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.061 39.122C30.5881 39.122 39.122 30.5881 39.122 20.061C39.122 9.5339 30.5881 1 20.061 1C9.5339 1 1 9.5339 1 20.061C1 30.5881 9.5339 39.122 20.061 39.122Z" stroke="white" stroke-width="2"/><path d="M17 15V25.4L25.916 20.2L17 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'

function Philanthropy(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const [pilanthropyTracklist, setPilanthropyTracklist] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState({ id: -1, url: '', name: '' });
    const [showPilanthropySideNav, setShowPilanthropySideNav] = useState(true);

    useEffect(() => {
        dispatch(GetPilanthropyTracksList(8));
    }, [])

    useEffect(() => {
        if(Array.isArray(media.pilanthropyTrackList)){
        media.pilanthropyTrackList.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setPilanthropyTracklist(media.pilanthropyTrackList || []);
    } else if (Array.isArray(media.pilanthropyTrackList.result)){
        media.pilanthropyTrackList.result.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setPilanthropyTracklist(media.pilanthropyTrackList.result || []);
    }
    }, [media.pilanthropyTrackList])

    const PlayTrack = (obj: any, index: number) => {
        setSelectedTrack({ id: index, url: obj.TrackURL, name: obj.TrackName });
    }

    const PauseTrack = (obj: any, index: number) => {
        setSelectedTrack({ id: -1, url: obj.TrackURL, name: obj.TrackName });
    }

    const closePilanthropySideNav = ()=>{
        setShowPilanthropySideNav(!showPilanthropySideNav);
    }

    function onplayerNext(obj: any): void {
        var id = selectedTrack.id + 1;
        if (id < media.pilanthropyTrackList.length) {
            var nextTrack = media.pilanthropyTrackList[id];
            setSelectedTrack({ id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    function onplayerPrevious(obj: any): void {
        var id = selectedTrack.id - 1;
        if (id >= 0) {
            var nextTrack = media.pilanthropyTrackList[id];
            setSelectedTrack({ id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            {/* <HangoutMenu /> */}
            <div className="open-sidemenu" onClick={closePilanthropySideNav}>
                <img src="/assets/media/images/leftarrow.png" />
            </div>
            <div className={ showPilanthropySideNav ? "SideNav hangSideNav text-left d-block position-fixed" : "SideNav hangSideNav text-left d-none position-fixed" }>
                <div className="sideNavHeader">
                    <div className="CloseNav" onClick={closePilanthropySideNav}><img src="assets/media/svg/x-close.svg" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row small">
                        <Link to="/learn" className="col-12 mb-3 text-center">
                            <div className="SidenavHex">

                                <img src="./assets/media/images/hangouts/suteSelectionsIcon.svg" />
                                <p className="pt-2">Sute<br />Selections</p>

                            </div>
                        </Link>
                        <div className="col-12 text-center">
                            <div className="SidenavHex">
                                <img src="./assets/media/images/hangouts/podcastsIcon.svg" />
                                <p className="pt-2">Podcasts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-9">
                        <div className="cRoomHeader pt-2"><div className="cRsuteLogo align-self-center"><Link to="/learn"><img src="/assets/media/images/logo.png" /></Link></div><div className="cRTitle p-3"><h5>Philanthropy</h5></div></div>
                        <div className="mediaGrid">
                            {(pilanthropyTracklist || []).map((obj: any, index: number) => {
                                return (
                                    <div className="mediaItem">
                                        <div className="mediaImg">
                                            <img src={obj.Thumbnail} />
                                        </div>
                                        <div className="mediaAction">
                                            {index != selectedTrack.id ? <img onClick={(e: any) => PlayTrack(obj, index)} src="/assets/media/images/hangouts/media/play.svg" />
                                                : <img onClick={(e: any) => PauseTrack(obj, index)} src="/assets/media/images/hangouts/media/pause.svg" />}
                                        </div>
                                        <div className="mediaDetails">
                                            <h4 className="mt-3 text-white h6">{obj.TrackName}</h4>
                                            <p className="text-muted fs12">{obj.Description}</p>
                                            <audio controls className="d-none">
                                                <source src={obj.TrackURL} />
                                            </audio>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <AudioPlayer className="SuteMediaPlayer HangoutMediaPlayer small" layout="stacked-reverse" customVolumeControls={[]} customAdditionalControls={[]} header={selectedTrack.name} src={selectedTrack.url} onClickPrevious={e => onplayerPrevious(e)} onClickNext={e => onplayerNext(e)} showSkipControls={true} customIcons={{ play: playIcon }} />
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}


export default Philanthropy;