import { Dispatch } from "redux";
import axios from "axios";
import { IApiResponse } from "../interfaces/IApiResponse";
import { UpdateStorage, UpdateStoreageValue } from './../services/StorageService';
import { LOGIN_SUCCESS, LOGIN_FAILURE, ADD_LOADING, SET_AUTHENTICATED,CLEAR_STORE } from "./AppTypes"
import { AuthDispatchTypes } from "./AuthDispatchTypes";
import jwt_decode  from "jwt-decode";
////import {chatClient} from "../services/chat-api";
import {postRequest, getRequest} from "../utils/http";
const api_host = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;
const api_auth_port = process.env.REACT_APP_ACCOUNTSAPI_PORT as string;
import queryString from 'query-string';
const customErrorMessage = "Invalid credentials/User does not exist.";

export async function SendOtp(body: any) {
    const res = await axios.post(`/api/auth/SendOtp`, body);
    return res.data as IApiResponse;
};

export async function Signup(body: any) {
    body.AuthType = "ocustomtp"
    const res = await axios.post(`${api_host}${api_auth_port}/api/user/Signup`, body);
    return res.data as IApiResponse;
};
const checkOldUserData = (empId: string, dispatch: Function) => {
    try {
        console.log('after login', empId)
      const serializedState = localStorage.getItem("state");
      if (!serializedState) {
        return;
      }else {
            const pstate = JSON.parse(serializedState);
            console.log('after login', pstate.EmpGuid)
            if(pstate.EmpGuid !== empId) {
                localStorage.removeItem("state");
                dispatch({
                    type: CLEAR_STORE
                })
            }
        }
    } catch(err) {
      return undefined;
    }
  };
export const Login = (body: any, history: any) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
        dispatch({
            type: ADD_LOADING
        })
        var querystring = require('query-string');
        const res = await axios.post(`${api_host}${api_auth_port}/connect/token`, querystring.stringify({
            username: body.username, //gave the values directly for testing
            password: body.password,
            grant_type: 'password',
            scope: 'api1 offline_access',
            client_id: 'ro.client',
            client_secret: 'secret',
            user_type_name: 'employee',
            auth_type: 'custom'
        }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        console.log(res);
        const decoded: any = jwt_decode(res.data.access_token);
        //console.log(decoded);
        checkOldUserData(decoded.EmpGuid, dispatch);
        UpdateStorage(decoded);
        UpdateStoreageValue('authType','custom');
        UpdateStoreageValue('webtoken',res.data.access_token);
        //UpdateStorage('{ "authType" : "custom" }');
        //(decoded.EmpGuid, decoded.OrgGuid);       
        dispatch({
            type: LOGIN_SUCCESS,
            payload: decoded
        });
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
        if(body.Signup){
            history('/companySetup');
        }else{
            // if (decoded.EmailConfirmed == 0 && decoded.UserTypeId == 2){
            if (decoded.EmailConfirmed == 0){
                history('/reset-password/'+decoded.token);
            }
            else{
                history('/pod');
            }
        }
    } catch (err) {
        const e = err as any
        console.log(e);
        dispatch({
            type: LOGIN_FAILURE,
            payload: {message: e.response?.data?.error_description||customErrorMessage}
        })
    }
};

export const LoginWithTeams = (body: any, history: any) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    
    try {
        dispatch({
            type: ADD_LOADING
        })
        var querystring = require('query-string');
        const res = await axios.post(`${api_host}${api_auth_port}/connect/token`, querystring.stringify({
            username: body.username, //gave the values directly for testing
            //password: body.password,
            social_token: body.accesstoken,
            grant_type: 'password',
            scope: 'api1 offline_access',
            client_id: 'ro.client',
            client_secret: 'secret',
            user_type_name: 'employee',
            auth_type: 'teams'
        }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        console.log(res);
        const decoded:any = jwt_decode(res.data.access_token);
        UpdateStorage(decoded);
        UpdateStoreageValue('authType','teams');
        UpdateStoreageValue('webtoken',res.data.access_token)
        //UpdateStorage('{ "authType" : "custom" }');
       // chatClient.init(decoded?.EmpGuid, decoded.OrgGuid);
        //history.push('/pod');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
        if(localStorage.getItem("msAccessToken")){
            const form = new FormData();
            form.append("accessToken",  localStorage.getItem("msAccessToken") as string);
            form.append("empGuid", decoded?.EmpGuid);
            postRequest(`${api_host}:${api_port}/api/teams/SyncTeamsData`, form as any)
            .then((resp: any)=>{
                console.log("resp after access login post to api ", resp);
            }).catch(e=>{
                console.log(e);
            });
        }
        dispatch({
            type: LOGIN_SUCCESS,
            payload: decoded
        })
        history('/pod')
    } catch (err) {
        const e = err as any;
        console.log('login failed>>', e)
        dispatch({
            type: LOGIN_FAILURE,
            payload: {message: e.response?.data?.error_description||customErrorMessage}
        })
    }
};

export const VerifyOtp = (body: any, history: any) => async (dispatch: Dispatch<AuthDispatchTypes>) => {
    try {
        dispatch({
            type: ADD_LOADING
        })
        var querystring = require('query-string');
        const res = await axios.post(`https://api.warrous.com:19081/gh.ms.auth/gh.ms.auth.api/connect/token`, queryString.stringify({
            username: body.PhoneNumber, //gave the values directly for testing
            password: 'nopassword',
            grant_type: 'password',
            scope: 'api1 offline_access',
            client_id: 'ro.client',
            client_secret: 'secret',
            user_type_name: 'customer',
            auth_type: 'otp',
            otp: body.Otp
        }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });

        console.log('not chat Intialised');
        UpdateStorage(res.data);
       // chatClient.init(res.data?.EmpGuid, res.data?.OrgGuid);
        history.push('/')
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })

    } catch (e) {
        dispatch({
            type: LOGIN_FAILURE,
            payload: {}
        })
    }
};

export const SetCurrentUser = (payload: any) => async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('access_token') || ''
    if (accessToken.length > 0) {
       // chatClient.init(localStorage.getItem('EmpGuid') || '', localStorage.getItem('OrgGuid') as string);
        dispatch({ type: SET_AUTHENTICATED, payload: {} });
    }

}



