import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import { useState, useEffect } from "react";
import { MdAttachFile } from "react-icons/md";
import { Modal } from "react-bootstrap";
import * as T from "../../actions/Types";
import { useDispatch } from "react-redux";
import { GetOngoingChallenges, SaveOngoingStatus } from "../../services/WellnessServices";
import { OngoingChallangesDetails } from "../../utils/types";
import { formatDateForInput } from "../../utils/helper";
import { useParams } from "react-router-dom";
import Moment from 'moment';


function OngoingChallengesDetails(props: any) {
  const [ongoingChallengesList, setOngoingChallengesList] = useState([] as OngoingChallangesDetails[]);
  const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [enrollId, setEnrollId] = useState(-1);
  const [rejectReason, setRejectReason] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const { orgGuidParam, stepId } = useParams() as any;
  const empGuid = localStorage.getItem("EmpGuid") || "";
  const [errorMessage,setErrorMessage] = useState("")
  const [erroeMesssgeHide, setErrorMessageHide] = useState(false)
  const [onGoingNewList,setOnGoingNewList] = useState([] as any) 
  const formattedDate = process.env.REACT_APP_DATE_FORMAT 

  const dispatch = useDispatch();

  const handleCloseRejectReasonPopup = () => {
    resetState();
  };

  useEffect(() => {
    dispatch({
      type: T.ADD_LOADING,
    });
    GetOngoingChallenges(orgGuidParam, stepId)
      .then((resp: any) => {
        setOngoingChallengesList([...resp.result]);
        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "ERROR") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
      setErrorMessageHide(false)
  }, [refreshData]);
  
  useEffect(() => {
    if (ongoingChallengesList && ongoingChallengesList.length > 0) {
        const updatedChallenges = ongoingChallengesList.map((challenge: any, i: number) => ({
            ...challenge,
            TotalCount: ongoingChallengesList.length,
            RowNumber: i + 1
        }));

        setOnGoingNewList(updatedChallenges?.filter((onGoings: any) =>
          onGoings.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && onGoings.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );

    } else
    setOnGoingNewList([]);
} , [ongoingChallengesList]);

useEffect(() => {
    if (ongoingChallengesList && ongoingChallengesList.length > 0) {           
        const onGoing = ongoingChallengesList.filter((onGoingChallenge: any) =>
          onGoingChallenge.FirstName.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
        onGoingChallenge.ChallengeName.toLowerCase().includes(filesTableParams.Search.toLowerCase())
    );

        onGoing.forEach((onGoings: any, i: any) => {
          onGoings.TotalCount = onGoing.length, onGoings.RowNumber = i + 1
        });

        setOnGoingNewList(onGoing.filter((onGoings: any) =>
          onGoings.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && onGoings.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );
    }
}, [filesTableParams])

  const handleSaveOngoingStatus = (status: string, reason: string | null, enrollID?: number, isReasonRequired: boolean = true) => {
    debugger
    if (isReasonRequired && !reason) {
      setErrorMessage("Please enter Reason");
      setErrorMessageHide(true);
      return;
  }
    const payload = {
      enrollId: enrollId !== -1 ? enrollId : enrollID,
      status,
      reason,
      empGuid,
    };
    dispatch({
      type: T.ADD_LOADING,
    });
    SaveOngoingStatus(payload)
      .then((resp: any) => {
        if (resp.status === "success") {
          handleCloseRejectReasonPopup();
          setRefreshData((prev) => !prev);
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "ERROR") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });
        console.log(e);
      });
  };

  const resetState = () => {
    setEnrollId(-1);
    setRejectReason("");
    setShowRejectPopup(false);
  };

  function headerRender(ongoingChallengesList : any ) {
    const hasStepId = ongoingChallengesList.some((onGoing : any) => onGoing.EnrollId);
    return hasStepId ? (    
      <tr>
        <th>Employee Name</th>
        <th>Date</th>
        <th>Value</th>
        <th>Description</th>
        <th>Attachments</th>
        <th>Status</th>
      </tr>
    ) : null
  }

  function rowRender(row: OngoingChallangesDetails, index: number) {
    return (
      <tr key={index}>
        <td>{row.FirstName + " " + row.LastName}</td>
        <td>{row.EnrollDate ? Moment(row.EnrollDate).format(formattedDate) : '--'}</td>
        <td>{row.StartingValue ?? '--'}</td>
        <td style={{width:'300px'}}>{row.ChallengeName ?? '--'}</td>
        <td>
          {row.FileUrl ? (
            <a target="_blank" rel="noreferrer" href={row.FileUrl}>
              <MdAttachFile color="#fff" size={20} />
            </a>
          ) : (
            "--"
          )}
        </td>
        <td>
          {row.Status !== null ? (
            row.Status
          ) : (
            <div className="d-flex">
              <button
                className="btn approve-btn"
                onClick={(e) => {
                  handleSaveOngoingStatus("approved", "", row.EnrollId, false);
                }}
              >
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              </button>
              <button
                className="btn reject-btn ml-2"
                onClick={(e) => {
                  setShowRejectPopup(true);
                  setEnrollId(row.EnrollId);
                }}
              >
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  }

  const handleReason = (e : any) =>{
    setRejectReason(e.target.value)
    setErrorMessageHide(false)
    setErrorMessage('')
  }
  return (
    <>
      {/* <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteUser} message={"Do you want to " + deleteText.toLowerCase() + " this user?"} /> */}
      <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
        <div className="container-fluid">
          <div className="row mt-4 justify-content-center">
            <Table
              id="ongoing-challenges"
              data={onGoingNewList || []}
              headerRender={() => headerRender(ongoingChallengesList)}
              rowRender={rowRender}
              onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
              onPageChange={(value: number) =>
                setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })
              }
              rootUrl="/OngoingChallenges"
              header={"Ongoing Wellness initiative Details"}
              subHeader=""
              noDataMsg="No challenges found"
              {...props}
            ></Table>
          </div>
        </div>
        <Modal show={showRejectPopup} onHide={handleCloseRejectReasonPopup} backdrop="static">
          <Modal.Header closeButton className="border-0">
            <Modal.Title className="modal-title text-light">Reject Reason</Modal.Title>
          </Modal.Header>
          <hr className="m-0 border-1 border-white" />
          <Modal.Body>
            <div className="row">
              <div className="col-12 m-auto popup-settings">
                <div className="card bgCadet border-1 ">
                  <div className="card-body p-0">
                    <div className="row m-0">
                      <div className="col-9 px-1">
                        <textarea
                          name="reject-reason"
                          id="reject-reason"
                          className="form-control challenges-textarea w-100"
                          value={rejectReason}
                          onChange={(e) => handleReason(e)}
                        ></textarea>
                      </div>
                      <div className="col-3 px-1 d-flex align-items-center">
                        <button
                          type="button"
                          className="btn btn-SeaGreen w-100 px-3"
                          onClick={(e) => {
                            handleSaveOngoingStatus("rejected", rejectReason,undefined,true);
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      {erroeMesssgeHide && (<div className="alert alert-danger text-center">{errorMessage}</div>)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}

export default OngoingChallengesDetails;
