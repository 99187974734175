import React, { useEffect, useRef, useState } from 'react';
import './toast.scss';
import {useGlobalState} from '../containers/root-context';
import {globalStore} from '../store/global_store';
import { useNavigate } from 'react-router-dom';
import { StoreClass } from "../store/Store";
import { useSelector } from "react-redux";

export interface SnackbarMessage {
  message: string;
  title?:string;
  link?: string;
  key: number;
}

interface SnackbarsProps {
  duration?: number
}

export default function ConsecutiveSnackbars({
  duration = 2000
}: SnackbarsProps) {

  const globalState = useGlobalState();
  const history = useNavigate();
  const queueRef = React.useRef<SnackbarMessage[]>([]);
  const [messages, setMessages] = useState<SnackbarMessage[]>([]);
  const timerRef = useRef<any>(null);
  const {user} = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));

  useEffect(() => {
    console.log("toast3")
    if (messages.length > 0 && timerRef.current === null) {
      timerRef.current = setTimeout(() => {
        queueRef.current.shift()
        setMessages([...queueRef.current]);
        timerRef.current = null;
      }, duration);
    }
  }, [messages]);

  useEffect(() => {
    console.log("toast4")
    if (queueRef.current && globalStore.state.toast.message) {
      queueRef.current.push({
        message: globalStore.state.toast.message,
        title: globalStore.state.toast.title,
        link: globalStore.state.toast.link,
        key: +Date.now()
      })
      setMessages([...queueRef.current]);
    }
  }, [globalStore.state.toast]);

  useEffect(() => {
    console.log("toast4")
    if (queueRef.current && user.toast.message) {
      queueRef.current.push({
        message: user.toast.message,
        title: user.toast.title,
        link: user.toast.link,
        key: +Date.now()
      })
      setMessages([...queueRef.current]);
    }
  }, [user.toast]);


  const handleMsgClick = (link: string)=>{
    if(link)
        history(link)
  }
  return (
    <div className="notice-message-container">
      {messages.map((message: SnackbarMessage, idx: number) => 
        <div onClick={()=>handleMsgClick(message.link||"")} key={`${idx}${message.key}`} 
        className={`toast showToast ${message.message}`} role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <strong className="mr-auto"><svg viewBox="0 0 24 24" width="16" height="16"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z" fill="#fff" /></svg> {message.title?message.title:'Message'}</strong>
          </div>
          <div className="toast-body">
            {message.message}
          </div>
        </div>
      )}
    </div>
  )
}
