import React from "react";
import { t } from '../../i18n';

function Inbox(props: any) {
    const closeInboxWindows = () => {
        props.handleShowInbox();
    }
    return (<>
        <div className={props.showInbox ? "SideNav text-left position-fixed" : "SideNav text-left position-fixed d-none"}>
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={closeInboxWindows}> <img src="/assets/media/svg/x-close.svg" /> </div>
            </div>
            <div className="sideNavBody" id="ParticipantsList">
                <div className="row mt-4 small">
                    <div className="col-12 mb-2 text-light font-weight-bold">{t('inbox.inbox')} </div>
                </div>
                <div className="row">
                    <div className="col-12 small ParticipantsSearch">
                        <input className="SuteInputPrimary" type="text" placeholder={t('title.search')} /> </div>
                </div>
                <div className="row mt-3 small" style={{ "overflow": "auto", height: "80%", alignContent: "flex-start" }}>

                    <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary">
                        <div className="float-left">
                            <svg className="ParticipantImage c-pointer" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <pattern id="007" patternUnits="userSpaceOnUse" width="100" height="100">
                                        <image xlinkHref="/assets/media/images/sute_user_default.png" x="-25" width="150" height="100" />
                                    </pattern>
                                </defs>
                                <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#007)"></polygon>
                            </svg>
                        </div>
                        <div className="float-left">
                            <p className="ParticipantName">Harsh Mehtha</p>
                            <p className="ParticipantDesignation">
                                <img className="c-pointer" title="Chat" alt="Chat" src="/assets/media/svg/profile/message.svg" />
                                <img className="c-pointer" title="Direct Call" alt="Direct Call" src="/assets/media/svg/profile/call.svg" />
                                <img className="c-pointer" title="Video Call" alt="Video Call" src="/assets/media/svg/profile/video-call.svg" />
                                <img className="c-pointer" title="Tasks" alt="Tasks" src="/assets/media/svg/profile/tasks.svg" />
                            </p>
                            <div className="favHeart c-pointer active">
                                <svg className="nonActive" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 13.5l-.354.354a.5.5 0 00.708 0L7.5 13.5zM1.536 7.536l-.354.353.354-.353zm5-5l-.354.353.354-.353zM7.5 3.5l-.354.354a.5.5 0 00.708 0L7.5 3.5zm.964-.964l-.353-.354.353.354zm-.61 10.61L1.889 7.182l-.707.707 5.964 5.965.708-.708zm5.257-5.964l-5.965 5.964.708.708 5.964-5.965-.707-.707zM6.182 2.889l.964.965.708-.708-.965-.964-.707.707zm1.672.965l.964-.965-.707-.707-.965.964.708.708zM10.964 1c-1.07 0-2.096.425-2.853 1.182l.707.707A3.037 3.037 0 0110.964 2V1zM14 5.036c0 .805-.32 1.577-.89 2.146l.708.707A4.036 4.036 0 0015 5.036h-1zm1 0A4.036 4.036 0 0010.964 1v1A3.036 3.036 0 0114 5.036h1zM4.036 2c.805 0 1.577.32 2.146.89l.707-.708A4.036 4.036 0 004.036 1v1zM1 5.036A3.036 3.036 0 014.036 2V1A4.036 4.036 0 000 5.036h1zm.89 2.146A3.035 3.035 0 011 5.036H0c0 1.07.425 2.096 1.182 2.853l.707-.707z" fill="#fff"></path></svg>
                                <svg className="Active" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.036 1a4.036 4.036 0 00-2.854 6.89l5.964 5.964a.5.5 0 00.708 0l5.964-5.965a4.036 4.036 0 00-5.707-5.707l-.611.61-.61-.61A4.036 4.036 0 004.035 1z" fill="#fff"></path></svg>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </>);
}


export default Inbox;