import { Dispatch } from "redux";
import {postRequest, getRequest, putRequest} from "../utils/http";
import * as T  from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;

/**JSON: {Type": "chat", "Isviewed": 0, "Data": {}, "OrgId": "fdsaf-dfas-fdas-ss",
"EmployeeGuid":string"
}*/
export const SaveActivity = async (Data: any, Type: string, EmployeeGuid:string) => {
    try {
        Data.fromEmpId = localStorage.getItem("EmpGuid");
        const params: any = {Data: JSON.stringify(Data), Type, 
            EmployeeGuid,
            orgGuid: localStorage.getItem('OrgGuid')}
       postRequest(`${api_domian}${api_port}/api/activity/SaveActivity`, params)
       .then((resp: any)=>{
           console.log('activity saved', resp);         
        }).catch(e=>{
            console.log('error in saving acity', e);
        });
    } catch (e) {
        console.log('error in saving acity', e);
    }
 }
 export const SaveMyActivity = async (params: any)  => {
    try {        
       // const params: any =JSON.stringify(Data);
      return postRequest(`${api_domian}${api_port}/api/activity/SaveActivity`, params)
       .then((resp: any)=>{
           console.log('activity saved', resp);
           return resp;         
        }).catch(e=>{
            console.log('error in saving acity', e);
        });
    } catch (e) {
        console.log('error in saving acity', e);
    }
 }

 export const UpdateActivity = (params: any) => async (dispatch: Dispatch) => {  
    putRequest(`${api_domian}${api_port}/api/activity/UpdateActivity`, params)
    .then((resp: any)=>{
        dispatch({
            type: T.UPDATE_ACTIVITY_SUCCESS,
            payload: params
        });
    }).catch(e=>{
        console.log('error in saving acity', e);
    });
   
 }
export const GetActivities = (empGuid:string) => async (dispatch: Dispatch) => {
    try {              
        getRequest(`${api_domian}${api_port}/api/activity/GetActivities?empGuid=${empGuid}`)
       .then((resp: any)=>{        
            dispatch({
                type: T.GET_ACTIVITY_SUCCESS,
                payload: resp.result
            });           
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }
 
 export const GetFromActivitie = async (empGuid: string)  =>  {         
    return getRequest(`${api_domian}${api_port}/api/activity/GetActivitiesByFromId?EmployeeGuid=${empGuid}`)
   .then((resp: any)=>{          
           return resp.result
                
    }).catch(e=>{            
            return e          
    });

}
 export const GetToActivitie = async (empGuid: string) =>  {              
      return  getRequest(`${api_domian}${api_port}/api/activity/GetActivitiesByToId?EmployeeGuid=${empGuid}`)
       .then((resp: any)=>{          
               return resp.result
                    
        }).catch(e=>{            
                return e          
        });
    
    }
 
 export const GetFeedbackTypes = () => async (dispatch: Dispatch) => {       
        getRequest(`${api_domian}${api_port}/api/Feedback/GetFeedBackTypes`)
       .then((resp: any)=>{
            dispatch({
                type: T.GET_FEEDBACK_TYPES,
                payload: resp.result
            });           
        }).catch(e=>{
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
 }
export const SaveFeedback = async (params: any) =>{
    return postRequest(`${api_domian}${api_port}/api/Feedback/SaveFeedBack`, params)
    .then((resp: any)=>{
        console.log('Feedback saved', resp);
        return resp;
     }).catch(e=>{
         console.log('error in saving Feedback', e);
         return e;
     });
}
