import { postRequest, getRequest } from "../utils/http";

const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;

export const GetBatchGroups = async (orgGuid: string) => {
  try {
    return getRequest(`${api_domian}${api_port}/api/employee/GeEmployeeBatchDetails?orgGuid=${orgGuid}`)
      .then((resp: any) => {
        return resp;
      })
      .catch((e) => {
        console.log(e);

        return "ERROR";
      });
  } catch (e) {
    console.log(e);

    return "ERROR";
  }
};

export const SetFavouritesGroups = async (batchGroupId: string | number, isFavourite: boolean) => {
  try {
    return postRequest(
      `${api_domian}${api_port}/api/employee/UpdateBatchGroupFavourite?batchGroup_id=${batchGroupId}&is_Favourite=${isFavourite}`,
      {}
    )
      .then((resp: any) => {
        return resp;
      })
      .catch((e) => {
        console.log(e);

        return "ERROR";
      });
  } catch (e) {
    console.log(e);

    return "ERROR";
  }
};
export const GetAllEmployeesByOrganisation = async (orgGuid: string) => {
  try {
    return getRequest(`${api_domian}${api_port}/api/employee/GetEmployeebyOrganisationDetails?orgGuid=${orgGuid}`)
      .then((resp: any) => {
        return resp;
      })
      .catch((e) => {
        console.log(e);

        return "ERROR";
      });
  } catch (e) {
    console.log(e);

    return "ERROR";
  }
};
