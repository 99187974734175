import WellnessMenu from "./wellnessMenu";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ShowToast } from "../../services/UserActions";
import { GetGoals, SaveEmployeeGoals } from "./../../services/WellnessServices";
import * as T from "../../actions/Types";
import { useLocation} from "react-router-dom";


function Assessment(props: any) {
    const media = useSelector((store: any) => store.MediaReducer);
    const dispatch = useDispatch();
    const [getGoals, setGetGoals] = useState([] as any);
    const [saveGoals, setSaveGoals] = useState([] as any);
    const [goals, setGoals] = useState(false);
    let EmpGuid = localStorage.getItem('EmpGuid') as string;
    const[handleMenu,setHandleMenu] = useState(true);
    const location = useLocation();
    const loc = location.state;
    
    useEffect(() => {
        if (getGoals.length < 0 || []) {
            dispatch({
                type: T.ADD_LOADING
            })
            GetGoals(EmpGuid)

                .then((resp: any) => {

                    setGetGoals([...resp.result])

                    if (resp.status == "Success") {
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                    else if (resp == "Error") {
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                })
                .catch(e => {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })

                    return "error"
                })
            dispatch({
                type: T.REMOVE_LOADING
            })
        }
    }, [goals])


    const handleGoalsChanges = (GoalsId: any) => {

        const uniqeGoals = getGoals.map((item: any) => {

            if (item.GoalId == GoalsId) {
                return { ...item, IsActive: !item.IsActive }
            }
            return item;
        })

        setGetGoals(uniqeGoals)
        setSaveGoals(uniqeGoals)

        //console.log('onChange target result find method',saveGoalsData)
        // console.log('onChange target result filter method', uniqeGoals)
    };

    const updateGoalsButton = () => {
        console.log("goals data");
        // debugger;
        let saveGoalsId = saveGoals.map((goals: any) => {
            // debugger;
            return { ...goals, empGuid: EmpGuid }

        })

        console.log("button clicking get data object property", saveGoalsId)
        dispatch({
            type: T.ADD_LOADING
        })
        //debugger;
        SaveEmployeeGoals(saveGoalsId)
            .then((resp: any) => {
                if (resp == "success") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                    setGoals(!goals)
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'Goals Updated Success' }) as any)

                }
                else if (resp == "Error") {
                    setGoals(!goals)
                    dispatch(ShowToast({ type: 'Message', title: 'Status', message: 'something Went wrong' }) as any)
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
    }

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            {(handleMenu)?<WellnessMenu type={[{ name: "Wellness hub", link: "/information", image: "assets/media/svg/sute_selections.svg", select: false }, { name: "Wellness initiatives", link: "/challenges", image: "assets/media/svg/wellness.svg", select: false }]} setHandleMenu={setHandleMenu} from={loc}/>:
            <div className="open-sidemenu" onClick={() => { setHandleMenu(true) }}>

                <span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
            </div>}
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={loc ? loc : "/hangoutroom"} ><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                                {/* <div className="cRTitle p-3"><h5>Health & Wellness / Health Dashboard / Goals</h5></div> */}
                                <div className="cRTitle p-3"><h5>Wellness dashboard</h5></div>
                            </div>
                            <div className="col-12 p-0 row m-0" style={{ justifyContent: "end" }}>
                                <div className="main-box w-80 row m-0" >
                                    {/* <div className="col-5">
                                        <h5>CONTROL OF YOUR WELLNESS</h5>
                                        <p>You can't plan where you are going until you know where you are.</p>
                                        <button type="button" className="btn btn-SeaGreen font-weight-bold pl-5 pr-5 w-auto" onClick={updateGoalsButton}>Log/Updates Goals</button>
                                    </div> */}
                                    <div className="col-12" style={{paddingLeft:'15%'}}>
                                      <div className="col-12" style={{paddingLeft:'120px'}}><h5 className="mb-3">WELLNESS GOALS</h5></div>  
                                        {getGoals.map((item: any) =>
                                            <div className="progress-div row m-0" key={item.GoalId}>
                                                <div className="progress-name">
                                                    <span>{item.Name}</span>
                                                </div>
                                                <div className="progressbar-div" >
                                                    <label className="switch" >
                                                        <input type="checkbox" checked={item.IsActive} onChange={() => handleGoalsChanges(item.GoalId)} />
                                                        <span className="slider round">
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="text-right mt-3 opacity color-white">
                        <h4>Under Construction</h4>
                    </div> */}
                </div>
            </div>
        </section>
    </>)
}

export default Assessment;