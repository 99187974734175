import { useSelector } from "react-redux";
import HangoutMenu from "../onboarding/HangoutMenu";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
function CuratedChallenges() {
    const arr = [
        { name: "Content", link: "/information" },
        { name: "Challenges", link: "/challenges" },
        { name: "Classes", link: "/classes" },
    ];
    const media = useSelector((store: any) => store.MediaReducer);
    const [challengesSidenav,setChallengesSidenav] = useState(true);
    return (
        <>
            <section className="myProfilePage position-relative d-flex mih-100">
                {/* {(challengesSidenav)?<HangoutMenu arr={arr} type="Challenges" setChallengesSidenav={setChallengesSidenav}/>:<div className="open-sidemenu" onClick={() => { setChallengesSidenav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>} */}
                <div className="container">
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-10">
                                <div className="cRoomHeader pt-2">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/leaderboard"}>
                                            <img src="/assets/media/images/logo.png" />
                                        </Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Health & Wellness / Wellness Hub / Challenges</h5>
                                    </div>
                                </div>
                                <div className="col-10 ml-auto">
                                    <ul className="challenges-menu">
                                        <li>
                                            <Link  to={"/challengesSteps"} style={{"color":'white'}}>
                                                Step challenge
                                            </Link>
                                        </li>
                                        <li>Sleep Improvement Challenge</li>
                                        <li>Meditation challenge</li>
                                        <li>Daily Workout & Fitness Challenge</li>
                                        <li>Weight loss challenge</li>
                                        <li>Screen Time Reduction Challenge</li>
                                        <li>Stress Reduction Challenge</li>
                                        <li>Nutrition Tracking Challenge</li>
                                        <li>Mindful Breathing Challenge</li>
                                        <li>Yoga Challenge</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default CuratedChallenges;


// import React, { useState, useEffect, useRef } from "react";
// import { Link } from 'react-router-dom';
// import WellnessMenu from "./wellnessMenu";
// import { useSelector } from "react-redux";

// function CuratedChallenges(props: any) {
//     const media = useSelector((store: any) => store.MediaReducer);
//     const [curatedSidenav, setCuratedSidenav] = useState(true);
//     return (<>
//         <section className="myProfilePage position-relative d-flex h-100">
//             {(curatedSidenav) ? <WellnessMenu type={[{ name: "Curated Challenges", link: "/curatedchallenges", image: "/assets/media/svg/sute_selections.svg", select: true }, { name: "Community Challenges", link: "/buddychallenges", image: "/assets/media/images/hangouts/podcastsIcon.svg", select: false }]} setCuratedSidenav={setCuratedSidenav} /> : <div className="open-sidemenu" onClick={() => { setCuratedSidenav(true) }}>

//                 <span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
//             </div>}
//             <div className="container">
//                 <div className="row">
//                     <div className="col-sm-12">
//                         <div className="row">
//                             <div className="col-sm-10 pb-2 mt-3">
//                                 <div className="cRoomHeader pt-2 mb-0">
//                                     <div className="cRsuteLogo align-self-center">
//                                         <Link to={"/hangoutroom/" + media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
//                                     </div>
//                                     <div className="cRTitle p-3">
//                                         <h5>Community & Culture / Challanges / Curated Challenges</h5>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </section>
//     </>
//     );
// }


// export default CuratedChallenges;