import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeSuccessStatus } from "../services/ContactsService";
import { addMembersSendInvitationAction } from '../services/UserActions';
import { StoreClass } from '../store/Store';
import { globalStore } from '../store/global_store';

interface formError {
    message: string;
}
var uids: string[] = [];

function InviteTeam(props: any) {
    const [email, setEmail] = useState('');
    const emailRef = useRef<HTMLInputElement>(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const empId = localStorage.getItem('EmpGuid') || '';
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const history = useNavigate();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({} as formError);


    useEffect(() => {
        if (user.success && user.success === 'SendInvitationsSuccess') {
            globalStore.showToast({
                type: 'Message',
                title: "Invitation",
                message: "Invitation sent successfully"
            });
            dispatch(removeSuccessStatus());
            setEmail('');
            if (emailRef.current) {
                emailRef.current.value = '';
            }
            setBtnDisabled(false);
        }
    }, [user.success]);

    useEffect(() => {
       
        console.log('-------------------------------------- CONTACTS --------------------------------------------',user.contacts);
        const cons = user.contacts as any;
        const contacts   =  Array.isArray(cons) ? cons  : cons?.result;
       // if(Array.isArray(user.contacts)){
            contacts.map((u: any) => {
            uids.push(u.OfficeEmail);
        }
        );
   // }
    
    }, [])

    const sendInviteLink = (event: any) => {
        event.preventDefault();
        if (email.length > 0) {
            if (validateEmail(email)) {
                var isExist = uids.includes(email.trim());
                if (!isExist) {
                    setBtnDisabled(true);
                    const formData = new FormData();
                    formData.append('empGuid', empId);
                    formData.append('emailAddresses', email);
                    dispatch(addMembersSendInvitationAction(formData, history));
                    setErrors({ message: '' });
                }
                else {
                    setErrors({ message: 'Email you entered is already exist in Database' });
                }
            } else {
                console.log("Invalid Email")
                setErrors({ message: 'Enter valid email address' });
            }
        } else {
            setErrors({ message: 'Enter email to send invitation' });
        }
    }

    const validateEmail = (email: string) => {
        var validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return validEmail.test(email);
    }

    const errormsg = (<div className="col-12"><div className="alert alert-danger text-center">
        <strong>Error!</strong> {errors.message}
    </div></div>);

    return (
        <div className="SideNav" id="inviteteamDiv">
            <div className="sideNavHeader">
                <div className="CloseNav" onClick={props.close}>
                    <img src="/assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" />
                </div>
            </div>
            <div className="sideNavBody">
                <div className="row mt-4 small">
                    <div className="col-12 mb-2 text-light">
                        <svg onClick={props.close} enableBackground="new 0 0 64 64" height="20" viewBox="0 0 64 64" width="20" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
                            <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z" /></svg>
                            Invite Team
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-3">
                        <p className="text-light m-0">Invite Your Team</p>
                    </div>
                </div>
                <div className="row mt-3 small">
                    <div className="col-12 mb-2">
                        <input ref={emailRef} value={email} onChange={(e: any) => {
                            e.preventDefault();
                            setErrors({ message: '' })
                            setEmail(e.target.value);
                        }} type="text" className="SuteInputPrimary fs12" placeholder="Enter email" />
                    </div>
                    <div className="col-12 mb-2 mt-1">
                        <button disabled={btnDisabled} onClick={sendInviteLink} className="btn-SeaGreen btn-block btn-sm btn fw600 fs12"><img className="inviteBtnShare" src="/assets/media/svg/share-alt.svg" /> Send Invite</button>
                    </div>
                    {errors.message && errormsg}
                </div>
            </div>
        </div >
    );
}

export default InviteTeam;