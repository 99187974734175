import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { t } from '../../i18n';
import clsx from 'classnames'
import { SetMeetingId } from "../../services/MediaService";
import "./wellness.css"
function WellnessMenu(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const history = useNavigate();
    useEffect(() => {
        if (!media.meetingId) {
            dispatch(SetMeetingId(props.meetingId) as any);
        }
    }, []);
    const handleClose =()=>{
        if(props.setHandleMenu != undefined){
            props.setHandleMenu(false);
        }
        if(props.setHandleSideNav != undefined){
            props.setHandleSideNav(false)
        }
        if(props.setCuratedSidenav != undefined){
            props.setCuratedSidenav(false)
        }
        if(props.setBuddyChallengesSidenav != undefined){
            props.setBuddyChallengesSidenav(false)
        }
        if(props.setChatrouletteSidenav != undefined){
            props.setChatrouletteSidenav(false)
        }
        if(props.setHappyhoursSidenav !=undefined){
            props.setHappyhoursSidenav(false)
        }
    }

    console.log("props.from",props.from)
    return (
        <div className="SideNav hangSideNav text-left d-block position-fixed">
            <div className="CloseNav wellnessmenu-close" >
                <img src="assets/media/svg/x-close.svg" onClick={handleClose}/>
            </div>
            <div className="sideNavBody">
                <div className="row small">
                    <div className="col-12 mb-3 text-center">
                        <Link to={props.type[0].link} state={props.from}>
                            <div className={props.type[0].select ? "SidenavHex wellnessmenu-active" : "SidenavHex"}>
                                <img src={props.type[0].image} />
                                <p className="pt-2">{props.type[0].name}</p>
                            </div>
                        </Link>
                    </div>


                    <div className="col-12 text-center">
                        <Link to={props.type[1].link} state={props.from}>
                            <div className={props.type[1].select ? "SidenavHex wellnessmenu-active" : "SidenavHex"}>
                                <img src={props.type[1].image} />
                                <p className="pt-2">{props.type[1].name}</p>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </div>

    );
}

export default WellnessMenu;