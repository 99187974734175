import { Dispatch } from "redux";
import { postRequest, getRequest } from "../utils/http";
import * as T from "../actions/Types";
import { FilesTableBody } from "../interfaces/EmployeeData";

const baseURL = '/api/employee';
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;
const accounts_api_port = process.env.REACT_APP_ACCOUNTSAPI_PORT as string;
const domain = 'https://localhost';
const port = '8461';

export const GetFolderListByEmpGuid = (empGuid: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetFoldersList?EmpGuid=${empGuid}`)
            //getRequest(`${domain}:${port}/api/File/GetFoldersList?EmpGuid=${empGuid}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_FOLDER_SUCCESS,
                    payload: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_FOLDER_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_FOLDER_FAILURE
        })
    }
};

export const SaveFolder = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/File/SaveFolder`, params)
            //postRequest(`${domain}:${port}/api/File/SaveFolder`, params)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const SaveFile = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/File/UploadFile`, params)
            //postRequest(`${domain}:${port}/api/File/UploadFile`, params)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'File Saved Successfully.'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const UploadNewFiles = (params: any) => async (dispatch: Dispatch) => {
    try {
        
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/File/UploadNewFile`, params)
           // postRequest(`${domain}:${port}/api/File/UploadNewFile`, params)
            .then((resp: any) => {

                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'File Saved Successfully.'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const UploadChatFiles = async (params: any) =>  { 
   
    return postRequest(`${api_domian}${api_port}/api/File/UploadChatFile`, params)
           // postRequest(`${domain}:${port}/api/File/UploadNewFile`, params)
            .then((resp: any) => {

                if(resp.status === 400)
            return [];
            return resp.result;
            }).catch(e => {
                console.log(e);
                return [];
            });
};

export const GetFileListByEmpGuid = (params: FilesTableBody, empGuid: string, folderId: number , SearchObj : any) => async (dispatch: Dispatch) => {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetFilesList?EmployeeGuid=${empGuid}&FolderId=${folderId}&PageNo=${params.PageNo}&PageSize=${params.PageSize}&SortColumn=0&SortOrder=desc&Search=${params.Search}&SearchObj=${SearchObj}`)
            //getRequest(`${domain}:${port}/api/File/GetFilesList?EmployeeGuid=${empGuid}&FolderId=${folderId}&PageNo=${params.PageNo}&PageSize=${params.PageSize}&SortColumn=0&SortOrder=desc&Search=${params.Search}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.GET_FILES_LIST,
                    payload: resp.result
                });
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
};

export const DeleteFile = (empGuid: string, FileId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/DeleteFile?EmpGuid=${empGuid}&FileId=${FileId}`)
            //getRequest(`${domain}:${port}/api/File/DeleteFile?EmpGuid=${empGuid}&FileId=${FileId}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_FOLDER_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_FOLDER_FAILURE
        })
    }
};

export const RenameFile = (params: any) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        postRequest(`${api_domian}${api_port}/api/File/RenameFile`, params)
            //postRequest(`${domain}:${port}/api/File/RenameFile`, params)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const DownloadFile = (file: any) => async (dispatch: Dispatch) => {
    // 
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/DownloadFile?FileId=${file.FileId}`, { responseType: 'blob' })
            //getRequest(`${domain}:${port}/api/File/DownloadFile?FileId=${file.FileId}`, { responseType: 'blob' })
            .then((resp: any) => {
                // 
                const fileName = file.OriginalName;
                let jsonBlob = new Blob([resp])
                const blobUrl = URL.createObjectURL(jsonBlob);
                // Create a link element
                const link = document.createElement("a");
                // Set link's href to point to the Blob URL
                link.href = blobUrl;
                link.download = fileName;
                // Append link to the body
                document.body.appendChild(link);
                // Dispatch click event on the link
                // This is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );
                // Remove link from body
                document.body.removeChild(link);
            }).catch(e => {
                // 
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        // 
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};

export const MoveFileToSelectedFolder = (params: any) => async (dispatch: Dispatch) => {
    // 
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/MoveToFolder?FileId=${params.FileId}&FolderId=${params.FolderId}`)
            //getRequest(`${domain}:${port}/api/File/MoveToFolder?FileId=${params.FileId}&FolderId=${params.FolderId}`)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })

            }).catch(e => {
                // 
                dispatch({ type: T.SET_ERRORS, payload: e });
            });
    } catch (e) {
        // 
        dispatch({ type: T.SET_ERRORS, payload: e });
    }
};


export const DeleteFolder = (empGuid: string, FolderId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/DeleteFolder?EmpGuid=${empGuid}&FolderId=${FolderId}`)
            //getRequest(`${domain}:${port}/api/File/DeleteFolder?EmpGuid=${empGuid}&FolderId=${FolderId}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_FOLDER_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_FOLDER_FAILURE
        })
    }
};

export const SyncGoogleDrive = (email: string, empGuid: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetGoogleDriveFiles?empGuid=${empGuid}&email=${email}`)
            //getRequest(`${domain}:${port}/api/File/GetGoogleDriveFiles?empGuid=${empGuid}&email=${email}`)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_FOLDER_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_FOLDER_FAILURE
        })
    }
};

export const ShareFile = (fileId: string, empGuid: string, Contacts: string , ExtMails :string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/SaveSharedFiles?fileId=${fileId}&toEmployeeGuid=${Contacts}&fromEmployeeGuid=${empGuid}&toExternalMails=${ExtMails}`)
            //getRequest(`${domain}:${port}/api/File/SaveSharedFiles?fileId=${fileId}&toEmployeeGuid=${Contacts}&fromEmployeeGuid=${empGuid}`)
            .then((resp: any) => {
                // ;
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: 'FilesShared'
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.GET_FOLDER_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.GET_FOLDER_FAILURE
        })
    }
};

export const GetSharedFileByContactGuid = (employeeGuid: string, contactGuid: string) => async (dispatch: Dispatch) => {
    try { 
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetFilesSharedToContact?EmployeeGuid=${employeeGuid}&ContactGuid=${contactGuid}`)
            //getRequest(`${domain}:${port}/api/File/GetFilesSharedToContact?EmployeeGuid=${employeeGuid}&ContactGuid=${contactGuid}`)
            .then((resp: any) => {  
                dispatch({
                    type: T.SHARED_FILES_GET_SUCCESS,
                    data: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SHARED_FILES_GET_FALIURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.SHARED_FILES_GET_FALIURE
        })
    }
};

export const GetSharedFileByContact = (employeeGuid: string, contactGuid: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetFilesSharedToContact?EmployeeGuid=${employeeGuid}&ContactGuid=${contactGuid}`)
        //getRequest(`${domain}:${port}/api/File/GetFilesSharedToContact?EmployeeGuid=${employeeGuid}&ContactGuid=${contactGuid}`)
            .then((resp: any) => {
                dispatch({
                    type: T.SAVE_SUCCESS,
                    success: resp
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SET_ERRORS
                })
            });
    } catch (e) {
        dispatch({
            type: T.SET_ERRORS
        })
    }
};

export const GetFileSharedContacts = (employeeGuid: string,) => async (dispatch: Dispatch) => {
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        getRequest(`${api_domian}${api_port}/api/File/GetFileSharedContacts?EmployeeGuid=${employeeGuid}`)
        //getRequest(`${domain}:${port}/api/File/GetFileSharedContacts?EmployeeGuid=${employeeGuid}`)
            .then((resp: any) => {
                dispatch({
                    type: T.SHARED_FILE_CONTACTS_GET_SUCCESS,
                    data: resp.result
                });
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }).catch(e => {
                dispatch({
                    type: T.SHARED_FILE_CONTACTS_GET_FAILURE
                })
            });
    } catch (e) {
        dispatch({
            type: T.SHARED_FILE_CONTACTS_GET_FAILURE
        })
    }
};

export const GetFileSharedByFileID = async (empGuid:any,FileId:any) => {
    try {  
        
     return getRequest(`${api_domian}${api_port}/api/File/GetFileSharedByFileID?EmployeeGuid=${empGuid}&FileId=${FileId}`)
       .then((resp: any)=>{
            return resp
        }).catch(e=>{
            console.log(e);
                return "ERROR";   
        });
    } catch (e) {
        console.log(e);
        return "ERROR";
    }
 }
 export const IsFileDuplicate = async (empGuid: any, FileName: any, Type: any) => {
    try {  
        const resp = await getRequest(`${api_domian}${api_port}/api/File/IsFileDuplicate?EmployeeGuid=${empGuid}&FileName=${FileName}&Type=${Type}`);
        return resp;
    } catch (e) {
        return "ERROR";
    }
}
export const IsFileNameDuplicate = async (empGuid: any, FileName: any, FileId:any) => {
    try {  
        const resp = await getRequest(`${api_domian}${api_port}/api/File/IsFileNameDuplicate?EmployeeGuid=${empGuid}&FileName=${FileName}&FileId=${FileId}`);
        return resp;
    } catch (e) {      
        return "ERROR";
    }
}

export const SaveLabel = async (LabelName: string, IsDefault: boolean, OrgId:any , EmpId:any , IsDeleted:boolean) => {
    try {
        const resp = await postRequest(`${api_domian}${api_port}/api/File/SaveLabel?LabelName=${LabelName}&IsDefault=${IsDefault}&OrgId=${OrgId}&EmpId=${EmpId}&IsDeleted=${IsDeleted}`);
        return resp;
    } catch (e) {      
        return "ERROR";
    }
}
export const GetLabelList = async (OrgId:any , EmpId:any) => {
    try {
        const resp = await getRequest(`${api_domian}${api_port}/api/File/GetLabelList?OrgId=${OrgId}&EmpId=${EmpId}`);
        return resp;
    } catch (e) {      
        return "ERROR";
    }
}

export const SaveEmployeeLabel = async (EmpId:any ,FileId:any,LabelId:any) => {
    try {
        const resp = await postRequest(`${api_domian}${api_port}/api/File/SaveEmployeeLabel?EmployeeId=${EmpId}&FileId=${FileId}&LabelId=${LabelId}`);
        return resp;
    } catch (e) {      
        return "ERROR";
    }
}

export const GetEmployeeLabels = async (EmpId:any , FileId :any) => {
    try {
        const resp = await getRequest(`${api_domian}${api_port}/api/File/GetEmployeeLabels?EmployeeId=${EmpId}&FileId=${FileId}`);
        return resp;
    } catch (e) {      
        return "ERROR";
    }
}