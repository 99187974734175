import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import WellnessMenu from "./wellnessMenu";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from "react-redux";


function BuddyChallenges(props: any) {

    const media = useSelector((store: any) => store.MediaReducer);
    const[buddyChallengeSidenav,setBuddyChallengesSidenav] = useState(true);
    return (<>
        <section className="myProfilePage position-relative d-flex h-100">
            {(buddyChallengeSidenav)?<WellnessMenu type = {[{name:"Curated Challenges",link:"/curatedchallenges",image:"/assets/media/svg/sute_selections.svg",select:false},{name:"Community Challenges",link:"/buddychallenges",image:"/assets/media/images/hangouts/podcastsIcon.svg",select:true}]} setBuddyChallengesSidenav={setBuddyChallengesSidenav}/>: <div className="open-sidemenu" onClick={() => { setBuddyChallengesSidenav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>}
            <div className="SideNav text-left position-fixed d-none">
                <div className="sideNavHeader">
                    <div className="CloseNav"> <img src="assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row mt-4 small">
                        <div className="col-12 mb-2 text-light"> <img src="assets/media/svg/builiten-board/the-well.svg" height="30" /> </div>
                    </div>
                    <div className="row small mt-4">
                        <div className="col-12 text-left text-light">
                            <h6 className="m-0">Build a stronger, healthier immune system</h6>
                            <p className="text-muted mb-2">Most of new line of immune suppliments five unique products - each designed to support your unique health needs, lifestyle, preferances and dietary restrictions. </p>
                        </div>
                    </div>
                    <div className="row mt-2 small c-pointer">
                        <div className="col-12 text-left c-SeaGreen"> View more
                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1" stroke="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-10 pb-2 mt-3">
                                <div className="cRoomHeader pt-2 mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/hangoutroom/"+media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Community & Culture / Challanges / Community Challenges</h5>
                                        {/* <p>All Partners</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'block', width: '85%', padding: 30 }}>
      {/* <h4 className="color-white">Buddy Challenges</h4> */}
      <Tabs defaultActiveKey="fourth">
        <Tab eventKey="first" title="Current/Future">
          Hii, I am 1st tab content
        </Tab>
        <Tab eventKey="second" title="Recent">
          Hii, I am 2nd tab content
        </Tab>
        <Tab eventKey="third" title="Show Declined">
          Hii, I am 3rd tab content
        </Tab>
        <Tab eventKey="fourth" title="Create">
        You currently don't have any challenges or invitations. Create a new challenge for you and your buddies or wait for an invite!
        </Tab>
      </Tabs>
    </div>
                    </div>
                </div>
                <div className="text-right mt-3 opacity color-white watermark">
                    <h4>Under Construction</h4>
                </div>
            </div>
        </section>
    </>
    );
}


export default BuddyChallenges;