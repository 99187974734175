import { useSelector } from 'react-redux';
import HangoutMenu from '../onboarding/HangoutMenu';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
function Classes(){
    const arr = [{name:"Content",link:"/information"},{name:"Challenges",link:"/challenges"},{name:"Classes",link:"/classes"}]
    const media = useSelector((store: any) => store.MediaReducer);
    const[classesSidenav,setClassesSidenav] = useState(true);
    return (<>
    <section className="myProfilePage position-relative d-flex mih-100">
     {(classesSidenav)?<HangoutMenu arr={arr} type="Classes" setClassesSidenav={setClassesSidenav}/>:<div className="open-sidemenu" onClick={() => { setClassesSidenav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>}
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={"/hangoutroom/"+media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                            <div className="cRTitle p-3"><h5>Health & Wellness / Wellness Hub / Classes</h5></div>
                        </div>
                    </div>
                    <h1 style={{margin:"auto",color:"white",marginTop:"200px"}}>Coming Soon!</h1>
                </div> 
            </div>
        </div> 
    </section>
    </>);
}
export default Classes;