"use client";

import { useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  Underline,
  Subscript,
  Superscript,
  Strikethrough,
  Font,
  FontSize,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Base64UploadAdapter,
  BalloonToolbar,
  AutoImage,
  Autoformat,
  Alignment,
  InlineEditor,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";

interface EditorProps {
  value: string;
  onChange: (data: string) => void;
  maxLength?: number;
  backgroundImage?: string;
}

function Editor({ value, onChange, maxLength, backgroundImage }: EditorProps) {
  const editorInstance = useRef<any>(null);

  const handleEditorChange = (event: any, editor: any) => {
    let data = editor.getData();
    if (maxLength && data.replace(/<(.|\n)*?>/g, "").length > maxLength) {
      return;
    }
    onChange(data);
  };

  useEffect(() => {
    if (maxLength && value.length > maxLength) {
      onChange(value.slice(0, maxLength));
    }
  }, [maxLength, value, onChange]);

  useEffect(() => {
    if (editorInstance.current && backgroundImage) {
      const editorElement = document.querySelector(".ck-editor__main");
      if (editorElement) {
        editorElement.classList.add("bulletin-bg");
        editorElement.setAttribute(
          "style",
          `background-image: url(${backgroundImage}) !important; background-size: cover !important; background-position: center; !important`
        );
      }
    }
  }, [backgroundImage]);

  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ],
        },
        toolbar: {
          items: [
            "bold",
            "italic",
            "underline",
            "|",
            // "insertImage",
            // "|",
            "alignment",
            "|",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "undo",
            "redo",
          ],
        },
        placeholder: "",
        plugins: [
          BalloonToolbar,
          Base64UploadAdapter,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Alignment,
          Autoformat,
          AutoImage,
          Bold,
          Essentials,
          Italic,
          Underline,
          Mention,
          Paragraph,
          Undo,
          Subscript,
          Superscript,
          Strikethrough,
          Font,
          FontSize,
          // CustomImagePlugin,
        ],
        initialData: value,
        balloonToolbar: ["bold", "italic", "|", "link", "insertImage"],
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
      }}
      onChange={handleEditorChange}
      onReady={(editor: any) => {
        editorInstance.current = editor;
        if (editorInstance.current && backgroundImage) {
          const editorElement = document.querySelector(".ck-editor__main");
          if (editorElement) {
            editorElement.classList.add("bulletin-bg");
            editorElement.setAttribute(
              "style",
              `background-image: url(${backgroundImage}) !important; background-size: cover !important; background-position: center; !important`
            );
          }
        }
      }}
    />
  );
}

export default Editor;
