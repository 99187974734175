import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import WellnessMenu from "./wellnessMenu";


function SelfHelp(props: any) {
   

    return (<>

        <section className="myProfilePage position-relative d-flex">
        <WellnessMenu />
            <div className="SideNav text-left position-fixed d-none">
                <div className="sideNavHeader">
                    <div className="CloseNav"> <img src="assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row mt-4 small">
                        <div className="col-12 mb-2 text-light"> <img src="assets/media/svg/builiten-board/the-well.svg" height="30" /> </div>
                    </div>
                    <div className="row small mt-4">
                        <div className="col-12 text-left text-light">
                            <h6 className="m-0">Build a stronger, healthier immune system</h6>
                            <p className="text-muted mb-2">Most of new line of immune suppliments five unique products - each designed to support your unique health needs, lifestyle, preferances and dietary restrictions. </p>
                        </div>
                    </div>
                    <div className="row mt-2 small c-pointer">
                        <div className="col-12 text-left c-SeaGreen"> View more
                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1" stroke="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-10 pb-2 mt-3">
                                <div className="cRoomHeader pt-2 mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to="/hangoutroom/"><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Self Help</h5>
                                        {/* <p>All Partners</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <ul id="suteSelMenu" className="HangOutMenu mt-2">
                            
                        
                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Attention</u> <u className="OBhexTitle d-block mt-2">O% Complete</u></span> </p>
                                </Link>
                            </li>
                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-2.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Belong</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-3.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Calm</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-4.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Eating Healthy on a Budget</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-5.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Fuel</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex transform-transilate">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-6.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Giddy-Up!</u> <u className="OBhexTitle d-block mt-2">O% Complete</u></span> </p>
                                </Link>
                            </li>
                  
                            <li className="OBhex transform-transilate">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-7.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Head On</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                 
                            <li className="OBhex transform-transilate">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-8.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Mindful Eating</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex child">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-9.png)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Save-Up</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex clear-left">
                                <Link className="OBhexIn active" to="/" style={{ backgroundImage: 'url(./assets/media/images/slider-image-10.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        {/* <img src="" /> */}
                                        <u className="OBhexTitle">Weight Positive</u><u className="OBhexTitle d-block mt-2">O% Complete</u> </span> </p>
                                </Link>
                            </li>

                           
                            
       
                        </ul>
                        
                    </div>
                </div>
                <div className="text-right mt-3 opacity color-white watermark">
                    <h4>Under Construction</h4>
                </div>
            </div>
        </section>

    </>
    );
}


export default SelfHelp;