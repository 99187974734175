import EventEmitter from 'events';
import {postRequest, getRequest} from "../utils/http";
const api_domian = process.env.REACT_APP_API_HOST as string;
const accounts_api_port = process.env.REACT_APP_ACCOUNTSAPI_PORT as string;
 export class ImageService extends EventEmitter {
    public imageUrl: string;
    constructor () {
        super()
        this.imageUrl = "";
    }

    public postImage(imageUrl:string, callback: Function) {        
        const form = new FormData();
        form.append("imgURL", imageUrl);
        postRequest(`${api_domian}${accounts_api_port}/api/user/Cartoonify`, form as any).then((resp:any)=>{
            callback(resp.result);
        })
    }

    public checkByRequest(requestID:string, callback: Function) {        
        getRequest(`${api_domian}${accounts_api_port}/api/user/GetCartoonifyImage?requestId=${requestID}`)
        .then((response:any) => JSON.parse(response))
        .then((resp:any)=>{
            console.log(resp);
            callback(resp);
        })
    }
}
export const imageService = new ImageService();
