import React, { useEffect, useState } from 'react';

function FileIcon(props: any) {
    const [tclr, setTclr] = useState("#50BEE8");
    useEffect(()=>{
        setTclr('#50BEE8');
        if (props.name == 'PDF' || props.name == 'PPT' || props.name == 'PPTX')
            setTclr('#fd6b6b');
        if(props.name == 'MPG' || props.name == 'MPEG' || props.name == 'MP3')
            setTclr('#f7bc50');
        if(props.name == 'CSV')
            setTclr("#84BD5A");
    }, [props.name]);
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	        viewBox="0 0 512 512" xmlSpace="preserve">
            <path style={{"fill":"#E2E5E7"}} d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
            <path style={{"fill":"#B0B7BD"}} d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
            <polygon style={{"fill":"#CAD1D8"}} points="480,224 384,128 480,128 "/>
            <path style={{"fill": tclr}} d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
                V416z"/>
            <g>
                <text x="50" y="360" fontFamily="Epilogue, sans-serif" fontSize="950%" fill="white"> {props.name} </text>
            </g>
            <path style={{"fill":"#CAD1D8"}} d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
        </svg>
    );
}

export default FileIcon;