import React from 'react';
var playIcon: '<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.061 39.122C30.5881 39.122 39.122 30.5881 39.122 20.061C39.122 9.5339 30.5881 1 20.061 1C9.5339 1 1 9.5339 1 20.061C1 30.5881 9.5339 39.122 20.061 39.122Z" stroke="white" stroke-width="2"/><path d="M17 15V25.4L25.916 20.2L17 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'

function ViewFile(props: any) {
    const GoogleApp_BucketName = process.env.REACT_APP_GoogleApp_BucketName as string;
    const GetPdfUrl = (url:string) => {
        var filename = /[^/]*$/.exec(url);
        if (filename)
            return GoogleApp_BucketName + filename[0];           
        else 
            return "";
    }

    return (
        <div>
            {props.file?.FileName?.match(/jpg$/) || props.file?.FileName?.match(/jpeg$/) || props.file?.FileName?.match(/png$/) || props.file?.FileName?.match(/webp$/) ? <img className="w-100" src={props.file?.FileUrl} /> : undefined }
            { props.file?.FileName?.match(/mp4$/) ? 
                <video width="450" height="300" controls src={props.file.FileUrl} autoPlay>
                    <p>Your browser does not support video file.</p>
                </video>
            : undefined }
            { props.file?.FileName?.match(/pdf$/) ? 
                <object data={GetPdfUrl(props?.file.FileUrl)} type="application/pdf" width="450" height="400">
                    <p>Please click the link <a href={props.file.FileUrl}>to view the PDF!</a></p>
                </object>
            : undefined }
            {props.file?.FileName?.match(/mp3$/) ? 
                <audio controls src={props.file.FileUrl} autoPlay>
                    <p>Your browser does not support audio file.</p>
                </audio>
            : undefined}
        </div>
    );
}

export default ViewFile;