import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import { useState, useEffect } from "react";
import * as T from "../../actions/Types";
import { useDispatch } from "react-redux";
import { GetOngoingStepChallengs } from "../../services/WellnessServices";
import { OnGoingStepChallenges } from "../../utils/types";
import { Link } from "react-router-dom";
import { formatDateForInput } from "../../utils/helper";
import Moment from 'moment';



function OngoingChallenges(props: any) {
  const [ongoingStepChallengesList, setOngoingStepChallengesList] = useState([] as OnGoingStepChallenges[]);
  const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
  const orgGuid = localStorage.getItem("OrgGuid") || "";
  const [ongoingChallengeList,setOngoingChallengeList] = useState([] as any)
  const formattedDate = process.env.REACT_APP_DATE_FORMAT 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: T.ADD_LOADING,
    });
    GetOngoingStepChallengs(orgGuid)
      .then((resp: any) => {
        setOngoingStepChallengesList([...resp.result]);
        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "ERROR") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
  }, []);

  useEffect(() => {
    if (ongoingStepChallengesList && ongoingStepChallengesList.length > 0) {
        const updatedChallenges = ongoingStepChallengesList.map((challenge: any, i: number) => ({
            ...challenge,
            TotalCount: ongoingStepChallengesList.length,
            RowNumber: i + 1
        }));

        setOngoingChallengeList(updatedChallenges?.filter((onGoings: any) =>
          onGoings.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && onGoings.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );

    } else
    setOngoingChallengeList([]);
} , [ongoingStepChallengesList]);

useEffect(() => {
    if (ongoingStepChallengesList && ongoingStepChallengesList.length > 0) {           
        const onGoing = ongoingStepChallengesList.filter((onGoingChallenge: any) =>
          onGoingChallenge.ChallengeName.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
        onGoingChallenge.ChallengeType.toLowerCase().includes(filesTableParams.Search.toLowerCase())
    );

        onGoing.forEach((onGoings: any, i: any) => {
          onGoings.TotalCount = onGoing.length, onGoings.RowNumber = i + 1
        });

        setOngoingChallengeList(onGoing.filter((onGoings: any) =>
          onGoings.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && onGoings.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );
    }
}, [filesTableParams])


  function headerRender(ongoingStepChallengesList : any) {
    // Check if any item in the list has a StepId
    const hasStepId = ongoingStepChallengesList.some((challenge : any) => challenge.StepId);
  
    return hasStepId ? (
      <tr>
        <th>Type</th>
        <th style={{width:'300px'}}>Description</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Participants</th>
      </tr>
    ) : null;
  }

  function rowRender(row: OnGoingStepChallenges, index: number) {
    return (
      <tr key={index}>
        <td>{row.ChallengeType}</td>
        <td style={{width:'300px'}}>{row.ChallengeName}</td>
        <td>{Moment(row.StartDate).format(formattedDate)}</td>
        <td>{Moment(row.EndDate).format(formattedDate)}</td>
        <td>
        {row.EnrollMembers !== 0 ? (
          <Link className="value" to={`/OngoingChallengesDetails/${orgGuid}/${row.StepId}`}>
            {row.EnrollMembers}
          </Link>
          ) : (
           <h5>0</h5> 
          )}
        </td>       
      </tr>
    );
  }

  return (
    <>
      {/* <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteUser} message={"Do you want to " + deleteText.toLowerCase() + " this user?"} /> */}
      <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
        <div className="container-fluid">
          <div className="row mt-4 justify-content-center">
            <Table
              id="ongoing-challenges"
              data={ongoingChallengeList || []}
              headerRender={() => headerRender(ongoingStepChallengesList)}
              rowRender={rowRender}
              onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
              onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
              rootUrl="/AdminChallenges"
              header={"Ongoing Wellness initiatives"}
              subHeader=""
              noDataMsg="No challenges found"
              {...props}
            ></Table>
          </div>
        </div>
      </section>
    </>
  );
}

export default OngoingChallenges;
