import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
//import tawkTo from "tawkto-react";
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";
import { useSelector,useDispatch } from 'react-redux';

function HelpFeedback() {

    const tawkToPropertyId = '6088fe5062662a09efc2d356'
    const tawkToKey = '1f4bi2rfh'
    const [showComingsoon, setComingsoon] = useState(false);
    const [screenName,setScreenName] = useState("");
    const dispatch = useDispatch();
	const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')
    // useEffect(() => {
    //     tawkTo(tawkToPropertyId, tawkToKey)
    // }, [])

    useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])

    const closeComingsoon = () => {
        setComingsoon(false);
     }

      const handleChat = () =>{
        const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'CHAT');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
      }

    return (<>

        <section className="myProfilePage position-relative d-flex h-100">

            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row mt-2">
                            <div className="col-sm-10 pb-5">
                                <div className="cRoomHeader pt-2 mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to="/pod"><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Help & Resources</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul id="suteSelMenu" className="HangOutMenu mb-wd-h-r">

                            <li className="OBhex">
                                <Link className="OBhexIn active" to="/resources" style={{ backgroundImage: 'url("./assets/media/images/help-feedback/resources.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" fill="rgba(255,255,255,1)" /></svg> <br />
                                        <u className="OBhexTitle" style={{fontSize: '16px', marginTop: '10px', display: 'block'}}>Resources</u> </span> </p>
                                </Link>
                            </li>
                            <li className="OBhex">
                                {/* <Link className="OBhexIn active" to="/help-feedback" style={{ backgroundImage: 'url("./assets/media/images/help-feedback/chatting.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}> */}
                                <Link className="OBhexIn active" onClick={handleChat} style={{ backgroundImage: 'url("./assets/media/images/help-feedback/chatting.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176zm.29-2.113l.653.35A7.955 7.955 0 0 0 12 20a8 8 0 1 0-8-8c0 1.334.325 2.618.94 3.766l.349.653-.655 2.947 2.947-.655z" fill="rgba(255,255,255,1)" /></svg> <br />
                                        <u className="OBhexTitle" style={{fontSize: '16px', marginTop: '10px', display: 'block'}}>Chat</u> </span> </p>
                                </Link>
                            </li>

                            <li className="OBhex hex-mb">
                                <Link className="OBhexIn" to="/feedback" style={{ backgroundImage: 'url("./assets/media/images/help-feedback/feedback.jpg")', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">

                                        
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 3H6C5.60218 3 5.22064 3.15803 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V31.5C4.5 31.8978 4.65804 32.2794 4.93934 32.5607C5.22064 32.842 5.60218 33 6 33H30C30.3978 33 30.7794 32.842 31.0607 32.5607C31.342 32.2794 31.5 31.8978 31.5 31.5V4.5C31.5 4.10218 31.342 3.72064 31.0607 3.43934C30.7794 3.15803 30.3978 3 30 3ZM28.5 6V30H7.5V6H28.5ZM12 25.5H24V22.5H12V25.5ZM12 19.5H24V16.5H12V19.5ZM12 13.5H19.5V10.5H12V13.5Z" fill="white"/></svg>
                            <br />
                                        <u className="OBhexTitle" style={{fontSize: '16px', marginTop: '10px', display: 'block'}}>Feedback</u> </span> </p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message={comingsoonDescription}/>
        </section>

    </>
    );
}


export default HelpFeedback;