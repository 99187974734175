import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Contacts from './Contacts';
import { t } from '../../i18n';
import WindowTitle from '../../layout/WindowTitle';
import Groups from './Groups';
import Inbox from './inbox';
import ShareMeetingLink from './shareMeetingLink';
import { getGroupsByEmpId } from '../../services/ContactsService';
import { leaveRoomById, AddEmployeeToRoom, UpdateLastActivityTime } from '../../services/RoomsService';
import { GetUnseenUserMessagesCount, GetUnseenGroupMessagesCount } from '../../services/ChatServices';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../../store/Store';
import Activity from './Activity';
import './produce.css';
export interface UnreadCounts {
    totalGroups: number;
    totalIndividuals: number;
}
import ComingSoonPopup from '../../layout/ComingsoonPopup';
import * as T from '../../actions/Types';
import { GetCommingSoon } from "../../services/WellnessServices";
function Produce() {
    const empId = localStorage.getItem('EmpGuid') as string;
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const meetingId = React.useRef('');
    const [showContacts, setShowContacts] = useState(false);
    const [showGroups, setShowGroups] = useState(false);
    const [showInbox, setShowInbox] = useState(false);
    const [showActivity, setShowActivity] = useState(false);
    const [showSML, setShowSML] = useState(false);
    const [postRoomStatusInt, updateIntId] = useState<any>(0);
    //const [unreadTotal, setUnreadTotal] = useState<UnreadCounts>({ totalGroups: 0, totalIndividuals: 0 });
    const dispatch = useDispatch();
    const location = useLocation();
    const [showComingsoon, setComingsoon] = useState(false);
    const [screenName,setScreenName] = useState("");
    const [fetchingCoomingSoonData, setFetchingCoomingSoonData] = useState([] as any);
	const [comingsoonDescription,setComingsoonDescription] = useState('')

    useEffect(() => {
		dispatch({
			type: T.ADD_LOADING
		})
		GetCommingSoon(null, null, 0)
			.then((resp: any) => {
				setFetchingCoomingSoonData([...resp.result ]); // Adjust based on whether result is array
				if (resp.status == "success") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
				else if (resp == "Error") {
					dispatch({
						type: T.REMOVE_LOADING
					})
				}
			})
			.catch((e: any) => {
				dispatch({
					type: T.REMOVE_LOADING
				})
	
				return "error"
			})
		dispatch({
			type: T.REMOVE_LOADING
		})
	}, [ ])
    // useEffect(() => {
    //     // dispatch(GetUnseenUserMessagesCount(empId) as any);
    //     // dispatch(GetUnseenGroupMessagesCount(empId) as any);
    //     if (!user.groups || user.groups.length == 0) {
    //         dispatch(getGroupsByEmpId(empId) as any);
    //     } 
    //     // else {
    //     //     const room = user.groups.filter((r: any) => r.IsDefault === true && r.GroupName === 'Produce');
    //     //     if (room.length > 0) {
    //     //         meetingId.current = room[0].GroupGuid;
    //     //         entryToProduce();
    //     //     }
    //    // }
    //     return () => {
    //        // clearInterval(postRoomStatusInt._id);
    //        /// leaveProduce();
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user.groups) {
    //         const room = user.groups.filter((r: any) => r.IsDefault === true && r.GroupName === 'Produce');
    //         if (room.length > 0) {
    //             meetingId.current = room[0].GroupGuid;
    //           //  entryToProduce();
    //         }
    //     }
    // }, [user.groups]);

    // useEffect(() => {
    //     setUnreadTotal({ totalIndividuals: user.messageCount.IndTotal, totalGroups: user.messageCount.GroupTotal });
    // }, [user.messageCount]);

    // const entryToProduce = () => {
    //     const form = new FormData();
    //     form.append("GroupGuid", meetingId.current || '');
    //     form.append("EmployeeGuid", empId);
    //     dispatch(AddEmployeeToRoom(form) as any);
    //     // updateIntId(setInterval(postRoomStatus, 120000));
    // }
    // const leaveProduce = () => {
    //     const form = new FormData();
    //     form.append("GroupGuid", meetingId.current || '');
    //     form.append("EmployeeGuid", empId);
    //     form.append("no_toast", 'true');
    //     dispatch(leaveRoomById(form) as any);
    // }

    // const postRoomStatus = async () => {
    //     const form = new FormData();
    //     form.append("GroupGuid", meetingId.current || '');
    //     form.append("EmployeeGuid", empId);
    //     await UpdateLastActivityTime(form);
    // }
    const handleShowContacts = () => {
        if (!showContacts)
            setShowGroups(false);
        setShowInbox(false);
        setShowActivity(false);
        setShowSML(false);
        setShowContacts(!showContacts);
    }
    const handleShowGroups = () => {
        if (!showGroups)
            setShowContacts(false);
        setShowInbox(false);
        setShowActivity(false);
        setShowSML(false);
        setShowGroups(!showGroups);
    }
    const handleShowInbox = () => {
        if (!showInbox)
            setShowGroups(false);
        setShowContacts(false);
        setShowActivity(false);
        setShowSML(false);
        setShowInbox(!showInbox);
    }
    // const handleShowActivity = () => {
    //     if (!showActivity)
    //         setShowGroups(false);
    //     setShowContacts(false);
    //     setShowInbox(false);
    //     setShowSML(false);
    //     setShowActivity(!showActivity);
    // }
    const handleShowSML = () => {
        if (!showSML)
            setShowGroups(false);
        setShowContacts(false);
        setShowInbox(false);
        setShowActivity(false);
        setShowSML(!showSML);
    }
    const closeComingsoon = () => {
        setComingsoon(false);
     }

     const handleShowActivity = () => {
        const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'ACTIVITY');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
    }
      const handleApp = () =>{
        const filteredData = fetchingCoomingSoonData.filter((item : any) => item.screenname === 'APPS');
			if (filteredData.length > 0) {
			const firstItem = filteredData[0];
			setScreenName(firstItem.screenname);
			setComingsoonDescription(firstItem.description);
			setComingsoon(true);
		}
      }

      
    return (
        <section className="overflow-hidden position-relative h-100 Produce-PODBG" style={{ "backgroundSize": "cover", "backgroundPosition": "center" }}>
            <div className="col-12 mt-2 mb-block d-none">
                <Link to="/pod">
                    <svg enable-background="new 0 0 64 64" height="40" viewBox="0 0 64 64" width="40" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"><path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path></svg>
                </Link>

            </div>
            <div className="d-flex h-100 text-center w-100">
                <WindowTitle title="Office" />
                <div className="ProduceMenuGrid">
                    <ul id="hexagonContainer">
                        <li className="hex-row">
                            <div className="hexagon">
                                <div className="hex-inner">
                                    <div className="hex-img"> <Link to="/calendar">{t('calendar.calendar')}</Link></div>
                                </div>
                            </div>
                            <div className="hexagon">
                                <div className="hex-inner">
                                    <div className="hex-img"> <Link to={{ pathname: user && user.UserTypeId == 1 ? "/userProfile" : "/companyInfo" }} state={location.pathname}>{t('button.settings')}</Link></div>
                                </div>
                            </div>
                        </li>
                        <li className="hex-row mobile-none">
                            <div className={showGroups ? "hexagon active" : "hexagon"}>
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowGroups}><a>{t('groups.collaborate')}</a></div>
                                </div>
                            </div>

                            <div className={showContacts ? "hexagon active" : "hexagon"}>
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowContacts}><a>{t('button.contacts')}</a></div>
                                </div>
                            </div>
                            <div className="hexagon mobile-none">
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleApp} ><a>{t('button.apps')}</a> </div>
                                </div>
                            </div>
                        </li>
                        <li className="hex-row mobile-none" style={{paddingLeft: "41.8%"}}>
                            {/* <div className="hexagon mobile-none">
                                <div className="hex-inner">
                                    <div className="hex-img"><a>{t('button.inbox')}</a></div>
                                </div>
                            </div> */}
                            <div className="hexagon">
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowActivity}> <a>{t('activity.activity')}</a> </div>
                                </div>
                            </div>
                            <div className="hexagon mobile-none">
                                <div className="hex-inner">
                                    {/* <div className="hex-img"><a>{t('button.checkins')}</a></div> */}
                                    <div className="hex-img"> <Link to="/checkins">{t('button.checkins')}</Link> </div>
                                </div>
                            </div>
                            <div className="hexagon mobile-mr">
                                <div className="hex-inner">
                                    <div className="hex-img"> <Link to="/files">{t('title.files')}</Link> </div>
                                </div>
                            </div>
                        </li>
                        <li className="hex-row mb-block d-none">
                            <div className="hexagon margin-tablet" style={{ marginTop: "-11%" }}>
                                <div className="hex-inner">
                                    <div className="hex-img"> <Link to="/files">{t('title.files')}</Link> </div>
                                </div>
                            </div>
                        </li>
                        <li className="hex-row mb-block d-none margin-tablet-2" style={{ marginTop: "-10%" }}>
                            <div className={showGroups ? "hexagon active" : "hexagon"}>
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowGroups}><a style={{ lineHeight: 'normal', paddingTop: '35px', fontSize: '11px' }}>{t('groups.collaborate')}</a></div>
                                </div>
                            </div>

                            <div className={showContacts ? "hexagon active" : "hexagon"}>
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowContacts}><a>{t('button.contacts')}</a></div>
                                </div>
                            </div>
                        </li>
{/* 
                        <li className="hex-row mobile-none" style={{ paddingLeft: "8.7%" }}>
                            <div className="hexagon">
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowActivity}> <a>{t('activity.activity')}</a> </div>
                                </div>
                            </div>

                         <div className="hexagon">
                                <div className="hex-inner">
                                    <div className="hex-img c-pointer" onClick={handleShowSML}> <a style={{ lineHeight: '1.5', paddingTop: '17px' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                            <path d="M4 19h16v-5h2v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-6h2v5zm8-9H9a5.992 5.992 0 0 0-4.854 2.473A8.003 8.003 0 0 1 12 6V2l8 6-8 6v-4z" fill="currentColor" /></svg>
                                        <br />{t('button.sute_link')}</a>
                                    </div>
                                </div>
                            </div> 
                        </li> */}
                    </ul>
                </div>
                <div className="align-self-center m-auto position-relative w-100">
                    <Link className="ProduceLogoClick" to="/pod"></Link>
                    <div className="ProduceLaptopScreen" >
                        <img src={(user.orgData.LogoURL && user.orgData.LogoURL.length > 0) ? user.orgData.LogoURL : '/assets/media/images/sute-logo1.png'} className='p-5 w-100' />
                    </div>
                    <div className="ProduceTabletScreen" style={{ "backgroundImage": "url('/assets/media/images/spotify.png')" }}></div>
                    <div className="ProduceWallPoster" style={{ "backgroundImage": "url('/assets/media/images/wallposter.JPG')" }}></div>
                </div>
            </div>
            {showContacts ? <Contacts showContacts={showContacts} handleShowContacts={handleShowContacts} /> : ''}
            {showGroups ? <Groups showGroups={showGroups} handleShowGroups={handleShowGroups} />: ''}           
            <Inbox showInbox={showInbox} handleShowInbox={handleShowInbox} />
            {showActivity ? <Activity showActivity={showActivity} handleShowActivity={handleShowActivity} /> : ''}
            <ShareMeetingLink showSML={showSML} handleShowSML={handleShowSML} />
            <ComingSoonPopup show={showComingsoon} screenName={screenName} close={closeComingsoon} message= {comingsoonDescription} />
        </section>
    );
}

export default Produce;


