import AgoraRTM,{ RemoteInvitation, RtmClient, RtmEvents, RtmMessage, RtmStatusCode } from 'agora-rtm-sdk';
import EventEmitter from 'events';
const APP_ID = process.env.REACT_APP_AGORA_APP_ID as string;
interface ReceivedMessageProperties {
  serverReceivedTs: number;
  isOfflineMessage: boolean;
}
export class ChatClient extends EventEmitter {
  public channels: any;
  public _logined: boolean;
  public meUid: string;
  public client: RtmClient;
  //public currentChannel: any;
  public currentPeerId: string;
  public userState: any;
  public onMsgsLoad: Function;
  public onNotification: Function;
  public onStatusUpdate: Function;
  public onConnectionChanged: Function;
  public onCallMsgs: Function;
  public remoteInvitationRequest: any;
  public localInvitationObj: any;
  public meetingInfo?: any;
  //public currentChannelId: any;
  public _channelAttrsKey?: any;
  public streamsInfo?: any;
  public joinedNotificationChannel: boolean;
  public note_channel: string;
  public subdChannels: string[];
  public onNavigationUpdate: Function;
  constructor() {
    super()
    this.meUid = '';
    this.note_channel = '';
    this.channels = {}
    this._logined = false;
    this.client = AgoraRTM.createInstance(APP_ID);
    this.currentPeerId = '';
    this.userState = 'DISCONNECTED';
    this.meetingInfo = {};
    this.subdChannels = [];
    this.joinedNotificationChannel = false;
    this.onMsgsLoad = (args: any) => {
      //console.log(args);
    }
    this.onStatusUpdate = (args: any) => {
      //console.log(args);
    }
    this.onNavigationUpdate = (args: any) => {
      //console.log(args);
    }
    
    this.onNotification = (args: any) => { }
    this.onConnectionChanged = (args: any) => { }
    this.onCallMsgs = (eventName: string, msgs: any) => { }
  }

  async init(userId: string, orgId: string): Promise<void> {
    if (this._logined)
      return;
    this.unsubscribeClientEvents();
    //console.log('Chatclient loggedin');
    this.meUid = userId;
    this.note_channel = orgId;
    return this.login(userId).then(() => {
      this.subscribeClientEvents();
    });

  }

  subscribeOnlineStatus(peerIds: string[]) {
    if (this._logined && peerIds && peerIds.length > 0) {
      this.client.subscribePeersOnlineStatus(peerIds).then((res: any) => {
        //console.log('online status results', res);
      });
    }
  }

  connectionStateChanged = (
    newState: RtmStatusCode.ConnectionState,
    reason: RtmStatusCode.ConnectionChangeReason
  ) => {
    this._logined = (newState === 'CONNECTED') ? true : false;
    // if (!this._logined)
    //   this.client.logout();
    this.onConnectionChanged(newState);
  };

  messageFromPeer = (
    message: RtmMessage,
    peerId: string,
    messageProps: any
  ) => {
    if (message.messageType === 'TEXT' && message.text !== 'CALL') {
      const newmsg = { ...message, SenderGuid: peerId, isSeen: false };
      this.onMsgsLoad('PEER', peerId);
    }

  };

  peersOnlineStatusChanged = (
    // status: RtmStatusCode.PeerOnlineState
    status: any
  ) => {
    this.onStatusUpdate(status);
  };

  remoteInvitationReceived = (remoteInvitation: RemoteInvitation) => {
    console.log(`remoteInvitationReceived:`, remoteInvitation);
    remoteInvitation.on('RemoteInvitationCanceled', this.emoteInvitationCanceled)
    remoteInvitation.on('RemoteInvitationRefused', this.RemoteInvitationRefused)
    this.remoteInvitationRequest = remoteInvitation;
    this.onCallMsgs('RemoteInvitationReceived', remoteInvitation);
  };
  emoteInvitationCanceled = (remoteInvitation: any) => {
    console.log(`remoteInvitationReceived:`, remoteInvitation);
    this.remoteInvitationRequest = remoteInvitation;
    //this.onCallMsgs('RemoteInvitationReceived', remoteInvitation);
  };
  RemoteInvitationRefused = () => {
    console.log(`remoteInvitationReceived:`);
    //this.onCallMsgs('RemoteInvitationReceived', '');
  };

  // subscribe client events
  subscribeClientEvents() {
    this.client.on('ConnectionStateChanged', this.connectionStateChanged);
    this.client.on('MessageFromPeer', this.messageFromPeer);
    this.client.on('PeersOnlineStatusChanged', this.peersOnlineStatusChanged);
    this.client.on('RemoteInvitationReceived', this.remoteInvitationReceived);
    //this.client.on('RemoteInvitationCanceled',this.emoteInvitationCanceled);
    // this.client.on('RemoteInvitationRefused',this.RemoteInvitationRefused);
  }
  unsubscribeClientEvents() {
    this.client.off('ConnectionStateChanged', this.connectionStateChanged);
    this.client.off('MessageFromPeer', this.messageFromPeer);
    this.client.off('PeersOnlineStatusChanged', this.peersOnlineStatusChanged);
    this.client.off('RemoteInvitationReceived', this.remoteInvitationReceived);
  }


  // subscribe channel events
  subscribeChannelEvents(channelName: string) {
    if (this.subdChannels.indexOf(channelName) > -1) return;
    if (!this.channels[channelName] || !this.channels[channelName].channel) return;
    this.subdChannels.push(channelName);

    this.channels[channelName].channel.on('ChannelMessage', (message: RtmMessage, memberId: string) => {
      // console.log('message.messageType: ', message.messageType);
      if (this.note_channel === channelName) {
        ///  this.emit('chatNotify', ...args);
        this.onNotification(message);
      }
      else if (message.messageType === 'TEXT') {
        const newmsg = { ...message, SenderGuid: memberId, isSeen: false };
        this.onMsgsLoad(newmsg, channelName);
      }
    });
    this.channels[channelName].channel.on('AttributesUpdated', (attributes: any) => {
      this.attributesUpdated(channelName, attributes);
    });
    this.channels[channelName].channel.on('MemberCountUpdated', this.memberCount);

  }
  memberCount = (memberCount: number) => {
    console.log(`memberCount updated: ${memberCount}`);
  };

  attributesUpdated(channelName: string, attribs: any[]) {
    //console.log('channel Attributes', attribs);
    const streams = attribs && attribs.length > 0 ? attribs[0] : '';
    let newStreams: any = {};
    for (let k in streams) {
      newStreams[k] = JSON.parse(streams[k].value);
      newStreams[k].lastUpdateTs = streams[k].lastUpdateTs;
    }
    this.streamsInfo = newStreams;
  }

  addOrUpdateAttribute(channelId: string, streamId: string, value: any) {
    this._channelAttrsKey = streamId;
    const channelAttributes: { [key: string]: string } = {}
    if (streamId) {
      channelAttributes[streamId] = JSON.stringify(value);
    }
    //console.log("updateChannelAttrsByKey ", value, " key ", streamId, channelAttributes);
    this.client.addOrUpdateChannelAttributes(channelId, channelAttributes, { enableNotificationToChannelMembers: true });
  }

  getChannelAttributes(channelId: string) {
    return this.client.getChannelAttributes(channelId).then((attribs: any) => {
      //console.log('getChannelAttributes>>', attribs);
      const streams = attribs && attribs.length > 0 ? attribs[0] : '';
      let newStreams: any = {};
      for (let k in streams) {
        newStreams[k] = JSON.parse(streams[k].value);
        newStreams[k].lastUpdateTs = streams[k].lastUpdateTs;
      }
      return newStreams;
    })
  }

  deleteChannelAttributesByKeys(channelId: string, keys: any[]) {
    console.log(keys, channelId);
    // this.client.deleteChannelAttributesByKeys(channelId, keys).then((res: any)=>{
    //console.log(res);
    // })
  }

  async getChannelAttrs(channelName: string): Promise<string> {
    let json = await this.client.getChannelAttributes(channelName);
    return JSON.stringify(json);
  }

  getUserNameById(uid: string) {
    return '';
  }

  async login(accountName: string, token?: string): Promise<any> {
    // accountName='123456789';
    // token='006d3dafa6c65d74a89934340f32e7b9b47IACQmXySiDXh/ZA2ES3QT8DJm/CTqHXXZhrTQ4I27wZE02nlshIAAAAAEABj+1kpgE4jYwEA6AOATiNj';
    if (this._logined) {
      return 'already loggedin';
    }
    if (!accountName) {
      return '';
    }
    //if(!this.client.hasLoggedin(accountName))

    return this.client.login({ uid: accountName, token: undefined }).then((error: any) => {
      this._logined = true;
      console.log("CHAT Login successful")
    }).catch((e: any) => { console.log(e) });
  }

  async logout() {
    this._logined = false;
    return this.client.logout()
  }

  async joinChannel(name: string) {
    const channel = this.client.createChannel(name);
    this.channels[name] = {
      channel,
      joined: false // channel state
    }
    this.subscribeChannelEvents(name);
    //this.currentChannelId = name;
    return channel.join();
  }

  async createChannel(channelID: string) {
    if (this.subdChannels.indexOf(channelID) > -1)
      return;
    if (this.channels[channelID]) {
      return this.channels[channelID];
      // this.currentChannel = this.channels[channelID];
    } else {
      this.joinChannel(channelID).then((channelObj: any) => {
        this.channels[channelID].joined = true;
        return channelObj;
        //this.currentChannel = this.channels[channelID];
      }
      ).catch((err: any) => {
        console.error(err)
      });
    }
  }

  async leaveChannel(name: string) {
    //console.log('leaveChannel', name)
    if (!this.channels[name]) return;
    return this.channels[name].channel.leave()
  }

  async sendChannelMessage(text: string, channelName: string) {
    //console.log('channelName>>>>>>>>', this.channels[channelName] );
    if (!this.channels[channelName] || !this.channels[channelName].joined) return "error";
    const msgOptions = { enableHistoricalMessaging: true }; //enableOfflineMessaging
    console.log('before channelmsg sent', text);
    return this.channels[channelName].channel.sendMessage({ text, messageType: "TEXT" }, msgOptions).then((res: any) => {
      console.log('after channelmsg sent', res);
      const nmsg = { text, messageType: "TEXT", SenderName: 'me', SenderGuid: this.meUid, TimeStamp: Date.now() };
      this.onMsgsLoad(nmsg, channelName);
      return nmsg;
    }).catch((e: any) => {
      console.log(e);
      // return "error";
    });
  }

  async sendNotification(toUser: string, fromUserName: string, msgType?: string) {
    if (!this.channels[this.note_channel] || !this.channels[this.note_channel].joined) return
    const msgOptions = { enableHistoricalMessaging: false }; //enableOfflineMessaging
    this.channels[this.note_channel].channel.sendMessage({ text: JSON.stringify({ t: (msgType ? msgType : 'typing'), toUser, from: fromUserName }) }, msgOptions)
      .then((res: any) => {
        //console.log('sending notify to user', res);
      });
  }

  async sendChannelCallMsg(newMeetingid: string, channelName: string, uname: string, groupname: string, callType = 'CALL') {

    const link = '/customrm/' + newMeetingid
    if (!this.channels[channelName] || !this.channels[channelName].joined) return
    const msgOptions = { enableHistoricalMessaging: true }; //enableOfflineMessaging
    let msg = JSON.stringify({ t: `__${callType}__`, f: uname, l: link, rn: groupname });
    const nmsg = { text: msg, messageType: "TEXT", senderName: 'me', SenderGuid: this.meUid, TimeStamp: Date.now() };
    this.channels[channelName].channel.sendMessage({ text: msg, messageType: "TEXT" }, msgOptions).then((res: any) => {
      //this.onMsgsLoad(nmsg, channelName);
    });
  }

  async sendPeerMessage(text: any, peerId: string) {
    const msgOptions = { enableHistoricalMessaging: true, enableOfflineMessaging: true };
    return this.client.sendMessageToPeer({ text }, peerId.toString()).then((sendResult: any) => {
      const nmsg = { text, messageType: "TEXT", SenderName: 'me', SenderGuid: this.meUid, TimeStamp: Date.now() };

      //this.onMsgsLoad(nmsg, peerId);
      return { ...nmsg, hasPeerReceived: sendResult.hasPeerReceived };
    }).catch((error: any) => {
      console.log(error);
    });
  }

  async sendCallMsg(msg: any, peerId: string) {
    this.client.sendMessageToPeer({ ...msg }, peerId).then((sendResult: any) => {
      console.log('call message send to ', sendResult.hasPeerReceived);
    });
  }

  async queryPeersOnlineStatus(memberId: string) {
    console.log('queryPeersOnlineStatus', memberId)
    return this.client.queryPeersOnlineStatus([memberId])
  }

  //send image
  async uploadImage(blob: any, peerId: string) {
    // const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
    //   messageType: 'IMAGE',
    //   fileName: 'agora.jpg',
    //   description: 'send image',
    //   thumbnail: blob, 
    //   // width: 100,
    //   // height: 200,
    //   // thumbnailWidth: 50,
    //   // thumbnailHeight: 200, 
    // }) 
    // return this.client.sendMessageToPeer(mediaMessage, peerId)
  }

  async sendChannelMediaMessage(blob: any, channelName: string) {
    console.log('sendChannelMessage', blob, channelName)
    if (!this.channels[channelName] || !this.channels[channelName].joined) return
    // const mediaMessage = await this.client.createMediaMessageByUploading(blob, {
    //   messageType: 'IMAGE',
    //   fileName: 'agora.jpg',
    //   description: 'send image',
    //   thumbnail: blob, 
    //   // width: 100,
    //   // height: 200,
    //   // thumbnailWidth: 50,
    //   // thumbnailHeight: 200, 
    // }) 
    //return this.channels[channelName].channel.sendMessage(mediaMessage)
  }

  async cancelImage(message: any) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 1000)
    // await this.client.downloadMedia(message.mediaId, {
    //   cancelSignal: controller.signal,
    //   onOperationProgress: ({currentSize, totalSize}: any) => {
    //     console.log(currentSize, totalSize)
    //   },
    // })
  }

  async handlePeerMessage(body: any, peerId: string) {
    const { cmd, data: { userName, userId, type } } = body
    if (cmd !== 1) return
    console.log(cmd);

  }

  async checkAndReConnection(dn: string, mettingId: string) {
    if (!this._logined) {
      this.init(dn, mettingId).then(() => {
         

        try {
          if (this._logined)
            if (!this.joinedNotificationChannel)
              if (mettingId) {
                try {
                  this.createChannel(mettingId).then(() => {
                    console.log('joined in notification channel', mettingId);
                    this.joinedNotificationChannel = true;
                  });
                } catch (e) {
                  console.log(e);
                }
              }

        } catch (e) {
          console.log(e);
        }
      });
    }
  }

}

export const chatClient = new ChatClient();