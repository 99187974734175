
//import { Line } from 'react-chartjs-2';
import { StoreClass } from '../../store/Store';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {GetEmpSessionsTrace} from '../../services/AnalyticsService';
import moment from 'moment';
function ProductiveLineGraph(props: any) {
    const { EMP } = useSelector((state: StoreClass) => ({ EMP: state?.EmployeeReducer }));
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [data, setData] = useState<any>();
    const dispatch = useDispatch();
    const options = {
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'index',
          },
        plugins: {       
            legend: {
                display: false,
                position: 'bottom'
            }
        },
        layout: {
            padding: 1
        }
    };

    useEffect(()=>{
        dispatch(GetEmpSessionsTrace(empGuid)as any);
    },[]);
    useEffect(()=>{
       
        const lastThirtyDays = [...new Array(30)].map((i, idx) => {return {x:moment().startOf("day").subtract(30-idx, "days").format('MMM D'), y:0};});
        const lastThirtyDay2 = [...new Array(30)].map((i, idx) => {return {x:moment().startOf("day").subtract(30-idx, "days").format('MMM D'), y:0};});
       // console.log('lastThirtyDays', lastThirtyDays);
        const data1: any[] = Object.assign([], lastThirtyDays); //lables
        const data2: any[] = Object.assign([], lastThirtyDay2);
        if(EMP.sessionTrace){
            let values = EMP.sessionTrace.ProductiveSessions;
            values.forEach((d:any)=>{
                const min: number = Math.round(d.ProductiveSessionsSeconds/60);
                const date = new Date(d.CreatedDate);
                const datax1 = data1.findIndex((k:any)=>k.x === moment(date).format('MMM D'));
                if(datax1>-1)
                    data1[datax1].y = min;
                //data1.push({x: moment(date).format('MMM D'), y: min});
            });
            const values2 = EMP.sessionTrace.NonProductiveSessions;
            values2.forEach((d:any)=>{
                const min: number = Math.round(d.NonProductiveSessionsSeconds/60);
                const date = new Date(d.CreatedDate);
                const datay = data2.findIndex((k:any)=>k.x === moment(date).format('MMM D'));
                if(datay>-1)
                    data2[datay].y = min;
                //data2.push({x: moment(date).format('MMM D'), y: min});
            });
        }
        console.log('data2', data1, data2);
        const datax = {
            datasets: [{
                type: 'line',
                label: 'Productive',
                data: data1,
                borderColor: '#6264a5',
                backgroundColor: '#6264a5',
                spanGaps: true,
                tension: 0.1
            }, {
                type: 'line',
                label: 'Non-productive',
                data: data2,
                borderColor: '#30D5C8',
                backgroundColor: '#30D5C8',
                spanGaps: true,
                tension: 0.1
            }]
        };

        setData(datax);
    }, [EMP.sessionTrace]);
    return (
        <div style={{height:'300px', width:'300px', marginLeft: '-40px'}}>
            {/* <Line type='line' data={data} options={options} /> */}
        </div>
    );
}

export default ProductiveLineGraph;