export const UpdateStorage = (json:any) => {  
    if(typeof json === 'object') {
        for(let key in json){
            localStorage.setItem(key, json[key]);
        }
    }else{
        localStorage.setItem(json.key, json);
    }
}

export const UpdateStoreageValue = (key: string, val: string) => {
    localStorage.setItem(key, val);
    console.log(localStorage);
}

export const getItem = (key: string) =>{
    return localStorage.getItem(key);
}
export const RemoveStorage = (key:string) => {
    localStorage.removeItem(key)
}

export const getUserId = ()=>{
    //return localStorage.getItem('EmpGuid')||'';
    return localStorage.getItem('EmpGuid')||'';
}