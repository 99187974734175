
import { GetLeaderShipTracksList } from "../../services/MediaService";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

function Leadership(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const [leaderTracklist, setLeaderTrackList] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState({ id: -1, url: '', name: '' });
    const [showLeadershipSideNav, setShowLeadershipSideNav] = useState(true);

    useEffect(() => {
        dispatch(GetLeaderShipTracksList(2));
    }, [])
    useEffect(() => {
       if(Array.isArray(media.leaderShipTrackList)){
        media.leaderShipTrackList.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setLeaderTrackList(media.leaderShipTrackList || []);
    } else if(Array.isArray(media.leaderShipTrackList.result)){
        media.leaderShipTrackList.result.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setLeaderTrackList(media.leaderShipTrackList.result || []);
    }
    }, [media.leaderShipTrackList])


    const PlayTrack = (obj: any, index: number) => {
        setSelectedTrack({ id: index, url: obj.TrackURL, name: obj.TrackName });
    }

    const PauseTrack = (obj: any, index: number) => {
        setSelectedTrack({ id: -1, url: obj.TrackURL, name: obj.TrackName });
    }

    const closeLeadershipSideNav = ()=>{
        setShowLeadershipSideNav(!showLeadershipSideNav);
    }

    function onplayerNext(obj: any): void {
        var id = selectedTrack.id + 1;
        if (id < media.leaderShipTrackList.length) {
            var nextTrack = media.leaderShipTrackList[id];
            setSelectedTrack({ id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    function onplayerPrevious(obj: any): void {
        var id = selectedTrack.id - 1;
        if (id >= 0) {
            var nextTrack = media.leaderShipTrackList[id];
            setSelectedTrack({ id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            {/* <HangoutMenu /> */}
            <div className="open-sidemenu" onClick={closeLeadershipSideNav}>
                <img src="/assets/media/images/leftarrow.png" />
            </div>
            <div className={showLeadershipSideNav ? "SideNav hangSideNav text-left d-block position-fixed" : "SideNav hangSideNav text-left d-none position-fixed" }>
                <div className="sideNavHeader">
                    <div className="CloseNav" onClick={closeLeadershipSideNav}><img src="assets/media/svg/x-close.svg" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row small">
                        <Link to="/learn" className="col-12 mb-3 text-center">
                            <div className="SidenavHex">

                                <img src="./assets/media/images/hangouts/suteSelectionsIcon.svg" />
                                <p className="pt-2">Sute<br />Selections</p>

                            </div>
                        </Link>
                        <div className="col-12 text-center">
                            <div className="SidenavHex">
                                <img src="./assets/media/images/hangouts/podcastsIcon.svg" />
                                <p className="pt-2">Podcasts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row mt-4">
                    
                    <div className="col-9">
                        <div className="cRoomHeader pt-2"><div className="cRsuteLogo align-self-center"><Link to="/learn"><img src="/assets/media/images/logo.png" /></Link></div><div className="cRTitle p-3"><h5>Leadership</h5></div></div>
                        <div className="mediaGrid">
                            { (leaderTracklist || []).length > 0 ? (leaderTracklist || []).map((obj: any, index: number) => {
                                return (
                                    <div className="mediaItem">
                                        <div className="mediaImg">
                                            <img src={obj.Thumbnail} />
                                        </div>
                                        <div className="mediaAction">
                                            {index != selectedTrack.id ? <img onClick={(e: any) => PlayTrack(obj, index)} src="/assets/media/images/hangouts/media/play.svg" />
                                                : <img onClick={(e: any) => PauseTrack(obj, index)} src="/assets/media/images/hangouts/media/pause.svg" />}
                                        </div>
                                        <div className="mediaDetails">
                                            <h4 className="mt-3 text-white h6">{obj.TrackName}</h4>
                                            <p className="text-muted fs12">{obj.Description}</p>
                                            <audio controls className="d-none">
                                                <source src={obj.TrackURL} />
                                            </audio>
                                        </div>
                                    </div>
                                )
                            }) : <div className="mediaDetails"/>}
                        </div>
                        <AudioPlayer className="SuteMediaPlayer HangoutMediaPlayer small" layout="stacked-reverse" customVolumeControls={[]} customAdditionalControls={[]} header={selectedTrack.name} src={selectedTrack.url} onClickPrevious={(e: any) => onplayerPrevious(e)} onClickNext={(e: any) => onplayerNext(e)} showSkipControls={true} />
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}


export default Leadership;