import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import HangoutMenu from "../onboarding/HangoutMenu";
import { useDispatch, useSelector } from "react-redux";
import { GetMediaTracksList } from "../../services/MediaService";




function Learn(props: any) {

    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);

    useEffect(() => {
        if (!media.mediaTrackList || media.mediaTrackList.length == 0)
        {
            dispatch(GetMediaTracksList() as any);
        }
    }, [])

    return (<>

        <section className="myProfilePage position-relative d-flex h-100">
            {/* <HangoutMenu /> */}
            
            <div className="container">
                <div className="row mt-4">
                    <div className="col-sm-12">
                    <div className="row">
                            <div className="col-sm-10 pb-5">
                                <div className="cRoomHeader mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/earn-learn"}><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Learn</h5>
                                        {/* <p>All Partners</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (media.mediaTrackList.filter((media:any)=>media.Page == 'lobby')||[]).length > 0 ? 
                            <ul id="suteSelMenu" className="HangOutMenu mt-2">
                            {
                                (media.mediaTrackList.filter((media:any)=>media.Page == 'lobby')||[]).map((media:any) =>
                                    <li className="OBhex">
                                        <Link className="OBhexIn active" to={"/"+media.MediaType.replace(' ','-')} style={{ backgroundImage: 'url('+media.Background+')', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            <p> <span className="align-self-center">
                                                <img src={media.Thumbnail} />
                                                <u className="OBhexTitle">{media.MediaType}</u> </span> </p>
                                        </Link>
                                    </li>
                                ) 
                            } 
                            </ul> 
                            :
                            <div className="info-center">
                                <p>No sute selections found</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}


export default Learn;