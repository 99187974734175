import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { GetChallenges, SaveStepChallenge } from "../../services/WellnessServices";
import * as T from "../../actions/Types";
import { globalStore } from "../../store/global_store";
import { removeSuccessStatus } from "../../services/ContactsService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import { formatUtcDate } from "../../utils/helper";



interface Challenges {
  ChallengeId: number;
  OrgId: number;
  ChallengeGuid: string;
  ChallengeName: string;
  IsActive: boolean;
  CreatedBy: string;
  CreatedDate: string;
  UpdatedBy: string;
  UpdatedDate: string;
}


function NewChallenges() {
  const [challengesList, SetChallenges] = useState([] as any);
  const EmpGuid = localStorage.getItem('EmpGuid') as string;
  const OrgGuid = localStorage.getItem('OrgGuid');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  var staticdate = new Date();
  var staticEnddate = new Date();  // Create a new Date object with the current date and time
  staticEnddate.setDate(staticdate.getDate() + 1)
  // Form Controls
  const [challengeForm, setFormControls] = useState<any>({
    EmpGuid: EmpGuid || "",
    ChallengeGuid: "",
    Description: "",
    Requirement: "",
    Goal: "",
    StartDate: Moment(staticdate).format('MM-DD-YYYY'),
    EndDate: Moment(staticEnddate).format('MM-DD-YYYY'),
    MetricType: "sum",
    staringValue: 0,
    // enrollDate: new Date()
  });

  const dispatch = useDispatch();
  const formData = new FormData();
  const history = useNavigate();
  

  useEffect(() => {
    if (challengesList.length < 0 || []) {
        dispatch({
            type: T.ADD_LOADING
        })
        GetChallenges(OrgGuid)
             .then((resp: any) => {
               SetChallenges([...resp.result])             
                if (resp.status === "Success") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
                else if (resp === "Error") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
            .catch((e : any) => {
                dispatch({
                    type: T.REMOVE_LOADING
                })

                return "error"
            })
        dispatch({
            type: T.REMOVE_LOADING
        })
    }
   
}, [])

  const handleFormControls = (e: React.ChangeEvent<any>, control: string) => {
    const value = e.target.value;
    setFormControls({ ...challengeForm, [control]: value });
  };

  const handleAggregationRadio = (e: React.ChangeEvent<any>, aggregate: string) => {
    setFormControls({ ...challengeForm, MetricType: aggregate });
  };

  

  
  const parseDate = (dateString : any ) => {
    const [day, month, year] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  
  const parseDateString = (dateString : any) => {
    const [day, month, year] = dateString.split('-');
    return new Date(`${month}-${day}-${year}`);
  };
  
  
  const checkFormValues = (form: any) => {
    for (let key in form) {
      if (form[key] === "" || form[key] === undefined || form[key] === null) {
        return false;
      }
    }
    return true;
  };


  const saveChallengeFormData = () => {
    debugger
    setIsSubmitted(true);
    if(!checkFormValues(challengeForm)) return;
    // setFormControls({ ...challengeForm, enrollDate});
    formData.append("EmpGuid", EmpGuid)
    formData.append("ChallengeGuid", challengeForm.ChallengeGuid);
    formData.append("Description", challengeForm.Description);
    formData.append("Requirement", challengeForm.Requirement);
    formData.append("Goal", challengeForm.Goal);
    formData.append("StartDate",Moment(challengeForm.StartDate).format('DD-MM-YYYY'));
    formData.append("EndDate",Moment(challengeForm.EndDate).format('DD-MM-YYYY'));
    formData.append("MetricType", challengeForm.MetricType);
    formData.append("LeaderBoardInfo.StartingValue", (challengeForm.staringValue || 0).toString());
     formData.append("LeaderBoardInfo.EnrollDate", "02-06-2024");
  // Alternatively, if your backend expects an empty string for null dates, you can append an empty string:
  // formData.append("LeaderBoardInfo.EnrollDate", challengeForm.LeaderBoardInfo.EnrollDate || '');

    dispatch({
      type: T.ADD_LOADING,
    });
    SaveStepChallenge(formData).then((resp: any) => {
      if (resp === "success") {
        resetAllControls();
        history('/AdminChallenges');
        dispatch({
          type: T.REMOVE_LOADING,
        });
        globalStore.showToast({
          type: "Message",
          title: "Status",
          message: "New Challenges Created Successfully",
        });
        dispatch(removeSuccessStatus() as any);
        setIsSubmitted(false);
       // history("/hr-challenges");

      } else if (resp === "Error") {
        globalStore.showToast({
          type: "Message",
          title: "Status",
          message: "Something Went wrong",
        });
        dispatch(removeSuccessStatus() as any);
        dispatch({
          type: T.REMOVE_LOADING,
        });
      }
    });
  };

  

  const resetAllControls = () => {
    setFormControls({
      EmpGuid: EmpGuid || "",
      ChallengeGuid: "",
      Description: "",
      Requirement: "",
      Goal: "",
      StartDate: "",
      EndDate: "",
      MetricType: "sum",
      staringValue: 0 ,
      // enrollDate: "",
    });
  };
 
  const handleStartDate = (date : any) => {  
    if (date) {
      setFormControls({ ...challengeForm, StartDate: Moment(date).format('MM-DD-YYYY') });
    }
  };

  const handleEndDate = (date : any) => {  
    debugger
    if (date) {
      // const formattedDate = formatDate(date);

    setFormControls({ ...challengeForm, EndDate: Moment(date).format('MM-DD-YYYY')});

    } 
  };

  const getNextDay = (date : any) => {
    return Moment(date).add(1, 'days').toDate();
  }

  return (
    <>
      <section className="myProfilePage position-relative d-flex mih-100">
        <div className="container">
          <div className="container-fluid">
            <div className="row mt-4">
              <div className="col-10">
                <div className="cRoomHeader pt-2">
                  <div className="cRsuteLogo align-self-center">
                      <Link to="/AdminChallenges">
                            <img src="/assets/media/images/logo.png" />
                      </Link>                
                  </div>
                  <div className="cRTitle p-3">
                    <h5>Wellness Challenge / [New Challenges]</h5>
                  </div>
                </div>
                <div className="col-10 ml-auto p-0">
                  <div className="challenge-form">
                    <div className="col-12 p-0">
                      <h5>Select Challenges</h5>
                      <select
                        id="challenge-dropdown"
                        className="form-control challengesview-input"
                        onChange={(event) => handleFormControls(event, "ChallengeGuid")}
                      >
                        <option>Select Challenges</option>
                        {challengesList.map((challenge: Challenges) => (
                          <option key={challenge.ChallengeGuid} value={challenge.ChallengeGuid}>
                            {challenge.ChallengeName}
                          </option>
                        ))}
                      </select>
                      {isSubmitted && !challengeForm.ChallengeGuid ? (
                        <div className="text-danger mt-2" style={{fontSize:'12px'}}>Challenges is required</div>
                      ): ('')}
                      
                    </div>
                    <div className="col-12 p-0 mt-3">
                      <h5>Description</h5>
                      <textarea
                        id="challenge-description"
                        className="form-control challenges-textarea"
                        onChange={(event) => handleFormControls(event, "Description")}
                      ></textarea>
                      {isSubmitted && !challengeForm.Description ? (
                        <div className="text-danger mt-2" style={{fontSize:'12px'}}>Description is required</div>
                      ): ('')}

                    </div>
                    <div className="col-12 mt-3 p-0">
                      <h5>Requirements</h5>
                      <input
                        type="text"
                        id="challenge-requirements"
                        className="form-control challengesview-input"
                        onChange={(event) => handleFormControls(event, "Requirement")}
                      />
                      {isSubmitted && !challengeForm.Requirement ? (
                        <div className="text-danger mt-2" style={{fontSize:'12px'}}>Requirements is required</div>
                      ): ('')}

                    </div>
                    <div className="col-12 mt-3 p-0">
                      <h5>Target / Goal</h5>
                      <input
                        type="text"
                        id="challenge-target"
                        className="form-control challengesview-input"
                        onChange={(event) => handleFormControls(event, "Goal")}
                      />
                      {isSubmitted && !challengeForm.Goal ? (
                        <div className="text-danger mt-2" style={{fontSize:'12px'}}>Target / Goal is required</div>
                      ): ('')}


                    </div>
                    <div className="col-12  row mt-3 m-0 p-0">
                      {/* <div className="col-6 pl-0">
                        <h5>Start Date </h5>
                        <input
                          id="challenge-startdate"
                          className="form-control challengesview-input"
                          type="date"
                          onChange={(event) => handleFormControls(event, "StartDate")}
                        />
                      </div> */}
                      <div className="col-12  row mt-3 m-0 p-0">
                          <div className="col-6 pl-0">
                          <h5>Start Date </h5>
                          <DatePicker
                                className="SuteInputPrimary h40px"
                                selected={new Date(challengeForm.StartDate)}
                                onChange={handleStartDate}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                                minDate={new Date()}
                              />
                          </div>
                          <div className="col-6 pl-0">
                          <h5>End Date </h5>
                              <DatePicker className="SuteInputPrimary h40px"
                                  selected={getNextDay(challengeForm.EndDate)}
                                 onChange={handleEndDate}
                                 name="endDate"
                                 value={challengeForm.EndDate ? formatUtcDate(challengeForm.EndDate , 'DD/MM/YYYY') : ""}
                                 dateFormat="dd/MM/yyyy"
                                 minDate={getNextDay(challengeForm.StartDate)}
                                  />
                          </div>
                          {isSubmitted && (!challengeForm.StartDate || !challengeForm.EndDate) ? (
                            <div className="text-danger mt-2" style={{fontSize:'12px'}}>First Date and Last Date is required</div>
                          ): ('')}

                      </div>
                    </div>
                    <div className="col-12  row mt-3 m-0 p-0">
                      <div className="col-6 pl-0">
                        <h5>Leader Board Aggregation</h5>
                        <input
                          type="radio"
                          id="sum"
                          name="fav_language"
                          value="sum"
                           checked
                          onChange={(event) => handleAggregationRadio(event, "sum")}
                        />
                        <label htmlFor="sum" className="color-white ml-2 mr-2">
                          Sum
                        </label>
                        <input
                          type="radio"
                          id="average"
                          name="fav_language"
                          value="Average"
                          onChange={(event) => handleAggregationRadio(event, "average")}
                        />
                        <label htmlFor="average" className="color-white ml-2">
                          Average
                        </label>
                      </div>

                      <div className="col-3 p-0">
                        <button
                          type="button"
                          className="btn enroll-btn w-100"
                          onClick={saveChallengeFormData}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default NewChallenges;
