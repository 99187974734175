import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BookEventForGroup, getEmployeeCalanderEvents, GetGroupTimeslots, removeSuccessStatus } from '../../services/CalenderSevice';
import { getGroupMems } from "../../services/ContactsService";
import Calendar from 'react-calendar';
import { StoreClass } from '../../store/Store';
import clsx from 'classnames'
import { t } from '../../i18n';
import { globalStore } from "../../store/global_store";
import { v4 as uuidv4 } from 'uuid';
import MediaSoundTracks from './MediaSoundTracks';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
var staticdate = new Date();

function formatDate(date: any) {
    var fullDate = date;
    var twoDigitMonth = fullDate.getMonth() + 1;
    if (twoDigitMonth.toLocaleString().length == 1)
        twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.toLocaleString().length == 1)
        twoDigitDate = "0" + twoDigitDate;
    var currentDate = fullDate.getFullYear() + '-' + twoDigitMonth + '-' + twoDigitDate;
    return currentDate;
}
class BookReminderForContact {
    Id : string = '';
    SelectedDate: string = formatDate(new Date());
    StartTime: string = '11:00';
    EndTime: string = '11:30';
    GroupGuid: string = '';
    EventDescription: string = '';
    EventName: string = '';
    TimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    IsAssigned: boolean = false;
    Email: string = 'Sute';
    Participants: string[] = [];
    Content: any[] = [];
    MeetingLink: string = '';
    EmpEmail: string = ''
    OrgGuid: string = '';
    EmpGuid: string = '';
    EmpId: number = 0;
    EmpName: string = '';
    StartDate: string = Moment(staticdate).format('MM-DD-YYYY HH:mm');
    EndDate: string = Moment(staticdate).format('MM-DD-YYYY HH:mm');
    IsAlldaySelected: boolean = false;
    StartMeridian: string = 'AM';
    EndMeridian: string = 'AM';
}
interface formError {
    message: string;
}
function GroupTimeSlotPopup(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const modelRef = useRef<HTMLDivElement>();
    const dispatch = useDispatch();
    const employee = useSelector((store: any) => store.EmployeeReducer)
    const myUserId = localStorage.getItem('EmpGuid');
    const myEmpEmail = localStorage.getItem('UserName');
    const myOrgId = localStorage.getItem('OrgGuid');
    const myEmpId = localStorage.getItem('EmpId');
    const myEmpName = localStorage.getItem("FirstName");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [bookReminder, setbookReminder] = useState(new BookReminderForContact());
    const [meetingId, setMeetingId] = useState('');
    const [showMeetingLink, setShowMeetingLink] = useState(false);
    const [showMediaAttachments, setShowMediaAttachments] = useState(false);
    const [showMediaSoundTracks, setShowMediaSoundTracks] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [eventNameErrors, setEventnameErrors] = useState({} as formError);
    const api_URL = process.env.REACT_APP_URL as string;
    const [allDay, setAllDay] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [MeetingGuid , setMeetingGuid] = useState(uuidv4());
    const [isSaveInProgress, setSaveInProgress ] = useState(false);
    const [isError,setIsError] = useState(false)
    const [createEvent, setcreateEvent] = useState(bookReminder);
    const [UpdateEventStatus, setUpdateEventStatus] = useState('');
    const minDate: Date = new Date();
    var selected: string;
    let startTime: string = '';
    let endTime: string = '';
    //var selectedStartDate: Date = new Date();
    //var selectedEndDate: Date = new Date();
    console.log("MeetingGuid",MeetingGuid);
    useEffect(() => {

        console.log(" ====================================== props.show =================================", props.show);
        console.log("book reminder console",bookReminder);
    }, [bookReminder]);
    useEffect(() => {
        // ;
        console.log('change group guid>', props.GroupMems);
        if (props.GroupGuid) {
            let defaultDate = formatDate(selectedDate);
            setbookReminder({ ...bookReminder, GroupGuid: props.GroupGuid, SelectedDate: defaultDate, EventName: '', StartTime: '11:00', EndTime: '11:30', EventDescription: '', EmpEmail: myEmpEmail ? myEmpEmail : '', OrgGuid: myOrgId ? myOrgId : '', EmpId: myEmpId ? parseInt(myEmpId) : 0, EmpName: myEmpName ? myEmpName : '' ,StartMeridian:'AM',EndMeridian:'AM'});
            dispatch(GetGroupTimeslots(props.GroupGuid, defaultDate) as any);
            dispatch(getGroupMems(props.GroupGuid) as any);
        }
        setIsError(false)
    }, [props.GroupGuid]);

    useEffect(() => {
        let emails: string[] = [];
        if (user.teamMems && user.teamMems.length > 0) {
            user.teamMems.map((u: any) => {
                emails.push(u.OfficeEmail);
            });
            setbookReminder({ ...bookReminder, Participants: [...emails] })
        }
    }, [user.teamMems]);

    useEffect(() => {
        if (employee.internalEventCreate && employee.internalEventCreate.message === 'Event Created Successfully') {
            globalStore.showToast({
                type: 'Message',
                title: "Event",
                message: employee.internalEventCreate.message
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(getEmployeeCalanderEvents(myUserId,new Date().getTimezoneOffset()) as any);
            props.close();
        }
    }, [employee.internalEventCreate, employee.events]);

    useEffect(() => {
        if (eventNameErrors.message == 'Select start time and End Time' && bookReminder.StartTime != '' && bookReminder.EndTime != '') {
            setEventnameErrors({ message: '' })
        }
    })

    const Submit = () => {
        bookReminder.GroupGuid= props.GroupGuid;
        setSaveInProgress(true);
        setIsError(true)
        if (bookReminder.IsAlldaySelected == false && bookReminder.StartTime != '' && bookReminder.EndTime != ''
            && bookReminder.EventName && bookReminder.SelectedDate && bookReminder.EventName !== '') {

            const reminderObj: any = bookReminder;
            reminderObj.EmpEmail = myEmpEmail ? myEmpEmail : '';
            reminderObj.EmpId = myEmpId ? myEmpId : 0;
            reminderObj.EmpName = myEmpName ? myEmpName : '';
            let startHours: any = parseInt(reminderObj.StartTime.split(":")[0]);
            var startminutes = reminderObj.StartTime.split(":")[1];
            if (reminderObj.StartMeridian == 'PM') {
                startHours = startHours + 12;
            }
            let endHours: any = parseInt(reminderObj.EndTime.split(":")[0]);
            var endminutes = reminderObj.EndTime.split(":")[1];
            if (reminderObj.EndMeridian == 'PM') {
                endHours = endHours + 12;
            }
            let startDate = selectedDate;
            startDate.setHours(startHours);
            startDate.setMinutes(Number(startminutes));
            startDate.setSeconds(0);
            reminderObj.UTCStartTime = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes());
            let endDate = selectedDate;
            endDate.setHours(endHours);
            endDate.setMinutes(Number(endminutes));
            endDate.setSeconds(0);
            reminderObj.UTCEndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes());
            reminderObj.offset = new Date().getTimezoneOffset();
            reminderObj.AttachmentsContent = JSON.stringify(bookReminder.Content);
            reminderObj.EmpGuid = myUserId;
            reminderObj.OrgGuid = myOrgId ;
            reminderObj.StartDate = selectedDate
            reminderObj.EndDate = selectedDate
            dispatch(BookEventForGroup(reminderObj) as any);
            //bookReminder.ContactGuid
        }
        else if (bookReminder.IsAlldaySelected && bookReminder.StartDate != '' && bookReminder.EndDate != '' && bookReminder.EventName !== '') {
            if (startDate <= endDate) {
                const reminderObj: any = bookReminder;
                reminderObj.EmpEmail = myEmpEmail ? myEmpEmail : '';
                reminderObj.EmpId = myEmpId ? myEmpId : 0;
                reminderObj.EmpName = myEmpName ? myEmpName : '';
                reminderObj.GroupGuid = props.GroupGuid;
                reminderObj.OrgGuid = myOrgId ? myOrgId : '';
                // let startHours: any = parseInt(reminderObj.StartTime.split(":")[0]);
                //var startminutes = reminderObj.StartTime.split(":")[1];
                // if (reminderObj.StartMeridian == 'PM') {
                //     startHours = startHours + 12;
                // }
                // let endHours: any = parseInt(reminderObj.EndTime.split(":")[0]);
                // var endminutes = reminderObj.EndTime.split(":")[1];
                // if (reminderObj.EndMeridian == 'PM') {
                //     endHours = endHours + 12;
                // }
                let startDt = startDate;
                let startHours: any = 12;
                var startminute = 0;
                startDt.setHours(startHours);
                startDt.setMinutes(Number(startminute));
                startDt.setSeconds(0);
                const dt = new Date(startDt.getFullYear(), startDt.getMonth(), startDt.getDate(), 0, 0);
                const utc_Start = Date.UTC(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(),dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds(), dt.getUTCMilliseconds());
                reminderObj.UTCStartTime =utc_Start //Date.UTC(startDt.getUTCFullYear(), startDt.getUTCMonth(), startDt.getUTCDate()  , startDt.getHours(), startDt.getMinutes());
                let endDt = endDate;
                let endHours: any = 23;
                var endminute = 59;
                endDt.setHours(endHours);
                endDt.setMinutes(Number(endminute));
                endDt.setSeconds(0);
                const dte = new Date(endDt.getFullYear(), endDt.getMonth(), endDt.getDate(), 23, 59);
                const utc_end = Date.UTC(dte.getUTCFullYear(), dte.getUTCMonth(), dte.getUTCDate(),dte.getUTCHours(), dte.getUTCMinutes(), dte.getUTCSeconds(), dte.getUTCMilliseconds());
                reminderObj.UTCEndTime = utc_end//Date.UTC(endDt.getUTCFullYear(), endDt.getUTCMonth(), endDt.getUTCDate()  ,endDt.getHours(), endDt.getMinutes() );
                reminderObj.offset = new Date().getTimezoneOffset();
                reminderObj.AttachmentsContent = JSON.stringify(bookReminder.Content);
                reminderObj.EmpGuid = myUserId;
                reminderObj.OrgGuid = myOrgId ;
                dispatch(BookEventForGroup(reminderObj) as any);
            }
            else {
                setEventnameErrors({ message: 'Enter Event Name' });
            }
        }
        else {
            if (!allDay && bookReminder.EventName == '' && bookReminder.StartTime == '' && bookReminder.EndTime == '') {
                setEventnameErrors({ message: 'Select Start time and End Time' });
            }
            else if (bookReminder.EventName == '') {
                setEventnameErrors({ message: 'Enter Event Name' });
            }
            else if (bookReminder.IsAlldaySelected && bookReminder.StartDate != '' && bookReminder.EndDate != '') {
                setEventnameErrors({ message: 'Select Start Date and End Date' });
            }
        }
        setTimeout(() => {
            setSaveInProgress(false);
        }, 2000);
    }

    const TimeSlotSelection = (e: any) => {
        startTime = e.target.innerHTML.substr(0, e.target.innerHTML.indexOf('-'));
        endTime = e.target.innerHTML.substr(e.target.innerHTML.indexOf('-') + 1);
        let EmpGuid = myUserId ? myUserId : '';
        setbookReminder({ ...bookReminder, GroupGuid: props.GroupGuid, StartTime: startTime, EndTime: endTime})
        setEventnameErrors({ message: '' });
        if(bookReminder.EventName == '')
            setEventnameErrors({ message: 'Enter Event Name' });
        
    }

    // function formatDate(date: any) {
    //     var fullDate = date;
    //     var twoDigitMonth = fullDate.getMonth() + 1;
    //     if (twoDigitMonth.toLocaleString().length == 1)
    //         twoDigitMonth = "0" + twoDigitMonth;
    //     var twoDigitDate = fullDate.getDate() + "";
    //     if (twoDigitDate.toLocaleString().length == 1)
    //         twoDigitDate = "0" + twoDigitDate;
    //     var currentDate = fullDate.getFullYear() + '-' + twoDigitMonth + '-' + twoDigitDate;
    //     return currentDate;
    // }
    const onChange = (e: any) => {
        //console.log("select date:", props);
        try {
            selected = formatDate(e);
            setSelectedDate(e);
            const newDate = new Date(e.getTime() + 3600000); 

            changeStartDate(newDate);
            changeEndDate(newDate);
            // setStartDate(newDate)
            // setEndDate(newDate)
            //var date = new Date(selected);
            setbookReminder({ ...bookReminder, SelectedDate: selected, StartTime: '11:00', EndTime: "11:30", StartDate: "", EndDate: "", EventName: "" , EventDescription: "",StartMeridian:'AM',EndMeridian:'AM'})
            dispatch(GetGroupTimeslots(props.GroupGuid, selected) as any);
            const checkbox = document.getElementById('all-day-checkbox') as HTMLInputElement
            if(checkbox.checked) {
                checkbox.click();
            }
            setEventnameErrors({ message: '' });
            setIsError(false)
        }
        catch (ex) {
            console.log(ex);
        }
        console.log("selected date", selected)
    }

    const selectEmail = (email: any) => {
        setbookReminder({ ...bookReminder, Email: email.target.value })
    }

    useEffect(() => {
        if (meetingId != '') {
            console.log('meetingId change');
            setbookReminder({ ...bookReminder, MeetingLink: `${api_URL}/customrm/${MeetingGuid}`,Id:MeetingGuid })
            setShowMeetingLink(true);
        }
        setMeetingId(props.GroupGuid);
    }, [meetingId]);

    // const GenerateMeetingLink = (e: any) => {
    //     //e.preventdefault();
    //     //setMeetingId(getRoomId(14));
    //     setMeetingId(props.GroupGuid);
    // }//

    const addContentClick = () => {
        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(true);
    }

    const closeAddContentClick = () => {
        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(false);
    }

    const AddMediaAttachments = (e: any) => {
        setShowAddPopup(false);
        console.log(e);
        setbookReminder({ ...bookReminder, Content: e })

    }

    const removeMediaAttachments = (index: number) => {
        const list = bookReminder.Content;
        list.splice(index, 1);
        setbookReminder({ ...bookReminder, Content: list });
    }
    const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
         {eventNameErrors.message}
    </div></div></div>);
    const changeStartDate = (date: any) => {
        setStartDate(date);
        setEndDate(date);
        setbookReminder({ ...bookReminder, StartDate: formatDate(date) })
    }
    const changeEndDate = (date: any) => {
        setEndDate(date);
        setbookReminder({ ...bookReminder, EndDate: formatDate(date) })
    }

    const handleAllDayEvent = () => {
        setbookReminder({ ...bookReminder, IsAlldaySelected: !allDay, StartDate: formatDate(selectedDate), EndDate: formatDate(selectedDate)});
        setAllDay(!allDay)
        //setEventnameErrors({ message: '' })
    }
    const compareTimes = (time1: string, meridian1: string, time2: string, meridian2: string): number => {
        // Convert time to 24-hour format for comparison
        let [hour1, minute1] = time1.split(':').map(Number);
        let [hour2, minute2] = time2.split(':').map(Number);

        // Adjust hours based on meridian (AM/PM)
        if (meridian1 === 'PM' && hour1 !== 12) hour1 += 12;
        if (meridian2 === 'PM' && hour2 !== 12) hour2 += 12;
        if (meridian1 === 'AM' && hour1 === 12) hour1 = 0;
        if (meridian2 === 'AM' && hour2 === 12) hour2 = 0;

        // Compare times
        if (hour1 > hour2) {
            return 1; // time1 is greater
        } else if (hour1 < hour2) {
            return -1; // time2 is greater
        } else {
            // Hours are the same, compare minutes
            if (minute1 > minute2) {
                return 1; // time1 is greater
            } else if (minute1 < minute2) {
                return -1; // time2 is greater
            } else {
                // Minutes are also the same, compare meridians
                if (meridian1 === 'PM' && meridian2 === 'AM') {
                    return 1; // time1 is greater (PM > AM)

                } else if (meridian1 === meridian2 && hour1 === hour2) {
                    return 1 // time2 is greater
                } else {
                    return 0; // times are equal
                }
            }
        }
    };
    const changeStartTime = (time: any) => {
        const reminderObj:any=bookReminder;
        const timeValue = time.target.value; 
        const [hourMinute, meridian] = timeValue.split(' ');
        reminderObj.StartMeridian=meridian;
        setbookReminder({
                   ...bookReminder,
                   StartTime: hourMinute,
            }
        )
        if (compareTimes(hourMinute, meridian, reminderObj.EndTime, reminderObj.EndMeridian) === 1) {
            setUpdateEventStatus('Start time cannot be equal or greater than end time.');
        } else {
            setUpdateEventStatus(''); // Clear any previous error message
        }
    };

    const changeEndTime = (time: any) => {
    
        const reminderObj:any=bookReminder
        const timeValue = time.target.value; // "07:00 PM"
        const [hourMinute, meridian] = timeValue.split(' '); // Split by space to get ["07:00", "PM"]
        reminderObj.EndMeridian=meridian;
        // Update state accordingly
        setbookReminder({
            ...bookReminder,
            EndTime: hourMinute,
        });
        if (compareTimes(reminderObj.StartTime, reminderObj.StartMeridian, hourMinute, meridian) === 1) {
            setUpdateEventStatus('End time cannot be equal or less than start time.');
        } else {
            setUpdateEventStatus(''); // Clear any previous error message
        }
        // setEventnameErrors({ message: '' });
        // if(bookReminder.EventName == '')
        //     setEventnameErrors({ message: 'Enter Event Name' });

        // Check if end time is less than start time
        
    };

    function allDayFields(showAllDayFields: boolean) {
        if (showAllDayFields) {
            return (<div className="row">
                <div className="col-sm-6">
                    <div className="form-group mb-1">
                        <label className="col-form-label">{t('calendar.start_date')}:</label>
                        <DatePicker className="SuteInputPrimary h40px"
                            selected={startDate}
                            onChange={date => changeStartDate(date)}
                            name="startDate"
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date()}
                        />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group mb-1">
                        <label className="col-form-label">{t('calendar.end_date')}:</label>
                        <DatePicker className="SuteInputPrimary h40px"
                            selected={endDate}
                            onChange={date => changeEndDate(date)}
                            name="startDate"
                            dateFormat="dd-MM-yyyy"
                            minDate={startDate}
                        />
                    </div>
                </div>
            </div>
            )
        }
        else {
            return " "
        }
    }
    return (
        <Modal
            size='lg'
            ref={modelRef}
            show={props.show}
            onHide={props.close}
            className="modal fade"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <h5 className="modal-title text-white">{t('calendar.schd_appointment')}</h5>
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <Calendar
                                minDate={minDate}
                                onChange={onChange}
                                value={selectedDate}
                            />
                        </div>
                        <div className="col-sm-6 text-white">

                            {/* {bookReminder.SelectedDate && <p><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="#fff"></path></svg> {bookReminder.SelectedDate} {bookReminder.StartTime && <svg className="ml-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 7.5H7a.5.5 0 00.146.354L7.5 7.5zm0 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM7 3v4.5h1V3H7zm.146 4.854l3 3 .708-.708-3-3-.708.708z" fill="#fff"></path></svg>} {bookReminder.StartTime} <select onChange={selectEmail} value={bookReminder.Email} className="SuteInputPrimary float-right emailSelectionDD">
                                 <option disabled selected>select Email</option>
                                <option value="select">{t('calendar.select')}</option>
                                <option value="Sute">Sute</option>
                                {(employee.events || []).map((obj: any, index: number) => {
                                    if (obj.IsShow) {
                                        return (<option key={index} value={obj.Email}>{obj.Email}</option>)
                                    }
                                })}
                            </select></p>} */}
                            {/* {(!allDay) ? <h6>{t('calendar.available_slots')} </h6> : null} */}
                            {!allDay ? bookReminder.StartTime == '' && bookReminder.EndTime == '' && eventNameErrors.message && errormsg : null}
                            {(!allDay) ? <div className=" col-12 row m-0 p-0">
                     <div className="col-6 pl-0">
                        <div className="form-group mb-1">
                            <label className="col-form-label">{t('calendar.start_time')}:</label>
                            <div className="row">
                                <div className="col-sm-12">
                                    <select className="SutetimePrimary h40px radiusright0" value={`${bookReminder.StartTime} ${bookReminder.StartMeridian}`} onChange={changeStartTime} >
                                    <option value="00:00 AM">12:00  <span>AM</span></option>
                                        <option value="00:30 AM">12:30  <span>AM</span></option>
                                        <option value="01:00 AM">01:00  <span>AM</span></option>
                                        <option value="01:30 AM">01:30  <span>AM</span></option>
                                        <option value="02:00 AM">02:00  <span>AM</span></option>
                                        <option value="02:30 AM">02:30  <span>AM</span></option>
                                        <option value="03:00 AM">03:00  <span>AM</span></option>
                                        <option value="03:30 AM">03:30  <span>AM</span></option>
                                        <option value="04:00 AM">04:00  <span>AM</span></option>
                                        <option value="04:30 AM">04:30  <span>AM</span></option>
                                        <option value="05:00 AM">05:00  <span>AM</span></option>
                                        <option value="05:30 AM">05:30  <span>AM</span></option>
                                        <option value="06:00 AM">06:00  <span>AM</span></option>
                                        <option value="06:30 AM">06:30  <span>AM</span></option>
                                        <option value="07:00 AM">07:00  <span>AM</span></option>
                                        <option value="07:30 AM">07:30  <span>AM</span></option>
                                        <option value="08:00 AM">08:00  <span>AM</span></option>
                                        <option value="08:30 AM">08:30  <span>AM</span></option>
                                        <option value="09:00 AM">09:00  <span>AM</span></option>
                                        <option value="09:30 AM">09:30  <span>AM</span></option>
                                        <option value="10:00 AM">10:00  <span>AM</span></option>
                                        <option value="10:30 AM">10:30  <span>AM</span></option>
                                        <option value="11:00 AM">11:00  <span>AM</span></option>
                                        <option value="11:30 AM">11:30  <span>AM</span></option>
                                        <option value="12:00 PM">12:00  <span>PM</span></option>
                                        <option value="12:30 PM">12:30  <span>PM</span></option>
                                        <option value="01:00 PM">01:00  <span>PM</span></option>
                                        <option value="01:30 PM">01:30  <span>PM</span></option>
                                        <option value="02:00 PM">02:00  <span>PM</span></option>
                                        <option value="02:30 PM">02:30  <span>PM</span></option>
                                        <option value="03:00 PM">03:00  <span>PM</span></option>
                                        <option value="03:30 PM">03:30  <span>PM</span></option>
                                        <option value="04:00 PM">04:00  <span>PM</span></option>
                                        <option value="04:30 PM">04:30  <span>PM</span></option>
                                        <option value="05:00 PM">05:00  <span>PM</span></option>
                                        <option value="05:30 PM">05:30  <span>PM</span></option>
                                        <option value="06:00 PM">06:00  <span>PM</span></option>
                                        <option value="06:30 PM">06:30  <span>PM</span></option>
                                        <option value="07:00 PM">07:00  <span>PM</span></option>
                                        <option value="07:30 PM">07:30  <span>PM</span></option>
                                        <option value="08:00 PM">08:00  <span>PM</span></option>
                                        <option value="08:30 PM">08:30  <span>PM</span></option>
                                        <option value="09:00 PM">09:00  <span>PM</span></option>
                                        <option value="09:30 PM">09:30  <span>PM</span></option>
                                        <option value="10:00 PM">10:00  <span>PM</span></option>
                                        <option value="10:30 PM">10:30  <span>PM</span></option>
                                        <option value="11:00 PM">11:00  <span>PM</span></option>
                                        <option value="11:30 PM">11:30  <span>PM</span></option>
                                    </select>
                                </div>
                                {/* <div className="col-sm-6 pl-1">
                                <select className="SutetimePrimary h40px radiusleft0" value={createEvent.StartMeridian} onChange={changeStartMeridian}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>  */}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 pl-0">
                        <div className="form-group mb-1">
                            <label className="col-form-label">{t('calendar.end_time')}:</label>
                            <div className="row">
                                <div className="col-sm-12 pr-1">
                                    <select className="SutetimePrimary h40px radiusright0" value={`${bookReminder.EndTime} ${bookReminder.EndMeridian}`} onChange={changeEndTime} >
                                        <option value="00:00 AM">12:00  <span>AM</span></option>
                                        <option value="00:30 AM">12:30  <span>AM</span></option>
                                        <option value="01:00 AM">01:00  <span>AM</span></option>
                                        <option value="01:30 AM">01:30  <span>AM</span></option>
                                        <option value="02:00 AM">02:00  <span>AM</span></option>
                                        <option value="02:30 AM">02:30  <span>AM</span></option>
                                        <option value="03:00 AM">03:00  <span>AM</span></option>
                                        <option value="03:30 AM">03:30  <span>AM</span></option>
                                        <option value="04:00 AM">04:00  <span>AM</span></option>
                                        <option value="04:30 AM">04:30  <span>AM</span></option>
                                        <option value="05:00 AM">05:00  <span>AM</span></option>
                                        <option value="05:30 AM">05:30  <span>AM</span></option>
                                        <option value="06:00 AM">06:00  <span>AM</span></option>
                                        <option value="06:30 AM">06:30  <span>AM</span></option>
                                        <option value="07:00 AM">07:00  <span>AM</span></option>
                                        <option value="07:30 AM">07:30  <span>AM</span></option>
                                        <option value="08:00 AM">08:00  <span>AM</span></option>
                                        <option value="08:30 AM">08:30  <span>AM</span></option>
                                        <option value="09:00 AM">09:00  <span>AM</span></option>
                                        <option value="09:30 AM">09:30  <span>AM</span></option>
                                        <option value="10:00 AM">10:00  <span>AM</span></option>
                                        <option value="10:30 AM">10:30  <span>AM</span></option>
                                        <option value="11:00 AM">11:00  <span>AM</span></option>
                                        <option value="11:30 AM">11:30  <span>AM</span></option>
                                        <option value="12:00 PM">12:00  <span>PM</span></option>
                                        <option value="12:30 PM">12:30  <span>PM</span></option>
                                        <option value="01:00 PM">01:00  <span>PM</span></option>
                                        <option value="01:30 PM">01:30  <span>PM</span></option>
                                        <option value="02:00 PM">02:00  <span>PM</span></option>
                                        <option value="02:30 PM">02:30  <span>PM</span></option>
                                        <option value="03:00 PM">03:00  <span>PM</span></option>
                                        <option value="03:30 PM">03:30  <span>PM</span></option>
                                        <option value="04:00 PM">04:00  <span>PM</span></option>
                                        <option value="04:30 PM">04:30  <span>PM</span></option>
                                        <option value="05:00 PM">05:00  <span>PM</span></option>
                                        <option value="05:30 PM">05:30  <span>PM</span></option>
                                        <option value="06:00 PM">06:00  <span>PM</span></option>
                                        <option value="06:30 PM">06:30  <span>PM</span></option>
                                        <option value="07:00 PM">07:00  <span>PM</span></option>
                                        <option value="07:30 PM">07:30  <span>PM</span></option>
                                        <option value="08:00 PM">08:00  <span>PM</span></option>
                                        <option value="08:30 PM">08:30  <span>PM</span></option>
                                        <option value="09:00 PM">09:00  <span>PM</span></option>
                                        <option value="09:30 PM">09:30  <span>PM</span></option>
                                        <option value="10:00 PM">10:00  <span>PM</span></option>
                                        <option value="10:30 PM">10:30  <span>PM</span></option>
                                        <option value="11:00 PM">11:00  <span>PM</span></option>
                                        <option value="11:30 PM">11:30  <span>PM</span></option>
                                    </select>
                                </div>
                                {/* <div className="col-sm-6 pl-1">
                                <select className="SutetimePrimary h40px radiusleft0" value={createEvent.EndMeridian} onChange={changeEndMeridian}>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </select>
                            </div>  */}
                            </div>
                        </div>
                    </div>
                    <div className="text-danger ml-2 fw-bold">{UpdateEventStatus}</div>

                                {/* <li className="active"><div className="timeslot"><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827z" fill="#6264a5"></path></svg> 09:00</div></li> */}
                                {/* {([]).map((obj: any, index: number) => {
                                    if (obj.StartTime >= '08:00' && (obj.StartTime < '21:00')) {
                                        console.log("grouptimeslot checking ",)
                                        return (
                                            <li key={'ts-' + index} className={clsx('', obj.StartTime === bookReminder.StartTime ? 'active' : '')} value={bookReminder.StartTime} onChange={e => { setbookReminder({ ...bookReminder }); setEventnameErrors({ message: '' }) }}><div className="timeslot" onClick={TimeSlotSelection}>{obj.StartTime}-{obj.EndTime}</div></li>
                                        )
                                    }
                                })} */}
                            </div> : allDayFields(allDay)}
                            {/* <div>
                                <input type="checkbox" checked={allDay}  onChange={()=>{
                                    setbookReminder({ ...bookReminder, IsAlldaySelected: !allDay });
                                    setAllDay(!allDay)
                                    }
                                    }/> 
                                <span>All Day</span>
                            </div> */}
                            <div className="col-6 mt-2 pt-1"><label className="listhead listhead-checkbox mr-3 float-left">
                                <input type="checkbox" name="allDayChecked" id="all-day-checkbox" onChange={handleAllDayEvent} />
                                <div className="control_indicator"></div></label> All Day
                            </div>
                            {/* <div className="mb-3 col-sm-12 pl-0 addTeamEmails">
                                {(bookReminder.Participants || []).map((e = '') => e.trim()).filter((e: any) => e).map((obj: any, index: number) => {
                                    return (
                                        <div className="teamEmailHolder" key={index}>{obj}</div>)
                                })}
                            </div> */}
                            {/* <div className="form-group mb-1 col-sm-12 pl-0 float-left">
                                <label className="col-form-label fs12"><a className="startMettingLink" href={bookReminder.MeetingLink}> onClick={(e) => e.preventDefault()} </a> <b>{t('calendar.start_meeting')}</b> {t('calendar.copy_url')} {bookReminder.MeetingLink}</label>
                            </div> */}
                            {/* <div className="form-group mb-1 col-sm-12 pl-0 float-left">
                                <label className="col-form-label fs12">
                                    <span className="startMettingLink">
                                        <b>{t('calendar.start_meeting')}</b>
                                    </span>
                                    {t('calendar.copy_url')}{bookReminder.MeetingLink}
                                </label>
                            </div> */}
                            {/* <div className="form-group mb-1 col-sm-12 pl-0 float-left">
                                <label className="col-form-label c-pointer" data-toggle="modal" onClick={addContentClick}><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 6.5L1.328 9.672a2.828 2.828 0 104 4L8.5 10.5m2-2l3.172-3.172a2.829 2.829 0 00-4-4L6.5 4.5m-2 6l6-6" stroke="currentColor"></path></svg> Attach Content</label>
                            </div> */}
                            <div className="mb-1 col-sm-12 pl-0 addTeamEmails">
                                {(bookReminder.Content || []).map((obj: any, index: number) => {
                                    return (
                                        <div className="teamEmailHolder" key={index}>{obj.TrackName} <img className="c-pointer" onClick={(e) => removeMediaAttachments(index)} src="assets/media/svg/onboarding/x-close.svg" /></div>)
                                })}
                            </div>
                            {isError && bookReminder.EventName == '' && bookReminder.StartTime != '' && bookReminder.EndTime != '' && errormsg}
                            <div className="row">
                                <div className="col-12">
                                    <label>Enter event name <sup>*</sup></label>
                                    {/* <textarea className="w-100 SuteInputPrimary fs10" value={bookReminder.EventName} onChange={e => setbookReminder({ ...bookReminder, EventName: e.target.value })}></textarea> */}
                                    <input type="text" placeholder="" className="SuteInputPrimary h40px" value={bookReminder.EventName} onChange={e => setbookReminder({ ...bookReminder, EventName: e.target.value })} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                <label>Enter event description</label>
                                    <textarea className="w-100 SuteInputPrimary fs10" value={bookReminder.EventDescription} onChange={e => setbookReminder({ ...bookReminder, EventDescription: e.target.value })}></textarea>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <button type="button" onClick={Submit} disabled={isSaveInProgress} className="btn btn-SeaGreen btn-sm">{t('button.submit')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MediaSoundTracks tracks={bookReminder.Content || []} show={showAddPopup} close={closeAddContentClick} AddMediaContent={(e: any) => AddMediaAttachments(e)} />
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default React.memo(GroupTimeSlotPopup);