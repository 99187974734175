import React, { useEffect, useState } from 'react';
import SetupComponent from '../uicomponents/SetupComponent';
import { Link, useNavigate } from "react-router-dom";

function Company(props: any) {

    return (
        <section className="bgCadet position-relative d-flex minh-100">
            <div className="container align-self-center">
                <div className="row justify-content-center">
                    <div className="cRoomHeader justify-content-center mb-0 mt-4">
                        <div className="cRsuteLogo align-self-center">
                            <Link to="/companies"><img src="/assets/media/images/logo.png" /></Link>
                        </div>
                        <div className="cRTitle w-auto">
                            <h5>Company</h5>
                            <p></p>
                        </div>
                    </div>
                    <SetupComponent redirectTo="companies" isCreate="true" />
                </div>
            </div>
           
        </section>
    );
}

export default Company;