import { Dispatch } from "redux";
import {getRequest} from "../utils/http";
import * as T  from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;

export const GetEmpTotalSessions = (empGuid: string) => async (dispatch: Dispatch) => {       
    getRequest(`${api_domian}${api_port}/api/Analytics/GetEmployeeSessions?empGuid=${empGuid}`)
    .then((resp: any)=>{
        dispatch({
            type: T.GET_SESSION_TOTALS,
            payload: resp
        });           
    }).catch(e=>{
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    });
 }
 
 export const GetEmpSessionsSplit = (empGuid: string) => async (dispatch: Dispatch) => {       
    getRequest(`${api_domian}${api_port}/api/Analytics/GetEmployeeSessionsLength?empGuid=${empGuid}`)
    .then((resp: any)=>{
        dispatch({
            type: T.GET_SESSIONS_SPLIT,
            payload: resp
        });           
    }).catch(e=>{
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    });
 }
 
 export const GetEmpSessionsTrace = (empGuid: string) => async (dispatch: Dispatch) => {       
    getRequest(`${api_domian}${api_port}/api/Analytics/GetEmployeeProductiveandNonProductiveSessionsLength?empGuid=${empGuid}`)
    .then((resp: any)=>{
        dispatch({
            type: T.GET_PRODUCTIVE_NON_PRODUCTIVE,
            payload: resp
        });           
    }).catch(e=>{
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    });
 }
 export const GetSessions = (empGuid: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: T.ADD_LOADING
    })       
    getRequest(`${api_domian}${api_port}/api/Analytics/GetSessions?empGuid=${empGuid}`)
    .then((resp: any)=>{
        dispatch({
            type: T.GET_SESSION_TOTALS,
            payload: resp.result
        });
        dispatch({
            type: T.REMOVE_LOADING
        })           
    }).catch(e=>{
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
        dispatch({
            type: T.REMOVE_LOADING
        })
    });
 }