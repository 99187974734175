import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import { useState, useEffect } from "react";
import { MdAttachFile } from "react-icons/md";
import { GetOngoingChallenges, GetCompletedChalleges, GetLeaderBoard} from "../../services/WellnessServices";
import * as T from "../../actions/Types";
import { Challenge, OngoingChallangesDetails } from "../../utils/types";
import { formatDateForInput } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Modal } from 'react-bootstrap';
import { FaDiamond } from "react-icons/fa6";
import Moment from 'moment';



function CompletedChallenges(props: any) {
  const [completedChallengesList, setCompletedChallengesList] = useState([] as Challenge[]);
  const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
  const [apiStatus, setApiStatus] = useState("");
  const orgGuid = localStorage.getItem("OrgGuid") || "";
  const [completedEnrollList,setCompletedEnrollList] = useState([] as any)
  const [modalVisible, setModalVisible] = useState(false);
  const [showArraw,setShowArrow] = useState(false)
  const [leaderBoardList,setLeaderBoardList] = useState([] as any)
  const [modalVisibleLeaderBoard,setModalVisibleLeaderBoard] = useState(false)
  const [completedChalleneges,setCompletedChalleneges] = useState([] as any)
  const [activeEnrollId, setActiveEnrollId] = useState(null);
  const formattedDate = process.env.REACT_APP_DATE_FORMAT 

  const dispatch = useDispatch();

  useEffect(() => {
    if (completedChallengesList && completedChallengesList.length > 0) {
        const updatedChallenges = completedChallengesList.map((challenge: any, i: number) => ({
            ...challenge,
            TotalCount: completedChallengesList.length,
            RowNumber: i + 1
        }));

        setCompletedChalleneges(updatedChallenges?.filter((completedtFile: any) =>
          completedtFile.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && completedtFile.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );

    } else
    setCompletedChalleneges([]);
} , [completedChallengesList]);

useEffect(() => {
    if (completedChallengesList && completedChallengesList.length > 0) {           
        const completed = completedChallengesList.filter((orgFile: any) =>
            orgFile.ChallengeName.toLowerCase().includes(filesTableParams.Search.toLowerCase())
        );

        completed.forEach((complete: any, i: any) => {
          complete.TotalCount = completed.length, complete.RowNumber = i + 1
        });

        setCompletedChalleneges(completed.filter((complete: any) =>
          complete.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
            && complete.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
        );
    }
}, [filesTableParams])

  useEffect(() => {
    dispatch({
      type: T.ADD_LOADING,
    });
    GetOngoingChallenges(orgGuid, 0)
      .then((resp: any) => {
        setCompletedChallengesList([...resp.result]);

        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "error") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
  }, [leaderBoardList,completedEnrollList]);

  function mergeEnrollments(data: any) {
    const empMap = new Map();

    data.forEach((entry: any) => {
        const empId = entry.EmpId;
        const enrollData = {
            StartingValue: entry.StartingValue,
            EnrollDate: entry.EnrollDate,
            Status:entry.Status
        };

        if (empMap.has(empId)) {
            empMap.get(empId).Enroll.push(enrollData);
        } else {
            empMap.set(empId, {
                EmpId: empId,
                FirstName: entry.FirstName,
                FileUrl: entry.FileUrl,
                Enroll: [enrollData],
                EnrollId: entry.EnrollId
            });
        }
    });

    return Array.from(empMap.values());
}



function headerRender(completedChallengesList : any) {
  // Check if any item in the list has a StepId
  const hasStepId = completedChallengesList.some((challenge : any) => challenge.StepId);

  return hasStepId ? (    
       <tr>
        <th>Description</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th style={{textAlign:"center"}}>Participants</th>
      </tr>
   ) : null;
  }


  const handleClick = (row : any) => {
    dispatch({
      type: T.ADD_LOADING,
    });
    GetCompletedChalleges(orgGuid, row.StepId)
      .then((resp: any) => {
        const mergedChallenges = mergeEnrollments(resp.result)
        console.log(">>>>", mergedChallenges);     
        setCompletedEnrollList(mergedChallenges);

        if (resp.status === "success") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "error") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
    setModalVisible(true)
  };
    console.log(completedEnrollList)

    const handleLeaderBoard = (row : any) =>{
      dispatch({
        type: T.ADD_LOADING,
      });
      GetLeaderBoard(orgGuid, row.StepId)
        .then((resp: any) => {
          setLeaderBoardList([...resp.result]); 
          if (resp.status === "success") {
            dispatch({
              type: T.REMOVE_LOADING,
            });
          } else if (resp === "error") {
            dispatch({
              type: T.REMOVE_LOADING,
            });
          }
        })
        .catch((e: any) => {
          dispatch({
            type: T.REMOVE_LOADING,
          });
  
          return "error";
        });
      setModalVisibleLeaderBoard(true)
    }
    console.log(leaderBoardList)

   
  function rowRender(row: OngoingChallangesDetails, index: number) {
    return (
      <tr key={index}>
          <td style={{width:'350px'}}>{row.ChallengeName}</td>
          <td>{Moment(row.StartDate).format(formattedDate)}</td>
          <td>{Moment(row.EndDate).format(formattedDate)}</td>
          <td onClick={row.EmpMembers !== 0 ? (e) => handleClick(row) : undefined} 
            style={{cursor: row.EmpMembers !== 0 ? 'pointer' : 'default', fontSize: "20px", textAlign: "center"}} >
            {row.EmpMembers !== 0 ? <a className="leaderboard-link value">{row.EmpMembers}</a> : 0}
          </td>
          <td>
            {row.EmpMembers !== 0 && 
              <span onClick={(e) => handleLeaderBoard(row)}>
                <a className="leaderboard-link">Leader Board</a>
              </span>
            }
          </td>        
      </tr>
    );
  }

  const handleAccordion = (enrollId :any) => {
    setActiveEnrollId((prevActiveEnrollId) => 
      prevActiveEnrollId === enrollId ? null : enrollId
    );
  };

  return (
    <>
      {/* <ConfirmPopup show={showConfirmDeleteUser} close={handleCloseDeleteUser} okHandle={deleteUser} message={"Do you want to " + deleteText.toLowerCase() + " this user?"} /> */}
      <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
        <div className="container-fluid">
          {apiStatus ? (
            <p className="alert alert-danger col-2 ml-auto mr-auto mt-3 text-center">{apiStatus}</p>
          ) : (
            ""
          )}
          {/* <div className="container-fluid">
                {apiStatus ? <p className="alert alert-danger col-2 ml-auto mr-auto mt-3 text-center">{apiStatus}</p> : ''} */}
          <div className="row mt-4 justify-content-center">
            <Table
              id="Completed-challenges"
              data={completedChalleneges || []}
              headerRender={() => headerRender(completedChallengesList)}
              rowRender={rowRender}
              onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
              onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
              rootUrl="/AdminChallenges"
              header={"Completed Wellness initiatives"}
              subHeader=""
              noDataMsg="No challenges found"
              {...props}
            ></Table>
           <div className="col-10 ml-auto">
           {modalVisible &&
              <Modal backdrop="static" onHide={() => setModalVisible(false)} show={modalVisible}>
                  <Modal.Header >
                  <h4 className="color-white">Participants</h4>
                      <span className="close" style={{color:"white", cursor: "pointer" }} title="Close" onClick={() => setModalVisible(false)}>&times;</span>
                  </Modal.Header>
                      <Modal.Body>
                    {completedEnrollList && completedEnrollList.length > 0 ? (
                      completedEnrollList.map((item: any, index: number) => ( 
                          <Accordion>
                                    <Accordion.Item eventKey={item.EnrollId}>
                                        <Accordion.Header 
                                        // onClick={(e) => handleAccordion(item.EnrollId)}
                                        >
                                          <>
                                             {/* {activeEnrollId === item.EnrollId ? <svg style={{fontSize:"30px"}} stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M5.8 9.7l6.2 6.3 6.2-6.3c.2-.2.3-.5.3-.7s-.1-.5-.3-.7c-.2-.2-.4-.3-.7-.3h-11c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7s.1.5.3.7z"></path></svg>: */}
                                              <svg
                                              stroke="currentColor"
                                              fill="currentColor"
                                              strokeWidth="0"
                                              viewBox="0 0 24 24"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"></path>
                                            </svg>
                                            {/* }  */}
                                            <span><img src={item.FileUrl || "/assets/media/images/profile-default.PNG"} className="participents-img" /></span>
                                            <span>{item.FirstName}</span>                                         
                                          </>
                                        </Accordion.Header>
                                            <Accordion.Body>
                                            <div className="col-12 row p-0 m-0 color-white">
                                                <div className="col-4">
                                                    <h6>Enroll Date</h6>
                                                </div>
                                                <div className="col-3">
                                                    <h6>Value</h6>
                                                </div>
                                                <div className="col-2">
                                                    <h6>Status</h6>
                                                </div>
                                            </div>
                                            {item.Enroll.map((e: any, index: number) => {
                                                    return (
                                              <div className="col-12 row p-0 m-0 color-white" key={index}>
                                                  <div className="col-4">
                                                    <span className="fontsize-14">{Moment(e.EnrollDate).format(formattedDate)}</span>
                                                  </div>
                                                  <div className="col-3">
                                                    <span className="fontsize-14">{e.StartingValue}</span> 
                                                  </div>
                                                  <div className="col-2" style={{textAlign:"center"}}>

                                                  <div title={e.Status} className={e.Status === 'approved' ? "green dot" : e.Status === 'reject' ? 'red dot customcursor' : 'orange dot'}>
                                                   
                                                  </div>
                                                  </div>
                                              </div>
                                                );
                                              })} 
                                            {/* <span><h6 className="color-white">EnrollDate  Value</h6></span>
                                              {item.Enroll.map((e: any, index: number) => {
                                                    return (
                                                        <div key={index} className="color-white">
                                                       
                                                         
                                                        </div>
                                                    );
                                                })}                                             */}
                                            </Accordion.Body>
                                    </Accordion.Item>
                              </Accordion>
                             ))
                          ) : ""
                       }
                      </Modal.Body>
            </Modal>                          
            } 
               
             </div>
             <div className="col-10 ml-auto">
              {modalVisibleLeaderBoard &&
               <Modal backdrop="static" onHide={() => setModalVisibleLeaderBoard(false)} show={modalVisibleLeaderBoard}>
               <Modal.Header >
               <div className="modal-title h5 modal-title h4 color-white">Are you sure?</div>
                  {/* <h6 className="color-white">Leader Board</h6> */}
                   <span className="close" style={{color:"white", cursor: "pointer" }} title="Close" onClick={() => setModalVisibleLeaderBoard(false)}>&times;</span>
               </Modal.Header>
                   <Modal.Body>
                   {leaderBoardList && leaderBoardList.length > 0 ? (
                      leaderBoardList.map((item : any,index : number) => (                      
                          item.NameOffEmployee && (
                            <div className="row mt-3 small" key={index}>
                              <div className="col-12 pb-1 pt-1 contactsLoop border-bottom border-secondary">
                                <div className="float-left">                  
                                  <svg
                                    className="ParticipantImage stroke c-pointer active"
                                    viewBox="0 0 100 100"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <defs>
                                      <pattern
                                        id={`pattern-${item.TotalStartingValue}`} 
                                        patternUnits="userSpaceOnUse" 
                                        width="100" 
                                        height="100"
                                      >
                                        <image
                                          href= {item.FileUrl || "/assets/media/images/sute_user_default.png"}
                                          x="-25"
                                          width="150"
                                          height="100"
                                        />
                                      </pattern>
                                    </defs>
                                    <polygon
                                      id={`hex-${item.TotalStartingValue}`}
                                      points="50 1 95 25 95 75 50 99 5 75 5 25"
                                      fill={`url(#pattern-${item.TotalStartingValue})`}
                                    ></polygon>
                                  </svg>
                                </div>
                                <div className="float-left">
                                  <p className="ParticipantName">{item.NameOffEmployee}&nbsp;&nbsp;<FaDiamond />&nbsp;&nbsp;{item.TotalStartingValue}</p>
                                </div>
                              </div>
                            </div>
                          )
                          ))
                          ) : (
                              <div className="text-center no-data-text-center">
                                   <h5 className="color-white">No Leader found</h5>
                              </div>
                          )}
                   </Modal.Body>
         </Modal>  
              }
             </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CompletedChallenges;