var count = 0;
var logs:string[] = [];
  class suteLogs  {
  
  constructor () {    
  }
   print = () => {
    console.clear();
            logs.forEach(msg =>{
                console.log(msg);
            })
    
            
        }
        clear = () => {
         logs = [];
         count = 0;
              }
      public setLog = (msg:any) => {
         
           logs.push(++count + ' * '+msg)
                }
};


  
export default suteLogs;