import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '../../i18n';

function Effects(props: any) {
    let empId = localStorage.getItem('EmpGuid') as string;
    const profileImage = localStorage.getItem('ProfileImgUrl') as string;
    const dispatch = useDispatch();
    useEffect(() => {
       
    }, [])
    return (
       
            <div className="row">
                <div className="col-12">
                    <h6 className="device-name">No effect &amp; blur</h6><div className="col-12 p-0">
                        <span className="blur-icon" onClick={props.disableVirtualBackground}><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg></span>
                        <span className={(props.selectedBG == 'BLUR' ? 'bgActive' : '')+" blur-icon"} onClick={props.onBlur} ><svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 13c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3 .5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM6 5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm15 5.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM14 7c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-3.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-11 10c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm7 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-17c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zM10 7c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 5.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm8 .5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 8.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zM14 17c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-4-12c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0 8.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm4-4.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"></path></svg></span></div>
                    <h6 className="device-name mt-2">Background Colors</h6><div className="col-12 p-0">
                        <span className={(props.selectedBG == '#ffffff' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#ffffff')} style={{ background: '#ffffff' }}></span>
                        <span className={(props.selectedBG == '#faebd7' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#faebd7')} style={{ background: '#faebd7' }}></span>
                        <span className={(props.selectedBG == '#00ffff' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#00ffff')} style={{ background: '#00ffff' }}></span>
                        <span className={(props.selectedBG == '#7fffd4' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#7fffd4')} style={{ background: '#7fffd4' }}></span>
                        <span className={(props.selectedBG == '#0000ff' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#0000ff')} style={{ background: '#0000ff' }}></span>
                        <span className={(props.selectedBG == '#d3c921' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#d3c921')} style={{ background: '#d3c921' }}></span>
                        <span className={(props.selectedBG == '#ff0000' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#ff0000')} style={{ background: '#ff0000' }}></span>
                        <span className={(props.selectedBG == '#a52a2a' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#a52a2a')} style={{ background: '#a52a2a' }}></span>
                        <span className={(props.selectedBG == '#deb887' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#deb887')} style={{ background: '#deb887' }}></span>
                        <span className={(props.selectedBG == '#5f9ea0' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#5f9ea0')} style={{ background: '#5f9ea0' }}></span>
                        <span className={(props.selectedBG == '#ff7f50' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#ff7f50')} style={{ background: '#ff7f50' }}></span>
                        <span className={(props.selectedBG == '#6495ed' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#6495ed')} style={{ background: '#6495ed' }}></span>
                        <span className={(props.selectedBG == '#dc143c' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#dc143c')} style={{ background: '#dc143c' }}></span>
                        <span className={(props.selectedBG == '#483d8b' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#483d8b')} style={{ background: '#483d8b' }}></span>
                        <span className={(props.selectedBG == '#e9967a' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#e9967a')} style={{ background: '#e9967a' }}></span>
                        <span className={(props.selectedBG == '#8fbc8f' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#8fbc8f')} style={{ background: '#8fbc8f' }}></span>
                        <span className={(props.selectedBG == '#00ff00' ? 'bgActive' : '')+" fixed-color-1 fixed-color"} onClick={() => props.onColor('#00ff00')} style={{ background: '#00ff00' }}></span></div>
                    <h6 className="device-name mt-2">Background Image</h6><div className="col-12 p-0" >
                        <span onClick={() => props.onImage('ve1.jpg')}><img src="/assets/media/images/effects/ve1.jpg" className={(props.selectedBG == 've1.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve2.jpg')}><img src="/assets/media/images/effects/ve2.jpg" className={(props.selectedBG == 've2.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve3.jpg')}><img src="/assets/media/images/effects/ve3.jpg" className={(props.selectedBG == 've3.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve4.jpg')}><img src="/assets/media/images/effects/ve4.jpg" className={(props.selectedBG == 've4.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve5.jpg')}><img src="/assets/media/images/effects/ve5.jpg" className={(props.selectedBG == 've5.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve6.jpg')}><img src="/assets/media/images/effects/ve6.jpg" className={(props.selectedBG == 've6.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve7.jpg')}><img src="/assets/media/images/effects/ve7.jpg" className={(props.selectedBG == 've7.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve8.jpg')}><img src="/assets/media/images/effects/ve8.jpg" className={(props.selectedBG == 've8.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve9.jpg')}><img src="/assets/media/images/effects/ve9.jpg" className={(props.selectedBG == 've9.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve10.jpg')}><img src="/assets/media/images/effects/ve10.jpg" className={(props.selectedBG == 've10.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve11.jpg')}><img src="/assets/media/images/effects/ve11.jpg" className={(props.selectedBG == 've11.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                        <span onClick={() => props.onImage('ve12.jpg')}><img src="/assets/media/images/effects/ve12.jpg" className={(props.selectedBG == 've12.jpg' ? 'bgActive' : '')+" video-bg"} /></span>
                         </div>
                </div>
            </div>
        
    );
}

export default React.memo(Effects);