
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HangoutMenu from '../onboarding/HangoutMenu';
const community = [{ name: "Challenges", link: "/curatedchallenges" },{ name: "Events", link: "/chatroulette" } ,{ name: "Games", link: "/leaderboard" } ]
function Leaderboard(){
    const media = useSelector((store: any) => store.MediaReducer);
    return (<>
   <section className="overflow-hidden position-relative h-100"
            style={{ backgroundImage: `url(/assets/media/images/Picture1.jpg)`, "backgroundSize": "cover", "backgroundPosition": "center" }}>
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={"/hangoutroom"}><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                            <div className="cRTitle p-3"><h5>Community & Culture</h5></div>
                        </div>
                    </div>
                </div> 
            </div>
        </div> 
    </section>
    <HangoutMenu meetingId={'meetingPId'} arr={community} showChillMenu={false} showCommunityMenu={true}  />
    </>);
}
export default Leaderboard;