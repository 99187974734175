import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { GetContacts } from '../../services/UserActions';
import { connect } from 'react-redux';
import { AddMemberToGroup } from "../../services/ContactsService";
import { t } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupMems } from '../../services/ContactsService';
interface ContactOption {
    FirstName: string;
    EmpGuid: string;
}
const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    GetContacts: (orgId: string, empId: string) => {
        dispatch(GetContacts(orgId, empId));
    }, AddMemberToGroup: (params: any) => {
        dispatch(AddMemberToGroup(params));
    }
});


function AddMemberPopup(props: any) {
    const modelRef = useRef<HTMLDivElement>();
    const [options, setOptions] = useState<any[]>([]);
    const [addMembers, setAddMembers] = useState<any[]>([]);
    const [saveStatus, setSaveStatus] = useState(false);
    const empId = localStorage.getItem('EmpGuid') || '';
    const orgId = localStorage.getItem('OrgGuid');
    const typeaheadRef = useRef<Typeahead<ContactOption>>(null);
    const dispatch = useDispatch();
    const user = useSelector((store: any) => store.AuthReducer)
    const addMemberToList = (option: any[]) => {
        if (option.length == 0) return;

        if (options.length > 0) {
            const Options = options.filter((m: any) => m.EmpGuid !== option[0].EmpGuid);
            setOptions(Options)
        }

        const existing = props.user.teamMems.filter((m: any) => m.EmpGuid === option[0].EmpGuid);
        if (existing.length > 0) {
            console.log('existing contact selected');
        } else {
            const addMem = addMembers.filter((member: any) => member.EmpGuid === option[0].EmpGuid);
            console.log('adding', addMembers, addMem);
            if (addMem.length == 0) {
                setAddMembers([...addMembers, ...option]);
                //typeaheadRef.current&&typeaheadRef.current.clear();
                typeaheadRef.current.clear();
            }
        }
    }

    useEffect(() => {
        // chatClient.login(myid || '');
        if (!props.user.contacts || props.user.contacts.length === 0) {
            props.GetContacts(orgId, empId);
        }
    }, [props.showAddPopup])
    useEffect(() => {
        if (props.groupId) {
            dispatch(getGroupMems(props.groupId) as any)
        }
    }, [])
    useEffect(() => {
        if (props.user.contacts && props.user.contacts.length > 0) {
            console.log(" cons 3", props.user.contacts);
            const options = props.user.contacts.filter((con: any) => {
                const exists = user.teamMems.filter((ext: any) => ext.EmpGuid === con.EmpGuid);
                if (exists.length > 0)
                    return false;
                return true;
            });
            setOptions(options);
        }
    }, [props.user.contacts]);

    const handleSubmit = () => {
        addMembers.forEach((contact: any) => {
            const form = new FormData();
            form.append("empGuid", contact.EmpGuid);
            form.append("groupGuid", props.groupId);
            if (localStorage.getItem("msAccessToken"))
                form.append("teamsAccessToken", localStorage.getItem("msAccessToken") as string);
            props.AddMemberToGroup(form);
        });
        props.close()
        //setTimeout(() => props.close(), 2000);
        setAddMembers([]);
    }
    const removeSelected = (empId: string) => {
        const addMem = addMembers.filter((member: any) => {
            if (member.EmpGuid !== empId) {
                return member
            }
            else {
                setOptions([...options, member]);
                return false
            }
        });
        setAddMembers([...addMem]);
    }

    return (
        <Modal
            ref={modelRef}
            show={props.show}
            onHide={props.close}
            className="addTeamMemberBox"
            backdrop="static"
            keyboard={false}
        >
            <div className="modal-header border-0">
                <h6 className="modal-title mt-0 text-white">{t('addmemberpopup.add_members_to_room')}</h6>
                <button id="eventCloseBtn" title="Close" type="button" onClick={props.close} className="close" aria-label="Close">
                    <span className="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <Modal.Body>
                <div className="card-body">
                    <div className="form-group position-relative">
                        <Typeahead
                            ref={typeaheadRef}
                            onChange={addMemberToList}
                            id="async-example"
                            labelKey="FirstName"
                            minLength={0}
                            options={options}
                            placeholder="Choose from contacts"
                        /></div>
                    <div className="addTeamEmails" style={{ color: '#ffffff' }}>
                        {addMembers && addMembers.map((ele: any) => {
                            return (<div key={ele.EmpGuid} className="teamEmailHolder">{ele.FirstName} <img onClick={() => removeSelected(ele.EmpGuid)} src="/assets/media/svg/onboarding/x-close.svg" /></div>)
                        })}
                    </div>
                    <div className="form-group">
                        <button onClick={handleSubmit} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-left  mt-4 mb-4">{t('button.add')}</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberPopup);