import * as T from '../actions/Types';

const initialState = {
    networkRequests: 0,
    identity: null,
    lastError: null
  };
  
  export default function (state = initialState, action:any) {
    switch (action.type) {
      case T.NETWORK_ERROR:
        return { ...state, lastError: action.error };
      case T.NETWORK_START:
        return { ...state, networkRequests: state.networkRequests + 1 };
      case T.NETWORK_STOP:
        return { ...state, networkRequests: state.networkRequests - 1 };
      case T.SIGN_IN:
        return { ...state, identity: action.identity };
      case T.SIGN_OUT:
        return { ...state, identity: null };
      default:
        return state;
    }
  }