import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch  } from "react-redux";

import "video-react/dist/video-react.css";
import ReactPlayer from 'react-player';
import { GetTracksListed } from "../../services/WellnessServices";
import * as T from "../../actions/Types";


function FitnessVideos(props: any) {
  //const mediaType = "86D619A0-BABD-466B-8D18-E821078C569F";
  const dispatch = useDispatch();
  const [mediaSoundTrack,setMediaSoundTrack] = useState([] as any)
  // const { Id } = useParams();  
  // const [mediaType, setMeetingId] = useState<string>(Id || '');
  const location = useLocation();
  const mediaType = location.state.typeGuid;

  useEffect(() => {  
          debugger
    if (mediaSoundTrack && (mediaSoundTrack.length === 0)) {
        dispatch({
            type: T.ADD_LOADING
        })
        GetTracksListed(mediaType)
            .then((resp: any) => {
              
              setMediaSoundTrack([...resp.result])
                console.log("getting data",resp.result)

                if (resp.status === "success") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
                else if (resp === "error") {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })
                }
            })
            .catch((e : any) => {
                dispatch({
                    type: T.REMOVE_LOADING
                })

                return "error"
            })
    }    
                        
}, [])

console.log("===========>",mediaSoundTrack)
  return (
    <>
      <section className="myProfilePage position-relative d-flex mih-100">
        {/* <WellnessMenu type = {[{name:"Videos",link:"/fitnessvideos",image:"/assets/media/svg/happyhouricon.svg",select:true},{name:"Sound Bites",link:"",image:"/assets/media/svg/wellness.svg",select:false}]}/> */}
        <div className="SideNav text-left position-fixed d-none">
          <div className="sideNavHeader">
            <div className="CloseNav">
              {" "}
              <img
                src="assets/media/svg/x-close.svg"
                alt="Close Side Nav"
                title="Close"
              />{" "}
            </div>
          </div>
          <div className="sideNavBody">
            <div className="row mt-4 small">
              <div className="col-12 mb-2 text-light">
                {" "}
                <img
                  src="assets/media/svg/builiten-board/the-well.svg"
                  height="30"
                />{" "}
              </div>
            </div>
            <div className="row small mt-4">
              <div className="col-12 text-left text-light">
                <h6 className="m-0">
                  Build a stronger, healthier immune system
                </h6>
                <p className="text-muted mb-2">
                  Most of new line of immune suppliments five unique products -
                  each designed to support your unique health needs, lifestyle,
                  preferances and dietary restrictions.{" "}
                </p>
              </div>
            </div>
            <div className="row mt-2 small c-pointer">
              <div className="col-12 text-left c-SeaGreen">
                {" "}
                View more
                <svg
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                >
                  <path
                    d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                    stroke="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-9 m-auto">
              <div className="cRoomHeader pt-2">
                <div className="cRsuteLogo align-self-center">
                  <Link to="/information">
                    <img src="/assets/media/images/logo.png" />
                  </Link>
                </div>
                <div className="cRTitle p-3">
                  <h5>Wellness Hub / Fitness / Videos</h5>
                </div>
              </div>

             
            </div>
            <div className="col-9 m-auto">
              <div className="mediaGrid">
              {
              mediaSoundTrack  && mediaSoundTrack .length > 0  ?

               mediaSoundTrack .map((item: any, index: number) => (
                <div className="mediaItem"  key={index}>
                    <div style={{ height: "200px", position: 'relative' }}>
                      <ReactPlayer
                        url={item.TrackURL}
                        playing
                        light={<img style={{ width: '100%' }} src={`${item.Thumbnail}`}></img>}
                        controls={true}
                        width="100%"
                        height={200}
                      />                    
                    </div>
                    <div className="mediaDetails">
                      <h4 className="mt-3 text-white h6">{item.TrackName}</h4>
                      <p className="text-muted fs12">
                       {item.Description}
                      </p>
                    </div>
                </div>)) 
                :<div style={{width:'300%',textAlign:'center',alignItems:'center',color:'white'}}><h5> no videoes found</h5></div> 
                } 
               
              </div>
            </div>
          </div>
        </div>
        <div className="text-right mt-3 opacity color-white watermark">
          <h4>Under Construction</h4>
        </div>
      </section>
    </>
  );
}

export default FitnessVideos;
