import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import WellnessMenu from "./wellnessMenu";
import { useSelector } from "react-redux";

function happyHours(props: any) {
    const media = useSelector((store: any) => store.MediaReducer);
    const[happyHoursSidenav,setHappyhoursSidenav] = useState(true);
    return (<>

        <section className="myProfilePage position-relative d-flex h-100">
        {(happyHoursSidenav)?<WellnessMenu type = {[{name:"Chat Roulette",link:"/chatroulette",image:"/assets/media/svg/guests.svg",select:false},{name:"Happy Hours",link:"/happyhours",image:"/assets/media/svg/happyhouricon.svg",select:true}]} setHappyhoursSidenav={setHappyhoursSidenav}/>:<div className="open-sidemenu" onClick={() => { setHappyhoursSidenav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>}
                <div className="container">
            <div className="container-fluid">
                <div className="row mh-100">
                <div className="col-sm-10 pb-2 mt-3">
                                <div className="cRoomHeader pt-2 mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/hangoutroom/"+media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Community & Culture / Socialize / Happy Hours</h5>
                                        {/* <p>All Partners</p> */}
                                    </div>
                                </div>
                            </div>
                    <div className="col-10 mt-4">
                        <div className="card bgCadet mh-95">
                            <div className="card-body text-white small">
                                <h4 className="text-light mb-4">Happy Hours</h4>
                                <div className="align-self-center w-30 m-auto position-relative small text-center pt-5 d-none">
                                    <img src="/assets/media/svg/happyHourNA.svg" className="mb-2 w-50" />
                                    <h5>No Happy Hours Schedule</h5>
                                    <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                <div className="row happyHourRow">
                                    <div className="col-10">
                                        <p className="mb-1">Happy Hours 1</p>
                                        <ul className="list-inline mb-0 small text-muted">
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/clock-time.svg" /> Firday, 22 Jan, 5:30 Pm - 6:30 Pm
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/guests.svg" /> 2 Guests
                                            </li>
                                            <li className="list-inline-item">
                                                <img src="/assets/media/svg/calSel.svg" /> Organised By John doe
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-2 text-right align-self-center">
                                        <button type="button" className="btn btn-SeaGreen btn-sm">Join Now</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-right mt-3 opacity color-white watermark">
                    <h4>Under Construction</h4>
                </div>
            </div>
        </section>

    </>
    );
}


export default happyHours;