import * as T from '../actions/Types';

const initialState = {
    loading: false,
    errors: null,
    notification:{}
}
export default function (state = initialState, action: any) {
    switch (action.type) {
        case T.SET_ERRORS:
            return {
                ...state,
                loading: false,
                errors: {message: action.payload}
            };
        case T.CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null
            };
        case T.LOADING_UI:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}