import React, { useEffect, useRef } from 'react';
import {historyStore} from '../store/history';
import { GlobalState, globalStore} from '../store/global_store';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';
export type IRootProvider = {
  globalState: GlobalState
//  whiteboardState: WhiteboardState
  historyState: any
}
export interface IObserver<T> {
  subscribe: (setState: (state: T) => void) => void
  unsubscribe: () => void
  defaultState: T
}

// export const useWhiteboardState = () => {
//   return useStore().whiteboardState;
// }

export function useObserver<T>(store: IObserver<T>) {
  const [state, setState] = React.useState<T>(store.defaultState);
  React.useEffect(() => {
    store.subscribe((state: any) => {
      setState(state);
    });
    return () => {
      store.unsubscribe();
    }
  }, []);

  return state;
}

export const RootContext = React.createContext({} as IRootProvider);

export const useStore = () => {
  const context = React.useContext(RootContext)
  if (context === undefined) {
    throw new Error('useStore must be used within a RootProvider');
  }
  return context;
}

export const useGlobalState = () => {
  return useStore().globalState;
}

export const RootProvider: React.FC<any> = ({children}) => {
  const {user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer}));
  const globalState = useObserver<GlobalState>(globalStore);
  //const whiteboardState = useObserver<WhiteboardState>(whiteboard);
  const historyState = useObserver<any>(historyStore);
  const history = useNavigate();
  const ref = useRef<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if(!ref.current) {          
          ref.current = true;
          //const userId = localStorage.getItem('EmpGuid') || '';
          //chatClient.init(userId);

          // chatClient.on('ConnectionStateChanged', ({ newState, reason }: { newState: string, reason: string }) => {
          //   if (reason === 'LOGIN_FAILURE') {
          //     globalStore.showToast({
          //       type: 'rtmClient',
          //       message: t('toast.login_failure'),
          //     });       
          //     return;
          //   }
          //   if (reason === 'REMOTE_LOGIN' || newState === 'ABORTED') {
          //     globalStore.showToast({
          //       type: 'rtmClient',
          //       message: t('toast.kick'),
          //     });
          //     return;
          //   }
          // });
      }
      historyStore.setHistory(history)
      return () => {
        ref.current = true;
        // chatClient.off("MessageFromPeer", ()=>{});
        // chatClient.off("ChannelMessage", ()=>{});
      }
  }, []);

  const value = {
    globalState,
    historyState,
   // whiteboardState
  }

  const location = useLocation();
    // const getUserDisplayName = (frmUsrId: any) => {
    //   console.log(" cons 2", user.contacts);
    //   if (!user.contacts)
    //       return "Chat";
    //       let cusr =[];
    //       if(Array.isArray(user.contacts))
    //       cusr = user.contacts.filter((usr: any) => usr.EmpGuid === frmUsrId);
    //       else if(Array.isArray(user.contacts['result']))
    //       cusr = (user.contacts['result'] as any[]).filter((usr: any) => usr.EmpGuid === frmUsrId);
             
    //   return cusr.length > 0 ? cusr[0].FirstName : 'Chat';
    // }

  // useEffect(() => {
  //   if (!chatClient._logined) return;
  //   const userId = localStorage.getItem('EmpGuid') || '';
  //   chatClient.on("MessageFromPeer", (...args: any) => {
  //     const [msg, peerId, dt] = args;
  //       console.log('msg>', msg, Date.now());
  //     if(msg.messageType === 'TEXT' && msg.text.indexOf('__CALL__') == -1 ){
  //         let msgJson: any = {};
  //         if(msg.text.indexOf('UUID')>-1){
  //             msgJson = JSON.parse(msg.text);                            
  //         }else{
  //             msgJson.text = msg.text;
  //             msgJson.UUID = msg.UUID;
  //         }
  //         const dupCheck = (user.messages[peerId]||[]).filter((m: any)=>m.UUID === msgJson.UUID);
  //         if(dupCheck.length > 0) {
  //           return ;
  //         }
  //         dispatch(GetUnseenUserMessagesCount(userId));
  //          dispatch(StoreMsg({...msg, TimeStamp:dt.serverReceivedTs, SenderGuid:peerId, UUID: msgJson.UUID}, peerId));
  //         if(globalState.windowFocus){ 
  //           globalStore.showToast({
  //             type: 'Message',
  //             message: getUserDisplayName(peerId) +":"+ (msgJson.text.length>20?msgJson.text.substring(0,20)+'...': msgJson.text),
  //             title: 'Message',
  //             link:'/produce'
  //           });
  //         }else {
  //             globalStore.deskTopNotice({ icon:'',
  //               message: (msgJson.text.length>20?msgJson.text.substring(0,20)+'...': msgJson.text),
  //               title: getUserDisplayName(peerId) +":",
  //               link:'/produce'
  //             });
  //         }
  //     }else if(msg.messageType === 'TEXT' && msg.text.indexOf('__CALL__') > -1 ){
  //       const callObj = JSON.parse(msg.text);
  //       if(callObj.t === '__CALL__'){
  //         globalStore.showWebNotice({
  //           reason: 'Incoming Group Call',
  //           message: `${callObj.f} Inviting you to join in ${callObj.rn}`,
  //           options:{ link: callObj.l, ok: ()=>{ history.push(callObj.l);}, 
  //             cancel: ()=>{ history.push('/pod')}
  //           }
  //         });
  //       }
  //     }        
  //   });
  //   chatClient.on("ChannelMessage", (...argss: any) => { 
  //     const note_channel = localStorage.getItem('OrgGuid') || '';   
  //     const [msg, memberId, messagePros] = argss[0].args; 
  //     if(note_channel == argss[0].channelName){
  //       const msObj = JSON.parse(msg.text);
  //       if(msObj.hasOwnProperty('t') && msObj.t == 'typing'){
  //           //typing notification, no action required here
  //           console.log('typing in channel', note_channel);
  //       }
  //     }else if(msg.messageType === 'TEXT' && msg.text.indexOf('__CALL__') == -1 ){
  //         if(msg.text.length>0 &&  msg.text.indexOf('__CALL__|') === -1){
  //           let msgJson: any = {};
  //           console.log('recieved group message', msg);
  //           if(msg.text.indexOf('UUID')>-1){
  //               msgJson = JSON.parse(msg.text);                            
  //           }else{
  //               msgJson.text = msg.text;
  //               msgJson.UUID = msg.UUID;
  //           }
  //           const dupCheck = (user.messages[argss[0].channelName]||[]).filter((m: any)=>m.UUID === msgJson.UUID);
  //           if(dupCheck.length > 0) {
  //             return ;
  //           }
	//         	dispatch(GetUnseenGroupMessagesCount(userId));
  //           dispatch(StoreMsg({...msg, TimeStamp:messagePros.serverReceivedTs, SenderGuid:memberId, UUID: msgJson.UUID}, argss[0].channelName));
  //           if(globalState.windowFocus){ 
  //             globalStore.showToast({
  //               type: 'Message',
  //               message: (msgJson.text.length>20?msgJson.text.substring(0,20)+'...': msgJson.text),
  //               title: 'Group Message',
  //               link: '/produce'
  //             });
  //           }else { 
  //               globalStore.deskTopNotice({ icon:'',
  //               message: (msgJson.text.length>20?msgJson.text.substring(0,20)+'...': msgJson.text),
  //               title: getUserDisplayName(memberId)+' Message',
  //               link: '/produce'
  //             }); }            
  //           }
  //       }else if(msg.messageType === 'TEXT' && msg.text.indexOf('__CALL__') >-1 ){
  //         const callObj = JSON.parse(msg.text);
  //         if(callObj.t === '__CALL__'){
  //           globalStore.showWebNotice({
  //             reason: 'Incoming Group Call',
  //             message: `${callObj.f} Inviting you to join in ${callObj.rn}`,
  //             options:{ link: callObj.l, 
  //               ok: ()=>{ history.push(callObj.l);}, 
  //               cancel: ()=>{ history.push('/pod')}
  //             }
  //           });
  //         }
  //       }
  //   });
  //   return () => {
  //     chatClient.off("MessageFromPeer", ()=>{});
  //     chatClient.off("ChannelMessage", ()=>{});
  //   }
  // }, [chatClient._logined]);

  useEffect(() => {
    if (location.pathname === '/') {
      return;
    }
     //@ts-ignore
     window.whiteboard = whiteboardState;
    //@ts-ignore
    window.globalState = globalState;
  }, [value, location]);
  return (
    <RootContext.Provider value={value}>
      {children}
    </RootContext.Provider>
  )
}