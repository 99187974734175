import React, {useEffect} from 'react'
//import { chatClient } from "../services/chat-api";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';
//import { GetContacts } from '../services/UserActions';
import { updateRtmStatus } from '../services/ContactsService';

interface HeaderProps {
    title: string;
    textLight?: boolean | false;
}

export default function WindowTitle(props: HeaderProps) {
    
    return (<></>)
}
