import React from 'react';
import { Route, PathRouteProps, RouteProps } from 'react-router-dom';
//redux stuff
import { connect } from 'react-redux'

interface MyRouteProps {
  component: React.ComponentType
  path?: string
  isAuthenticated:boolean;
}

 const GuestRoute: React.FC<MyRouteProps> = ({ component: RouteComponent }) => {
    return <RouteComponent />
}

const mapStateToProps = (state: any) => ({
   isAuthenticated: state.AuthReducer.isAuthenticated
});

export default connect(mapStateToProps)(GuestRoute);
