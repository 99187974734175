import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
function handleHttpError(error: any): string {
  const genericErrorMessage = 'Service is available. Try again?';

  if (error.response && error.response.data) {
    return error.response.data.errorMessage || genericErrorMessage;
  }

  return genericErrorMessage;
}

function makeHttpRequest(apiCall: Function) {
  return new Promise(async (resolve, reject) => {
    try {
      const data: AxiosResponse = await apiCall();
     // console.log(data.data);
      resolve(data.data);
    } catch (e:any) {
      reject(handleHttpError(e));
    }
  });
}
export function getRequest(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest(() => axios.get(path, options));
}

export function postRequest(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest(() => axios.post(path, options));
}

export function postRequest2(path: string, data?: any,  options?: AxiosRequestConfig) {
  return makeHttpRequest(() => axios.post(path, data, options));
}

export function putRequest(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest(() => axios.put(path, options));
}

export function deleteRequest(path: string, options?: AxiosRequestConfig) {
  return makeHttpRequest(() => axios.delete(path, options));
}
