import AuthReducer from './AuthReducer';
import uiReducer from './uiReducer';
import MsReducer from './MsReducer';
import EmployeeReducer from './EmployeeReducer';
import MediaReducer from './MediaReducer';
import {combineReducers} from 'redux';

const AllReducers = combineReducers(
    {AuthReducer, uiReducer, MsReducer, EmployeeReducer, MediaReducer});

export default AllReducers;