import React from 'react'
import { Route, RouteProps,PathRouteProps,Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

interface MyRouteProps {
    component: React.ComponentType
    path?: string
    isAuthenticated:boolean;
  }
  
const PrivateRoute: React.FC<MyRouteProps> = ({ isAuthenticated,component: RouteComponent }) => {
    
    if (isAuthenticated) {
        return <RouteComponent />
      }
      return <Navigate to="/login" />
    }
    
const mapStateToProps = (state:any) => ({
    isAuthenticated: state.AuthReducer.isAuthenticated
});
export default connect(mapStateToProps)(PrivateRoute)