import React from "react";
import { Link } from "react-router-dom";

function ChallengesMenu() {
  return (
    <>
      <section className="myProfilePage position-relative d-flex h-100">
        <div className="container align-self-center">
          <div className="row">
            <div className="col-sm-12">
              <div className="row mobile-mr" style={{ marginTop: "-140px" }}>
                <div className="col-sm-10 pb-5">
                  <div className="cRoomHeader pt-2 mb-0">
                    <div className="cRsuteLogo align-self-center">
                      <Link to="/admin">
                        <img src="/assets/media/images/logo.png" />
                      </Link>
                    </div>
                    <div className="cRTitle p-3">
                      <h5>Wellness Initiatives</h5>
                    </div>
                  </div>
                </div>
              </div>
              <ul id="suteSelMenu" className="HangOutMenu">
                <li className="OBhex">
                  <Link
                    className="OBhexIn active"
                    to="/Challenge"
                    style={{
                      backgroundImage:
                        'url("https://img.freepik.com/free-photo/man-jump-through-gaps-hills_1150-19693.jpg?t=st=1714981218~exp=1714984818~hmac=e89b677dfe991552d67aac5a94adcb829f50a57f3ac38c6f6413f655cfd0b27b&w=740")',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <p>
                      {" "}
                      <span className="align-self-center">
                        <u
                          className="OBhexTitle"
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            display: "block",
                          }}
                        >
                          New 
                        </u>{" "}
                      </span>{" "}
                    </p>
                  </Link>
                </li>
                <li className="OBhex">
                  <Link
                    className="OBhexIn active"
                    to="/OngoingChallenges"
                    style={{
                      backgroundImage:
                        'url("https://img.freepik.com/free-photo/businessman-playing-jenga_23-2148148352.jpg?t=st=1714981596~exp=1714985196~hmac=5219beec08146eb4c2dc87a7918e800b8f5bc048d3c5bd0a04c58316d8de5c41&w=740")',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <p>
                      {" "}
                      <span className="align-self-center">
                        <u
                          className="OBhexTitle"
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            display: "block",
                          }}
                        >
                          Ongoing 
                        </u>{" "}
                      </span>{" "}
                    </p>
                  </Link>
                </li>

                <li className="OBhex">
                  <Link
                    className="OBhexIn"
                    to="/CompletedChallenges"
                    style={{
                      backgroundImage:
                        'url("https://img.freepik.com/free-photo/accounting-hands-businessman-statistics-discussing-business_1418-42.jpg?t=st=1714981669~exp=1714985269~hmac=55bea8bfb71f099cf589fb865a481f5c97564c734c4cfa0c183b8203c1cf622f&w=740")',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <p>
                      {" "}
                      <span className="align-self-center">
                        <u
                          className="OBhexTitle"
                          style={{
                            fontSize: "16px",
                            marginTop: "10px",
                            display: "block",
                          }}
                        >
                          Completed 
                        </u>{" "}
                      </span>{" "}
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChallengesMenu;
