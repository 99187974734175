import { GetAnxietySlayerTracksList, GetDiversityTracksList, GetGuidedMeditationTracksList, GetLifeBalanceTracksList, GetPersonalGrowthTracksList, GetPilanthropyTracksList, GetSoundBathTracksList } from "../../services/MediaService";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from '../../i18n';
import { Modal } from "react-bootstrap";

function MediaSoundTracks(props: any) {
    const modelRef = useRef<HTMLDivElement>();
    const media = useSelector((store: any) => store.MediaReducer);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(props.show || false)
    const [addContent, setAddContent] = useState<any[]>([]);
    useEffect(() => {
        if (media.soundTrackList.length == 0) {
            dispatch(GetSoundBathTracksList(1));
        }
        if (media.anxietySlayerTrackList.length == 0) {
            dispatch(GetAnxietySlayerTracksList(3));
        }
        if (media.diversityTrackList.length == 0) {
            dispatch(GetDiversityTracksList(4));
        }
        if (media.lifeBalanceTrackList.length == 0) {
            dispatch(GetLifeBalanceTracksList(5));
        }
        if (media.guidedMeditationTrackList.length == 0) {
            dispatch(GetGuidedMeditationTracksList(6));
        }
        if (media.personalGrowthTrackList.length == 0) {
            dispatch(GetPersonalGrowthTracksList(7));
        }
        if (media.pilanthropyTrackList.length == 0) {
            dispatch(GetPilanthropyTracksList(8));
        }
    }, [])

    useEffect(() => {
        setShowDialog(props.show || false)
    }, [props.show])

    useEffect(() => {
        setShowDialog(props.show || false)
        setAddContent([...props.tracks]);
        (media.soundTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.anxietySlayerTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.diversityTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.lifeBalanceTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.guidedMeditationTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.personalGrowthTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (media.pilanthropyTrackList.result || []).map(
            (obj: any, objindex: number) => {
                obj.IsChecked = false;
            }
        );
        (props.tracks || []).map(
            (track: any, index: number) => {
                (media.soundTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                }); 

                (media.anxietySlayerTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                });

                (media.diversityTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                });

                (media.lifeBalanceTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                }); 

                (media.guidedMeditationTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                }); 

                (media.personalGrowthTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                }); 

                (media.pilanthropyTrackList.result || []).map(
                    (obj: any, objindex: number) => {
                    if (track.TrackId === obj.TrackId)
                    {
                        obj.IsChecked = true;
                    }
                }); 
        }) 
    }, [props])

    useEffect(() => {


    }, [media.soundTrackList])


    function addContentClick(e: any, obj: any, medialist: any[]) {
        // 
        var isExistInDirecory: boolean = false;
        isExistInDirecory = addContent.includes(obj);
        if (!isExistInDirecory) {
            setAddContent([...addContent, obj]);
        }
        else
        {
            setAddContent((rows) =>
                rows.filter((row) => row.TrackId !== obj.TrackId)
            ); 
        }

        //find the track list index
        const Index = medialist.findIndex((r) => r.TrackId === obj.TrackId);
        //get the track list item
        let item = medialist[Index];
        //update IsChecked status
        item.IsChecked = !isExistInDirecory;
    }

    function handleAddContent(e: any) {
        e.preventDefault();
        props.AddMediaContent(addContent);
    }

    return (<>
        <Modal ref={modelRef} show={props.show} onHide={props.close} className="addTeamMemberBox">
            <Modal.Body>
                <div className="row">
                    <div className="col-sm-12">
                        <h5> <span className="eventMTitle text-light">Attach Content</span></h5>
                    </div>
                    <div className="col-12 overflow-auto" style={{ height: 'calc(100vh - 200px)' }}>
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header bgCadet" id="headingOne">
                                    <button className="btn p-0 text-light" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Soundbath Meditations
                                                    </button>
                                </div>
                                <div id="collapseOne" className="collapse bgCadet show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        { (media.soundTrackList.result || []).length > 0 ? (media.soundTrackList.result || []).map(
                                            (obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.soundTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingTwo">
                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Anxiety Slayer
                                                 </button>
                                </div>
                                <div id="collapseTwo" className="collapse bgCadet" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {(media.anxietySlayerTrackList.result || []).length > 0 ? (media.anxietySlayerTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.anxietySlayerTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined}</div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingThree">

                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Life Balance
                                                    </button>
                                </div>
                                <div id="collapseThree" className="collapse bgCadet" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {(media.lifeBalanceTrackList.result || []).length > 0 ? (media.lifeBalanceTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.lifeBalanceTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined} </div>

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingFour">
                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Diversity
                                                    </button>
                                </div>
                                <div id="collapseFour" className="collapse bgCadet" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {(media.diversityTrackList.result || []).length > 0 ? (media.diversityTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.diversityTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined} </div>

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingFive">
                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                        Guided Meditations
                                                    </button>
                                </div>
                                <div id="collapseFive" className="collapse bgCadet" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div className="card-body">
                                        { (media.guidedMeditationTrackList.result || []).length > 0 ? (media.guidedMeditationTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.guidedMeditationTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined} </div>

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingSix">
                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                        Personal Growth
                                                    </button>
                                </div>
                                <div id="collapseSix" className="collapse bgCadet" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div className="card-body">
                                        { (media.personalGrowthTrackList.result || []).length > 0 ? (media.personalGrowthTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.personalGrowthTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined} </div>

                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header bgCadet" id="headingSeven">
                                    <button className="btn p-0 text-light collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                        Pilanthropy
                                                    </button>
                                </div>
                                <div id="collapseSeven" className="collapse bgCadet" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                    <div className="card-body">
                                        { (media.pilanthropyTrackList.result || []).length > 0 ? (media.pilanthropyTrackList.result || []).map((obj: any, index: number) => {
                                            return (
                                                <div key={index} className="custom-control custom-checkbox small">
                                                    <input type="checkbox" checked={obj.IsChecked} value={obj.IsChecked} onChange={(e: any) => addContentClick(e, obj, media.pilanthropyTrackList.result)} className="custom-control-input" />
                                                    <label className="custom-control-label text-light">{obj.TrackName}</label>
                                                </div>
                                            )
                                        }) : undefined} </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-SeaGreen" onClick={props.close}>{t('button.close')}</button>
                    <button type="button" className="btn btn-SeaGreen" onClick={handleAddContent}>Add</button>
                </div>
            </Modal.Body>
        </Modal>
    </>
    );
}

export default MediaSoundTracks;
