import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import WellnessMenu from "./wellnessMenu";
import HangoutMenu from "../onboarding/HangoutMenu";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GetMediaTracksList } from "../../services/WellnessServices";
import * as T from "../../actions/Types";

function Information(props: any) {
  const arr = [
    { name: "Content", link: "/information" },
    { name: "Challenges", link: "/challenges" },
    { name: "Classes", link: "/classes" },
  ];
  const media = useSelector((store: any) => store.MediaReducer);
  const [informationSideNav, setInformationSideNav] = useState(true);
  const [mediaTypeList, setMediaTypeList] = useState([] as any)
  const location = useLocation();
  const loc = location.state;
  let orgGuid = localStorage.getItem('OrgGuid') || '';
  const dispatch = useDispatch();
  const mediaType = media.mediaTrackList[0]?.MediaGuid  
  
  

  useEffect(() => {
    if (mediaTypeList && (mediaTypeList.length === 0)) {
      dispatch({
        type: T.ADD_LOADING
      })
      GetMediaTracksList(orgGuid)
        .then((resp: any) => {
          setMediaTypeList([...resp.result])
          console.log("getting data", resp.result)

          if (resp.status === "success") {
            dispatch({
              type: T.REMOVE_LOADING
            })
          }
          else if (resp === "error") {
            dispatch({
              type: T.REMOVE_LOADING
            })
          }
        })
        .catch((e: any) => {
          dispatch({
            type: T.REMOVE_LOADING
          })

          return "error"
        })
    }

  }, [])



  return (
    <>
      <section className="myProfilePage position-relative d-flex">
        {/*<WellnessMenu />*/}
        {/* {(informationSideNav) ? <HangoutMenu arr={arr} type="Content" setInformationSideNav={setInformationSideNav}/> : <div className="open-sidemenu" onClick={() => { setInformationSideNav(true) }}>

          <span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
        </div>} */}
        <div className="SideNav text-left position-fixed d-none">
          <div className="sideNavHeader">
            <div className="CloseNav">
              {" "}
              <img
                src="assets/media/svg/x-close.svg"
                alt="Close Side Nav"
                title="Close"
              />{" "}
            </div>
          </div>
          <div className="sideNavBody">
            <div className="row mt-4 small">
              <div className="col-12 mb-2 text-light">
                {" "}
                <img
                  src="assets/media/svg/builiten-board/the-well.svg"
                  height="30"
                />{" "}
              </div>
            </div>
            <div className="row small mt-4">
              <div className="col-12 text-left text-light">
                <h6 className="m-0">
                  Build a stronger, healthier immune system
                </h6>
                <p className="text-muted mb-2">
                  Most of new line of immune suppliments five unique products -
                  each designed to support your unique health needs, lifestyle,
                  preferances and dietary restrictions.{" "}
                </p>
              </div>
            </div>
            <div className="row mt-2 small c-pointer">
              <div className="col-12 text-left c-SeaGreen">
                {" "}
                View more
                <svg
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                >
                  <path
                    d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                    stroke="currentColor"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-10 pb-2 mt-3">
                  <div className="cRoomHeader pt-2 mb-0">
                    <div className="cRsuteLogo align-self-center">
                      <Link to="/assessment" state={loc}>
                        <img src="/assets/media/images/logo.png" />
                      </Link>
                    </div>
                    <div className="cRTitle p-3">
                      <h5>Wellness Hub</h5>
                      {/* <p>All Partners</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 pb-2 mt-3" style={{ paddingLeft: '120px' }}>
                  <ul
                    id="suteSelMenu"
                    className="HangOutMenu mt-2 information-section-hex"
                  >
                    {mediaTypeList && mediaTypeList.length > 0 && (
                      mediaTypeList.map((item: any, index: number) => (
                        <> <li className={'OBhex OB' + (index + 1)} key={item.MediaId[0]}>
                          <Link
                            className="OBhexIn active"
                            to='/wellnessMedia'
                            style={{
                              backgroundImage: `url(${item.Thumbnail})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            state={{ typeGuid: item.MediaGuid }}
                          >
                            <p>
                              {" "}
                              <span className="align-self-center" key={item.MediaId[0]}>
                                <img src="" />
                                <u className="OBhexTitle">{item.MediaType}</u>{" "}
                              </span>{" "}
                            </p>
                          </Link>
                        </li>
                        {
                          Boolean((index + 1) == 3) ? <li className='OBhex fourth-child' > </li> : ''
                        }
                        {
                          Boolean((index + 1) == 5) ? <li className='OBhex seventh-child' > </li> : ''

                        }
                          </>
                      ))
                    )}
                  </ul></div></div>
            </div>
          </div>
          <div className="text-right mt-3 opacity color-white watermark">
            <h4>Under Construction</h4>
          </div>
        </div>
      </section>
    </>
  );
}

export default Information;
