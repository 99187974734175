
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
//import tawkTo from "tawkto-react";

function Chat(props: any) {
    const tawkToPropertyId = '6088fe5062662a09efc2d356'
    const tawkToKey = '1f4bi2rfh'

    //useEffect(() => {
        //tawkTo(tawkToPropertyId, tawkToKey)
        //return ()=>{
            //tawkTo("", "") ;
       // }
    //}, [])

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            <ul id="OBhexGrid" className="HangOutMenu">
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex" style={{ top: '94px' }}>
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    <Link className="OBhexIn" to="/resources">
                        <p> <span className="align-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M2 3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V3.993zM4 5v14h16V5H4zm6.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" fill="rgba(255,255,255,1)" /></svg> <br />
                            <u className="OBhexTitle">Resources</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    <Link className="OBhexIn active" to="/chat">
                        <p> <span className="align-self-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="36" height="36"><path fill="none" d="M0 0h24v24H0z" /><path d="M7.291 20.824L2 22l1.176-5.291A9.956 9.956 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.956 9.956 0 0 1-4.709-1.176zm.29-2.113l.653.35A7.955 7.955 0 0 0 12 20a8 8 0 1 0-8-8c0 1.334.325 2.618.94 3.766l.349.653-.655 2.947 2.947-.655z" fill="rgba(255,255,255,1)" /></svg> <br />
                            <u className="OBhexTitle">Chat</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex">
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex">
                    <Link className="OBhexIn" to="/feedback">
                        <p> <span className="align-self-center">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M30 3H6C5.60218 3 5.22064 3.15803 4.93934 3.43934C4.65804 3.72064 4.5 4.10218 4.5 4.5V31.5C4.5 31.8978 4.65804 32.2794 4.93934 32.5607C5.22064 32.842 5.60218 33 6 33H30C30.3978 33 30.7794 32.842 31.0607 32.5607C31.342 32.2794 31.5 31.8978 31.5 31.5V4.5C31.5 4.10218 31.342 3.72064 31.0607 3.43934C30.7794 3.15803 30.3978 3 30 3ZM28.5 6V30H7.5V6H28.5ZM12 25.5H24V22.5H12V25.5ZM12 19.5H24V16.5H12V19.5ZM12 13.5H19.5V10.5H12V13.5Z" fill="white"/></svg> <br />
                            <u className="OBhexTitle">Feedback</u> </span> </p>
                    </Link>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex opacity0">
                    <a className="OBhexIn"></a>
                </li>
                <li className="OBhex" style={{ top: '-94px', zIndex: -1 }}>
                    <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
                </li>
            </ul>

            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-9">
                        <div className="cRoomHeader pt-2"><div className="cRsuteLogo align-self-center"><Link to="/pod"><img src="/assets/media/images/logo.png" /></Link></div><div className="cRTitle p-3"><h5>Chat</h5></div></div>
                        <div className="row">
                            <div className="col-12">
                                <div className="chattingPanel" style={{ background: '#2e3151', padding: '15px', height: 'auto', borderRadius: '10px' }}>
                                    <h5 className="text-light">Team Sute</h5>
                                    <ul>
                                        <li className="FromSelf" style={{width: 'calc(100% - 350px)'}}>
                                            <div className="chattingUserName">
                                                <h4 className="float-left m-0">Team Sute</h4>
                                                <p className="float-right" title="-"></p>
                                            </div>
                                            <div className="chattingContent" style={{fontSize: '12px'}}>
                                                <p>We've changed the way you interact with the Sute team.</p>
                                                <p>Please use this chat to get a hold of us. 🙌</p>
                                                <p>Note - this is an automated message. Responses can take a few hours.</p>
                                            </div>
                                        </li>
                                        <li className="ml350" style={{width: 'calc(100% - 350px)'}}><div className="chattingUserName"><h4 className="float-left m-0">Praveen M</h4><p className="float-right" title="-"></p></div><div className="chattingContent" style={{fontSize: '12px'}}><p> Thank you</p></div></li>
                                        <li className="FromSelf" style={{width: 'calc(100% - 350px)'}}>
                                            <div className="chattingUserName">
                                                <h4 className="float-left m-0">Team Sute</h4>
                                                <p className="float-right" title="-"></p>
                                            </div>
                                            <div className="chattingContent" style={{fontSize: '12px'}}>
                                                <p>Hey Sute users. My name is Sean, and I am one of the new Founding Engineers hired at Sute recently. Im excited to be here and am looking for everybody's help!</p>
                                                <p>We would love to get your thoughts about Sute and hybrid work. If you have some time, would you mind filling out our survey? 🙏</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <input type="text" className="SuteInputPrimary fs10" placeholder="Enter Message" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}


export default Chat;