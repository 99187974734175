import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import HangoutMenu from "../onboarding/HangoutMenu";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useDispatch, useSelector } from "react-redux";
import { GetSoundBathTracksList } from "../../services/MediaService";

var playIcon: '<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.061 39.122C30.5881 39.122 39.122 30.5881 39.122 20.061C39.122 9.5339 30.5881 1 20.061 1C9.5339 1 1 9.5339 1 20.061C1 30.5881 9.5339 39.122 20.061 39.122Z" stroke="white" stroke-width="2"/><path d="M17 15V25.4L25.916 20.2L17 15Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>'

 export default function SoundBathMeditations(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const [tracklist, setTrackList] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState({index:-1, id: -1, url: '', name: '' });
    const playerRef = useRef();

    useEffect(() => {
        dispatch(GetSoundBathTracksList(1));
    }, [])

    useEffect(() => {
        if(Array.isArray(media.soundTrackList)){
        media.soundTrackList.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setTrackList(media.soundTrackList || []);
    }
    else  if(Array.isArray(media.soundTrackList.result)){
        media.soundTrackList.result.forEach((track: any) => {
            track.TrackName = track.TrackName.replace(/\&nbsp;/g, '')
        });
        setTrackList(media.soundTrackList.result || []);
    }
    }, [media.soundTrackList])

    const PlayTrack = (obj: any, index: number) => {
        if (index == -1)
            setSelectedTrack({index:selectedTrack.index, id: selectedTrack.index, url: selectedTrack.url, name: selectedTrack.name });
        else
        {
            setSelectedTrack({index: index, id: index, url: obj.TrackURL, name: obj.TrackName });
            playerRef &&
                playerRef.current &&
                playerRef.current.audio &&
                playerRef.current.audio.current.play();
        }
    }

    const PauseTrack = (obj: any, index: number) => {
        if (index == -1)
            setSelectedTrack({index:selectedTrack.index, id: -1, url: selectedTrack.url, name: selectedTrack.name });
        else
        {
            setSelectedTrack({index:selectedTrack.index, id: -1, url: obj.TrackURL, name: obj.TrackName });
        
            playerRef &&
                playerRef.current &&
                playerRef.current.audio &&
                playerRef.current.audio.current.pause();
        }
    }

    function onplayerNext(obj: any): void {
        var id = selectedTrack.id + 1;
        if (id < media.soundTrackList.result.length) {
            var nextTrack = media.soundTrackList.result[id];
            setSelectedTrack({index: id, id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    function onplayerPrevious(obj: any): void {
        var id = selectedTrack.id - 1;
        if (id >= 0) {
            var nextTrack = media.soundTrackList.result[id];
            setSelectedTrack({index: id, id: id, url: nextTrack.TrackURL, name: nextTrack.TrackName })
        }
    }

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            <HangoutMenu />
            <div className="SideNav text-left position-fixed d-none">
                <div className="sideNavHeader">
                    <div className="CloseNav"> <img src="assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row mt-4 small">
                        <div className="col-12 mb-2 text-light"> <img src="assets/media/svg/builiten-board/the-well.svg" height="30" /> </div>
                    </div>
                    <div className="row small mt-4">
                        <div className="col-12 text-left text-light">
                            <h6 className="m-0">Build a stronger, healthier immune system</h6>
                            <p className="text-muted mb-2">Most of new line of immune suppliments five unique products - each designed to support your unique health needs, lifestyle, preferances and dietary restrictions. </p>
                        </div>
                    </div>
                    <div className="row mt-2 small c-pointer">
                        <div className="col-12 text-left c-SeaGreen"> View more
                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1" stroke="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-9">
                        <div className="cRoomHeader pt-2"><div className="cRsuteLogo align-self-center"><Link to="/sute-selections"><img src="/assets/media/images/logo.png" /></Link></div><div className="cRTitle p-3"><h5>Soundbath Meditations</h5></div></div>
                        <div className="mediaGrid">
                            {(tracklist || []).map((obj: any, index: number) => {
                                return (
                                    <div className="mediaItem">
                                        <div className="mediaImg">
                                            <img src={obj.Thumbnail} />
                                        </div>
                                        <div className="mediaAction">
                                            {index != selectedTrack.id ? <img onClick={(e: any) => PlayTrack(obj, index)} src="/assets/media/images/hangouts/media/play.svg" />
                                                : <img onClick={(e: any) => PauseTrack(obj, index)} src="/assets/media/images/hangouts/media/pause.svg" />}
                                        </div>
                                        <div className="mediaDetails">
                                            <h4 className="mt-3 text-white h6">{obj.TrackName}</h4>
                                            <p className="text-muted fs12">{obj.Description}</p>
                                            <audio controls className="d-none">
                                                <source src={obj.TrackURL} />
                                            </audio>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <AudioPlayer 
                            ref={playerRef} 
                            onPause={() => PauseTrack({TrackURL:'', TrackName:''},-1)} 
                            onPlay={e => PlayTrack({TrackURL:'', TrackName:''},-1)}
                            className="SuteMediaPlayer HangoutMediaPlayer small" layout="stacked-reverse" customVolumeControls={[]} customAdditionalControls={[]} header={selectedTrack.name} src={selectedTrack.url} onClickPrevious={e => onplayerPrevious(e)} onClickNext={e => onplayerNext(e)} showSkipControls={true} customIcons={{ play: playIcon }} />
                    </div>
                </div>
            </div>
        </section>
    </>
    );
}

//export default soundBathMeditations;
