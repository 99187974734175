import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { GetAllEmployeesByOrganisation, GetBatchGroups, SetFavouritesGroups } from "../../services/CollaborationHubService";
import * as T from "../../actions/Types";
import { BatchGroups, BatchGroupsEmployees } from "../../utils/types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { StoreClass } from "../../store/Store";
import { GetContacts } from "../../services/UserActions";
import UserChatComponent from "../../components/UserChatComponent";
import { flushSync } from "react-dom";
import PreCallPopup from "../myoffice/PreCallPopup";

function CollaborationHub() {
  const dispatch = useDispatch();
  const [groupsList, setGroupsList] = useState([] as BatchGroups[]);
  const [groupsListAllEmployees, setGroupsListAllEmployees] = useState({} as BatchGroups);
  const [modalEmployeeList, setModalEmployeeList] = useState<BatchGroups>({} as BatchGroups);
  const [originalModalEmployeeList, setOriginalModalEmployeeList] = useState<BatchGroups>({} as BatchGroups);
  const [isFocused, setIsFocused] = useState(false);
  const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
  const [selectUser, setSelectedUser] = useState({ EmployeeId: 0, FirstName: '', LastName: '', ProfileImageURL: '', EmpGuid: '', OfficeEmail: '', Mobile: '', status: '' });
  const [openChat, setOpenChat] = useState(false);
  const orgGuid = localStorage.getItem("OrgGuid") || "";
  const empId = localStorage.getItem('EmpGuid') || '';
  const maxEmployeeToDisplay = 5;
  const employeeListContainer = useRef<any>(null);
  const location = useLocation();
  const [openCall, setOpenCall] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    if (user.contacts) {
      console.warn("user contancts >>>", user.contacts)
      LoadData();
    }
  }, [user.contacts]);

  useEffect(() => {
    if (!user.contacts)
      dispatch(GetContacts(orgGuid, empId) as any)

  }, []);

  const LoadData = () => {

    if (groupsList.length < 0 || []) {
      //   dispatch({
      //     type: T.ADD_LOADING,
      //   });
      GetBatchGroups(orgGuid)
        .then((resp: any) => {
          if (resp.status === "success") {
            const sortedGroups = sortDataByFavourite(resp.result);
            // mergeAllEmployeesInSingle(resp.result);
            setGroupsList(sortedGroups);
            setHeightOnEmployeeListContainer();
            dispatch({
              type: T.REMOVE_LOADING,
            });
          } else if (resp === "error") {
            dispatch({
              type: T.REMOVE_LOADING,
            });
          }
        })
        .catch((e: any) => {
          dispatch({
            type: T.REMOVE_LOADING,
          });

          return "error";
        });
    }

    // dispatch({
    //   type: T.ADD_LOADING,
    // });
    GetAllEmployeesByOrganisation(orgGuid)
      .then((resp: any) => {
        if (resp.status === "success") {
          const employessObj = {
            batchGroup_Id: 4588,
            batch_name: "All",
            is_favourite: false,
            EmployeeDetails: resp.result,
          };
          setGroupsListAllEmployees(employessObj);
          dispatch({
            type: T.REMOVE_LOADING,
          });
        } else if (resp === "error") {
          dispatch({
            type: T.REMOVE_LOADING,
          });
        }
      })
      .catch((e: any) => {
        dispatch({
          type: T.REMOVE_LOADING,
        });

        return "error";
      });
  }

  const sortDataByFavourite = (group: BatchGroups[]) => {
    const sorted = [...group].sort((a: any, b: any) => b.is_favourite - a.is_favourite);
    return sorted;
  };

  const handleFavourite = (group: BatchGroups) => {
    SetFavouritesGroups(group.batchGroup_Id, !group.is_favourite)
      .then((resp: any) => {
        if (resp.status === "success") {
          const updatedGroupsList = groupsList.map((g) => {
            if (g.batchGroup_Id === group.batchGroup_Id) {
              return { ...g, is_favourite: !group.is_favourite };
            }
            return g;
          });
          const sortedGroupsList = sortDataByFavourite(updatedGroupsList);
          setGroupsList(sortedGroupsList);
        } else if (resp === "error") {
        }
      })
      .catch((e: any) => {
        return "error";
      });
  };

  const handleExpandClick = (group: BatchGroups) => {
    // setModalEmployeeList({ ...group, EmployeeDetails: group.EmployeeDetails.slice(maxEmployeeToDisplay) });
    // setOriginalModalEmployeeList({ ...group, EmployeeDetails: group.EmployeeDetails.slice(maxEmployeeToDisplay) });
    setModalEmployeeList({ ...group, EmployeeDetails: group.EmployeeDetails });
    setOriginalModalEmployeeList({ ...group, EmployeeDetails: group.EmployeeDetails });
  };

  const handleHideEmployeeListModal = () => {
    setModalEmployeeList({} as BatchGroups);
    setOriginalModalEmployeeList({} as BatchGroups);
  };

  const handleSearchOnEmployee = (e: React.ChangeEvent<HTMLInputElement>, group: BatchGroups) => {
    const value = e.target.value;
    if (value) {
      const filteredData = originalModalEmployeeList.EmployeeDetails.filter(
        (item) => item.first_name.toLowerCase().includes(value.toLowerCase()) || item.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setModalEmployeeList({ ...group, EmployeeDetails: filteredData });
    } else setModalEmployeeList({ ...modalEmployeeList, EmployeeDetails: originalModalEmployeeList.EmployeeDetails });
  };

  function setHeightOnEmployeeListContainer() {
    setTimeout(() => {
      if (employeeListContainer.current) {
        const wrapper = document.getElementById("employee-list-container") as HTMLDivElement;
        const wrapperHeight = employeeListContainer.current.getBoundingClientRect().height - 50;
        const viewportHeight = window.innerHeight - 40;
        if (wrapper && wrapperHeight > 0 && wrapperHeight > viewportHeight) {
          wrapper.style.height = `100vh`;
          wrapper.style.overflowY = "auto";
        }
      }
    }, 500);
  }

  const handleScrollUp = () => {
    if (employeeListContainer.current) {
      employeeListContainer.current.scrollBy({
        top: -100,
        behavior: "smooth",
      });
    }
  };

  const handleScrollDown = () => {
    if (employeeListContainer.current) {
      employeeListContainer.current.scrollBy({
        top: 100,
        behavior: "smooth",
      });
    }
  };

  function truncateText(str: string, n: number) {
    return str.length > n ? str.substring(0, n - 1) + "..." : str;
  }

  const getEmployeeStats = (employee: any): string => {

    let status = 'offline';
    if (!employee) return status;
    if (!employee.isonline)
      return status;

    if (employee.isonline) {
      employee.status = employee.status.length > 0 ? employee.status : 'Available';
      if (employee.status === "Available") return 'inoffice';
      else if (employee.status === "Away") return 'away';
      else if (employee.status === "Busy") return 'busy';

    }
    return status;
  }

  const closeChat = () => {
    setOpenChat(false);
  }
  const startChat = (sltEmp: any) => {
    
    if (!sltEmp.isonline)
      return;
    console.log(" EMP ", sltEmp);
    if (empId == sltEmp.employee_guid)
      return false;

   // if (sltEmp.employee_guid === selectUser.EmpGuid) return;

    flushSync(() => {
      setSelectedUser({ ...selectUser, EmployeeId: sltEmp.EmployeeId, EmpGuid: sltEmp.employee_guid, FirstName: sltEmp.first_name, LastName: sltEmp.last_name })
    })
    console.log(" EMP 2", selectUser);

    setOpenChat(true);

  }

  const sendCallReq = (sltEmp: any) => {
    if (!sltEmp.isonline)
      return;
    // flushSync(() => {
    //   setOpenCall(false);
    // })
    // if (openCall) {
    //   alert("Are you want to end the call?");
    //   return false;
    // }
    if (empId == sltEmp.employee_guid)
      return false;

   // if (sltEmp.employee_guid === selectUser.EmpGuid) return;
    if (!user.contacts) return;
    console.log('#OPENCALL sendCallReq-hub',user.openCall,user.selectedUser);
    // flushSync(() => {
    //   setSelectedUser({ ...selectUser, EmployeeId: sltEmp.EmployeeId, EmpGuid: sltEmp.employee_guid, FirstName: sltEmp.first_name, LastName: sltEmp.last_name })
    // })
    dispatch({
      type: T.SELECTED_USER,
      payload: sltEmp.employee_guid
  })

  dispatch({
      type: T.OPEN_CALL,
      payload: true
  })
  //  setOpenCall(true);
    setOpenChat(false);
  }

  const cancelCallLocal = () => {
    setOpenCall(false);
    console.log('#PRE cancelCallLocal ', openCall);
    if (user && user.Socket && user.Socket.connected) {
      user.Socket.removeListener("userfromCallStatus");
    }
  }

  const getUserDetails = (eid: any) => {

  }

  const popoverHoverFocus = (employee: BatchGroupsEmployees, batchName: string) => (
    <Popover id="popover-contained">
      <Popover.Body>
        <div className="details">
          <p>
            <span className="font-bold">Name: </span>
            <span>{employee?.first_name}</span>
            <span> {employee?.last_name}</span>
          </p>
          {/* <p>
            <span className="font-bold">Last Name: </span>
            <span> {employee?.last_name}</span>
          </p> */}
          <p>
            {employee.designation ? (
              <>
                <span className="font-bold">Designation: </span>
                <span>{employee.designation}</span>
              </>
            ) : null}
          </p>
          <p>
            {employee.employee_groups ? (
              <>
                <span className="font-bold">Member Of: </span>
                {employee.employee_groups.split(",").map((ele, index) => (
                  <span key={index}>
                    {ele}
                    {employee.employee_groups.split(",").length - 1 !== index ? "," : ""}{" "}
                  </span>
                ))}
              </>
            ) : null}
          </p>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <section
      className="overflow-hidden position-relative h-100 Produce-PODBG"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "url(/assets/media/images/officeBg.jpg)",
      }}
    >
      <div className="col-12 mt-2 mb-block d-none">
        <Link to="/pod">
          <svg enable-background="new 0 0 64 64" height="40" viewBox="0 0 64 64" width="40" xmlns="http://www.w3.org/2000/svg" fill="#ffffff">
            <path d="m54 30h-39.899l15.278-14.552c.8-.762.831-2.028.069-2.828-.761-.799-2.027-.831-2.828-.069l-17.448 16.62c-.755.756-1.172 1.76-1.172 2.829 0 1.068.417 2.073 1.207 2.862l17.414 16.586c.387.369.883.552 1.379.552.528 0 1.056-.208 1.449-.621.762-.8.731-2.065-.069-2.827l-15.342-14.552h39.962c1.104 0 2-.896 2-2s-.896-2-2-2z"></path>
          </svg>
        </Link>
      </div>
      <div className="row col-12 m-0 p-0">
        <div className="col-8 row m-0 p-0">
          <div className="col-2 mt-2 text-center mb-none">
            <Link to="/pod">
              <img className="w-50" src="/assets/media/images/sute-blue-logo.png" alt="logo" />
            </Link>
          </div>
          <div className="col-10" ref={employeeListContainer} id="employee-list-container">
            <>
              {groupsListAllEmployees ? (
                <div className="team-box" key={groupsListAllEmployees.batchGroup_Id}>
                  <div className="team-sub">
                    <h5>
                      {groupsListAllEmployees.batch_name ?? "-"}
                    </h5>

                    <div className="team-imgs">
                      {groupsListAllEmployees?.EmployeeDetails?.slice(0, maxEmployeeToDisplay).map(
                        (employee: BatchGroupsEmployees, index: number) => (
                          <div className="team-imgs-sub" key={"emp-list" + index}>
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              placement="auto"
                              overlay={popoverHoverFocus(employee, groupsListAllEmployees.batch_name)}
                            >
                              <div>
                                <svg
                                  className={`ParticipantImage c-pointer ${getEmployeeStats(employee)}`}
                                  viewBox="0 0 100 100"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <defs>
                                    <pattern id="aa25fdac-619a-4e6e-abdf-553668fc5534" patternUnits="userSpaceOnUse" width="100" height="100">
                                      <image href="/assets/media/images/userProfileImg.png" x="-25" width="150" height="100"></image>
                                    </pattern>
                                  </defs>
                                  <polygon
                                    id="hex"
                                    points="50 1 95 25 95 75 50 99 5 75 5 25"
                                    fill="url(#aa25fdac-619a-4e6e-abdf-553668fc5534)"
                                  ></polygon>
                                </svg>
                              </div>
                            </OverlayTrigger>
                            {
                              empId == employee.employee_guid ? '' :

                                <div className="social">
                                  <button type="button" className="mr-1 status-btn" onClick={() => sendCallReq(employee)}>
                                    <svg
                                      style={{ color: "green" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                                    </svg>
                                  </button>
                                  <button type="button" className="status-btn" onClick={() => startChat(employee)}>
                                    <svg
                                      style={{ color: "crimson" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                                      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                                    </svg>
                                  </button>
                                </div>
                            }
                            <h6>{truncateText(employee.first_name ?? "-", 15)}</h6>
                          </div>
                        )
                      )}
                      {groupsListAllEmployees?.EmployeeDetails?.length - maxEmployeeToDisplay > 0 && (
                        <span className="expand customcursor" onClick={() => handleExpandClick(groupsListAllEmployees)}>
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"></path>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  {modalEmployeeList && modalEmployeeList.batchGroup_Id === groupsListAllEmployees.batchGroup_Id ? (
                    <div className="expand-div pt-0">
                      <div className="col-12 text-right p-0">
                        <svg
                          onClick={handleHideEmployeeListModal}
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
                        </svg>
                      </div>
                      <div className="col-12 mb-2 p-0">
                        <input
                          type="search"
                          placeholder="Search"
                          className="form-control-inner"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={(e) => handleSearchOnEmployee(e, groupsListAllEmployees)}
                        />
                        {!isFocused && (
                          <span className="expand-search">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2-2-.9-2-2-2zm-6 12c.22-.72 3.31-2 6-2 0-.7.13-1.37.35-1.99C7.62 13.91 2 15.27 2 18v2h9.54c-.52-.58-.93-1.25-1.19-2H4zm15.43 0.02c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22 22 20.59c-1.5-1.5-.79-.8-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                            </svg>
                          </span>
                        )}
                      </div>

                      <div className="team-imgs-content">
                        {modalEmployeeList.EmployeeDetails?.map((employee: BatchGroupsEmployees) => (
                          <div className="team-imgs-inner" key={employee.employee_id}>
                            <OverlayTrigger
                              key={employee.employee_id}
                              trigger={["hover", "focus"]}
                              placement="auto"
                              overlay={popoverHoverFocus(employee, groupsListAllEmployees.batch_name)}
                            >
                              <div>
                                <svg
                                  className={`ParticipantImage c-pointer ${getEmployeeStats(employee)}`}
                                  viewBox="0 0 100 100"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <defs>
                                    <pattern id="aa25fdac-619a-4e6e-abdf-553668fc5534" patternUnits="userSpaceOnUse" width="100" height="100">
                                      <image href="/assets/media/images/userProfileImg.png" x="-25" width="150" height="100"></image>
                                    </pattern>
                                  </defs>
                                  <polygon
                                    id="hex"
                                    points="50 1 95 25 95 75 50 99 5 75 5 25"
                                    fill="url(#aa25fdac-619a-4e6e-abdf-553668fc5534)"
                                  ></polygon>
                                </svg>
                              </div>
                            </OverlayTrigger>
                            {
                              empId == employee.employee_guid ? '' :

                                <div className="social">
                                  <button type="button" className="mr-1 status-btn" onClick={() => sendCallReq(employee)}>
                                    <svg
                                      style={{ color: "green" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                                    </svg>
                                  </button>
                                  <button type="button" className="status-btn" onClick={() => startChat(employee)}>
                                    <svg
                                      style={{ color: "crimson" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                                      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                                    </svg>
                                  </button>
                                </div>
                            }
                            <h6>{truncateText(employee.first_name ?? "-", 15)}</h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </>

            {groupsList?.map((group: BatchGroups) => {
              const displayedEmployees = group.EmployeeDetails.slice(0, maxEmployeeToDisplay);
              const remainingCount = group.EmployeeDetails.length - maxEmployeeToDisplay;

              return (
                <div className="team-box" key={group.batchGroup_Id}>
                  <div className="team-sub">
                    <h5>
                      {group.batch_name ?? "-"}
                      <span className="float-right" onClick={(e) => handleFavourite(group)}>
                        <svg
                          className={group.is_favourite ? "favorite-icon" : "favourite-icon-light"}
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
                        </svg>
                      </span>
                    </h5>
                    <div className="team-imgs">
                      {displayedEmployees?.map((employee: BatchGroupsEmployees) => (
                        <div className="team-imgs-sub">
                          <OverlayTrigger
                            key={employee.employee_id}
                            trigger={["hover", "focus"]}
                            placement="auto"
                            overlay={popoverHoverFocus(employee, group.batch_name)}
                          >
                            <div>
                              <svg
                                className={`ParticipantImage c-pointer ${getEmployeeStats(employee)}`}
                                viewBox="0 0 100 100"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <defs>
                                  <pattern id={`pattern-${employee.employee_id}`} patternUnits="userSpaceOnUse" width="100" height="100">
                                    <image href="/assets/media/images/userProfileImg.png" x="-25" width="150" height="100"></image>
                                  </pattern>
                                </defs>
                                <polygon id="hex" points="50 1 95 25 95 75 50 99 5 75 5 25" fill={`url(#pattern-${employee.employee_id})`}></polygon>
                              </svg>
                            </div>
                          </OverlayTrigger>
                          {
                              empId == employee.employee_guid ? '' :

                                <div className="social">
                                  <button type="button" className="mr-1 status-btn" onClick={() => sendCallReq(employee)}>
                                    <svg
                                      style={{ color: "green" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                                    </svg>
                                  </button>
                                  <button type="button" className="status-btn" onClick={() => startChat(employee)}>
                                    <svg
                                      style={{ color: "crimson" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                                      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                                    </svg>
                                  </button>
                                </div>
                            }
                          {displayedEmployees.length > 3 ? (
                            <h6>{truncateText(employee.first_name ?? "-", 15)}</h6>
                          ) : (
                            <h6>{employee.first_name ?? "-"}</h6>
                          )}
                        </div>
                      ))}

                      {remainingCount > 0 && (
                        <span className="expand customcursor" onClick={() => handleExpandClick(group)}>
                          {/* +{remainingCount} */}
                          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"></path>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  {modalEmployeeList && modalEmployeeList.batchGroup_Id === group.batchGroup_Id ? (
                    <div className="expand-div pt-0">
                      <div className="col-12 text-right p-0">
                        <svg
                          onClick={handleHideEmployeeListModal}
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
                        </svg>
                      </div>
                      <div className="col-12 mb-2 p-0">
                        <input
                          type="search"
                          placeholder="Search"
                          className="form-control-inner"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onChange={(e) => handleSearchOnEmployee(e, group)}
                        />
                        {!isFocused && (
                          <span className="expand-search">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M10 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2-2-.9-2-2-2zm-6 12c.22-.72 3.31-2 6-2 0-.7.13-1.37.35-1.99C7.62 13.91 2 15.27 2 18v2h9.54c-.52-.58-.93-1.25-1.19-2H4zm15.43 0.02c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L20.59 22 22 20.59c-1.5-1.5-.79-.8-2.57-2.57zM16 18c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path>
                            </svg>
                          </span>
                        )}
                      </div>

                      <div className="team-imgs-content">
                        {modalEmployeeList.EmployeeDetails?.map((employee: BatchGroupsEmployees) => (
                          <div className="team-imgs-inner">
                            <OverlayTrigger
                              key={employee.employee_id}
                              trigger={["hover", "focus"]}
                              placement="auto"
                              overlay={popoverHoverFocus(employee, group.batch_name)}
                              delay={{ show: 0, hide: 0 }}
                            >
                              <div>
                                <svg
                                  className={`ParticipantImage c-pointer ${getEmployeeStats(employee)}`}
                                  viewBox="0 0 100 100"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <defs>
                                    <pattern id="aa25fdac-619a-4e6e-abdf-553668fc5534" patternUnits="userSpaceOnUse" width="100" height="100">
                                      <image href="/assets/media/images/userProfileImg.png" x="-25" width="150" height="100"></image>
                                    </pattern>
                                  </defs>
                                  <polygon
                                    id="hex"
                                    points="50 1 95 25 95 75 50 99 5 75 5 25"
                                    fill="url(#aa25fdac-619a-4e6e-abdf-553668fc5534)"
                                  ></polygon>
                                </svg>
                              </div>
                            </OverlayTrigger>
                            {
                              empId == employee.employee_guid ? '' :

                                <div className="social">
                                  <button type="button" className="mr-1 status-btn" onClick={() => sendCallReq(employee)}>
                                    <svg
                                      style={{ color: "green" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 512 512"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                                    </svg>
                                  </button>
                                  <button type="button" className="status-btn" onClick={() => startChat(employee)}>
                                    <svg
                                      style={{ color: "crimson" }}
                                      stroke="currentColor"
                                      fill="currentColor"
                                      stroke-width="0"
                                      viewBox="0 0 24 24"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                                      <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"></path>
                                    </svg>
                                  </button>
                                </div>
                            }
                            {/* <h6>{employee.first_name}</h6> */}
                            <h6>{truncateText(employee.first_name ?? "-", 15)}</h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}

            <div className="top-content" onClick={handleScrollUp}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 448 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path>
              </svg>
            </div>
            <div className="down-content" onClick={handleScrollDown}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 384 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="col-4 mt-4">
          <div className="ProduceMenuGrid">
            <ul id="hexagonContainer">
              <li className="hex-row">
                <div className="hexagon" style={{ height: "100px", width: "120px" }}>
                  <Link to="/calendar" state={location.pathname}>
                    <div className="hex-inner">
                      <div className="hex-img" style={{ background: "#4f5587" }}>
                        <a className="inner-div">My Calendar</a>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="hexagon" style={{ height: "100px", width: "120px" }}>
                  <Link to="/checkins" state={location.pathname}>
                    <div className="hex-inner">
                      <div className="hex-img" style={{ background: "#4f5587" }}>
                        <a className="inner-div">My Check-Ins</a>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
              <li className="hex-row" style={{ paddingLeft: "71%" }}>
                <div className="hexagon c-hub-mobile-hexagon" style={{ height: "100px", width: "120px" }}>
                  <Link to="/files" state={location.pathname}>
                    <div className="hex-inner">
                      <div className="hex-img" style={{ background: "#4f5587" }}>
                        <a className="inner-div">My Files</a>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {openChat ? <UserChatComponent openChat={openChat} closeChat={closeChat}
        selEmpName={selectUser.FirstName + " " + selectUser.LastName}
        selEmpGuid={selectUser.EmpGuid} /> : undefined}
      {/* {openCall ? <PreCallPopup cancelCall={cancelCallLocal} type={'audio'}
        openCall={openCall} selectedUser={selectUser} /> : ''} */}
    </section>
  );
}

export default CollaborationHub;
