import * as T from '../actions/Types';

interface IState {
    mediaTrackList: any[];
    soundTrackList: any[];
    leaderShipTrackList: any[];
    anxietySlayerTrackList: any[];
    diversityTrackList: any[];
    lifeBalanceTrackList: any[];
    guidedMeditationTrackList: any[];
    personalGrowthTrackList: any[];
    pilanthropyTrackList: any[];
    mentalHealthTrackList:any[];
    profileAvatarsList:any[];
    meetingId:any;
}
const defaultState: IState = {
    mediaTrackList: [],
    soundTrackList: [],
    leaderShipTrackList: [],
    anxietySlayerTrackList: [],
    diversityTrackList: [],
    lifeBalanceTrackList: [],
    guidedMeditationTrackList: [],
    personalGrowthTrackList: [],
    pilanthropyTrackList: [],
    mentalHealthTrackList:[],
    profileAvatarsList:[],
    meetingId:'',
};
const MediaReducer = (state: IState = defaultState, action: any): IState => {
    switch (action.type) {
        case T.MEETINID_SET: {
            return { ...state, meetingId: action.payload || [] }
        }
        case T.MEDIA_TRACK_LIST_SUCCESS: {
            return { ...state, mediaTrackList: action.payload || [] }
        }
        case T.SOUND_TRACK_LIST_SUCCESS: {
            return { ...state, soundTrackList: action.payload || [] }
        }
        case T.LEADER_TRACK_LIST_SUCCESS: {
            return { ...state, leaderShipTrackList: action.payload || [] }
        }
        case T.ANXIETY_TRACK_LIST_SUCCESS: {
            return { ...state, anxietySlayerTrackList: action.payload || [] }
        }
        case T.DIVERSITY_TRACK_LIST_SUCCESS: {
            return { ...state, diversityTrackList: action.payload || [] }
        }
        case T.LIFE_BALANCE_TRACK_LIST_SUCCESS: {
            return { ...state, lifeBalanceTrackList: action.payload || [] }
        }
        case T.GUIDED_MEDITATION_TRACK_LIST_SUCCESS: {
            return { ...state, guidedMeditationTrackList: action.payload || [] }
        }
        case T.PERSONAL_GROWTH_TRACK_LIST_SUCCESS: {
            return { ...state, personalGrowthTrackList: action.payload || [] }
        }
        case T.PILANTHROPY_TRACK_LIST_SUCCESS: {
            return { ...state, pilanthropyTrackList: action.payload || [] }
        }
        case T.MENTALHEALTH_TRACK_LIST_SUCCESS: {
            return { ...state, mentalHealthTrackList: action.payload || [] }
        }
        case T.PROFILE_AVATAR_LIST_SUCCESS: {
            return { ...state, profileAvatarsList: action.payload || [] }
        }
        default: return state;
    }
}

export default MediaReducer;