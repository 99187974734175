import { StoreClass } from '../../store/Store';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
//import { Doughnut } from 'react-chartjs-2';
import { GetEmpSessionsSplit } from '../../services/AnalyticsService';
//import { whiteboard } from '../../store/WhiteBoard';
import { t } from '../../i18n';
function EngagementPie(props: any) {
    const { EMP } = useSelector((state: StoreClass) => ({ EMP: state?.EmployeeReducer }));
    const empGuid = localStorage.getItem('EmpGuid') || '';
    const [data, setData] = useState<any>({
        labels: [
            'Collaborate',
            'Office',
            'Social & Wellness'
        ],
        datasets: [{
            data: [0, 0, 0],
            backgroundColor: [
                '#04f1b2',
                '#6264a5',
                '#ff715b'
            ],
            hoverBackgroundColor: [
                '#04c794',
                '#4d4f84',
                '#ff715b'
            ]
        }]
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetEmpSessionsSplit(empGuid) as any);
    }, []);
    useEffect(() => {
        if (EMP.sessionsSplit && EMP.sessionsSplit.ColloborateSessionsLegth > 0) {
            const data2 = {
                labels: [
                    'Collaborate',
                    'Office',
                    'Social & Wellness'
                ],
                datasets: [{
                    data: [EMP.sessionsSplit.ColloborateSessionsLegth,
                    EMP.sessionsSplit.CustomSessionLength,
                    EMP.sessionsSplit.SocialSessionsLegth],
                    backgroundColor: [
                        '#04f1b2',
                        '#6264a5',
                        '#ff715b'
                    ],
                    hoverBackgroundColor: [
                        '#04c794',
                        '#4d4f84',
                        '#ff715b'
                    ]
                }]
            };
            setData(data2);
        } else {

        }

    }, [EMP.sessionsSplit]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                fullWidth: true,
                reverse: false,
                labels: {
                    color: '#ffffff',
                },
            },
        },
        layout: {
            padding: 15
        },
        datasets: {
            doughnut: {
                cutout: 80,
                radius: 100
            }
        }
    };
    
    return (
        <div style={{ height: '300px', width: '330px', marginLeft: '-40px' }}>
            <div className="col-12">
                        <p className="mb-2 text-muted small">{t('myprofile.total_sessions')}</p>
                        <h4 className="m-0 text-white">{EMP.sessionTotals&&EMP.sessionTotals.TotalSessions?EMP.sessionTotals.TotalSessions:0}</h4>
                        <p className="m-0 text-muted small"></p>
            </div>
            <div className="col-12">
                        <p className="mb-2 text-muted small">{t('myprofile.avg_sessions_week')}</p>
                        <h4 className="m-0 text-white">{props.weekCount}</h4>
                        <p className="m-0 text-muted small"></p>
            </div>
            <div className="col-12">
                        <p className="mb-2 text-muted small">{t('myprofile.avg_session_length')}</p>
                        <h4 className="m-0 text-white">{props.avgSessionLen}</h4>
                        <p className="m-0 text-muted small"></p>
            </div>
        </div>
    );
}

export default EngagementPie;