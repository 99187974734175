import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { t } from '../../i18n';
 import { SetMeetingId } from "../../services/MediaService";
import HealthTracking from '../wellness/healthTracking';

function HangoutMenu(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const history = useNavigate();
 
    useEffect(() => {
        if (!media.meetingId) {
            dispatch(SetMeetingId(props.meetingId) as any);
        }
    }, []);

    

    const handleCloseMenu = () => {
        if (props.showChillMenu) {
            props.setShowChillMenu(false);
        } else if (props.showCommunityMenu) {
            props.setShowCommunityMenu(false);
        }else if(props.setInformationSideNav!=undefined){
            props.setInformationSideNav(false);
        }else if(props.setChallengesSidenav != undefined){
            props.setChallengesSidenav(false);
        }
        else if(props.setClassesSidenav != undefined){
            props.setClassesSidenav(false);
        }
    }


    return (
        <ul id="OBhexGrid" className="HangOutMenu">
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex" style={{ top: '94px' }}>
                <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}>
                    <div className="CloseNav"
                        onClick={handleCloseMenu}
                    >
                        <img style={{ marginLeft: '50px', marginTop: '12px', height: '30px' }} src="assets/media/svg/x-close.svg" /> </div>
                </a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex">
                <Link className={props.type === "Content" ? "OBhexIn active" : "OBhexIn"} to={props.arr[0].link}>
                    <p> <span className="align-self-center">
                        <img src="assets/media/svg/sute_selections.svg" />
                        <u className="OBhexTitle">{props.arr[0].name}</u> </span> </p>
                </Link>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex">
                <Link className={props.type === "Challenges" ? "OBhexIn active" : "OBhexIn"} to={props.arr[1].link}>
                    <p> <span className="align-self-center">
                        <img src="assets/media/svg/wellness.svg" />
                        <u className="OBhexTitle">{props.arr[1].name}</u> </span> </p>
                </Link>
            </li>
            <li className="OBhex">
                <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex">
                <Link className={props.type === "Classes" ? "OBhexIn active" : "OBhexIn"} to={props.arr[2].link}>
                    <p> <span className="align-self-center">
                        <img src="assets/media/svg/onboarding/audio-video.svg" />
                        <u className="OBhexTitle">{props.arr[2].name}</u> </span> </p>
                </Link>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex opacity0">
                <a className="OBhexIn"></a>
            </li>
            <li className="OBhex" style={{ top: '-94px', zIndex: -1 }}>
                <a className="OBhexIn" style={{ backgroundColor: 'var(--SpaceCadet)' }}></a>
            </li>
        </ul>
    );
}

export default HangoutMenu;