import moment from 'moment';
import * as T from '../actions/Types';

interface IState {
    events?: any[];
    googleEvents?: any[];
    exchnageevents?: any[];
    updateEventResponse?: string;
    calendarCategories?: any;
    availableTimeSlots?: any[];
    internalEventCreate?: any;
    deleteEventResponse?: string;
    createEventResponse?: string;
    deleteAccountResponse?: any;
    eventParticipants?: string;
    calendarEmails?: any[];
    groupEvents?: any[];
    feedbackTypes?: any[];
    saveSuccess?: any;
    sessionTotals?: any;
    sessionsSplit?: any;
    sessionTrace?:any;
}

const defaultState: IState = {
    events: [],
    groupEvents: [],
    calendarEmails: [],
    deleteAccountResponse: {},
    feedbackTypes: [],
    calendarCategories: [{ CategoryId: 1, CategoryName: "Reminder", Checked: true }, { CategoryId: 2, CategoryName: "Task", Checked: true }, { CategoryId: 3, CategoryName: "Event", Checked: true }]
};

const EmployeeReducer = (state: IState = defaultState, action: any): IState => {
    
    switch (action.type) {
        case T.GET_EMPLOYEE_EVENTS: {
            
            return { ...state, events: action.data.result }
        }
        case T.SYNC_GOOGLE_CALENDER: {
            return { ...state, googleEvents: action.data }
        }
        case T.SYNC_EXCHANGE_CALENDER: {
            return { ...state, exchnageevents: action.data }
        }
        case T.CHECK_UNCHECK_EVENT_EMAIL: {
            return {
                ...state,
                events: (state.events || []).map(
                    (event, i) => event.Email === action.data.Email ? { ...event, Checked: action.data.Checked }
                        : event
                )
            }
        }
        case T.CHECK_UNCHECK_EVENT_CATEGORY: {
            // 
            return {
                ...state,
                calendarCategories: (state.calendarCategories || []).map(
                    (category: any, i: number) => category.CategoryId === action.data.CategoryId ? { ...category, Checked: action.data.Checked }
                        : category
                )
            }
        }
        case T.CALENDAR_EVENT_UPDATE_SUCCESS: {
            return { ...state, updateEventResponse: action.data }
        }
        case T.CALENDER_EVENT_CATEGORIES: {
            return { ...state, calendarCategories: action.data }
        }
        case T.AVAILABLE_TIME_SLOTS_SUCCESS: {
            const data = Array.isArray(action.data) ? action.data : action.data.result;
            // (data || []).forEach((slot: any) => {
            //     var dt = '04/23/2021 ' + slot.StartTime + ':00';
            //     var conDt = new Date(dt);
            //     var localString = conDt.toLocaleString();
            //     var mom = moment(localString, ["hh:mm A"]);
            //     const startNumber = moment(localString, ["h:mm A"]).format("HH:mm");
            //     slot.StartTime = startNumber;// "14.00"

            //     var dt = '04/23/2021 ' + slot.EndTime + ':00 UTC';
            //     var conDt = new Date(dt);
            //     var localString = conDt.toLocaleString();
            //    // var mom = moment(localString).format('hh:mm a');
            //     const endNumber = moment(localString, ["h:mm A"]).format("HH:mm");
            //     slot.EndTime = endNumber;// "14.00"
            // });
console.log("============================================= DATA ===============================", data);
            return { ...state, availableTimeSlots: data }
        }
        case T.INTERNAL_EVENT_CREATE_SUCCESS: {
            return { ...state, internalEventCreate: { ...action.data } }
        } case T.DELETE_CALENDAR_EVENT_SUCCESS: {
            return { ...state, deleteEventResponse: action.payload }
        } case T.SAVE_GOOGLE_CALENDAR_EVENT_SUCCESS: {
            return { ...state, createEventResponse: action.payload }
        } case T.DELETE_CALENDAR_ACCOUNT_SUCCESS: {
            return { ...state, deleteAccountResponse: action.payload }
        }
        case T.EVENT_PARTICIPANTS: {
            return { ...state, eventParticipants: action.payload }
        }
        case T.GET_EMPLOYEE_CALENDAR_EMAILS: {
            return { ...state, calendarEmails: action.data }
        }
        case T.REMOVE_CAL_SUCCESS: {
            return {
                ...state, internalEventCreate: '',
                deleteEventResponse: '',
                createEventResponse: '', updateEventResponse: '', deleteAccountResponse: ''
            }
        }
        case T.GROUP_EVENTS_SUCCESS: {
            return { ...state, groupEvents: action.payload };
        }
        case T.GET_FEEDBACK_TYPES: {
            return {...state, feedbackTypes: action.payload};
        }
        case T.GET_SESSION_TOTALS: {
            return {...state, sessionTotals: action.payload}
        }
        case T.GET_SESSIONS_SPLIT: {
            return {...state, sessionsSplit: action.payload}
        }
        case T.GET_PRODUCTIVE_NON_PRODUCTIVE:{
            return {...state, sessionTrace: action.payload}
        }
        default: return state;
    }
}

export default EmployeeReducer;

