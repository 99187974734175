import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { BookReminderForContct, getEmployeeCalanderEvents, GetTimeslots, removeSuccessStatus } from '../../services/CalenderSevice';
import Calendar from 'react-calendar';
import { StoreClass } from '../../store/Store';
import clsx from 'classnames'
import { t } from '../../i18n';
import { globalStore } from "../../store/global_store";
import { v4 as uuidv4 } from 'uuid';
import MediaSoundTracks from './MediaSoundTracks';

function getRoomId() {
    return uuidv4();
}

interface formError {
    message: string;
}
class BookReminderForContact {
    SelectedDate: string = '';
    StartTime: string = '';
    EndTime: string = '';
    EmpGuid: string = '';
    EventDescription: string = '';
    EventName: string = '';
    TimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    IsAssigned: Boolean = false;
    Email: string = 'Sute';
    Content: any[] = [];
    Participants: string[] = [];
    MeetingLink: string = '';
    EmpEmail: string = ''
    OrgGuid: string = '';
    EmpId: Number = 0;
    EmpName: string = '';
}
function TimeSlotPopup(props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const modelRef = useRef<HTMLDivElement>();
    const dispatch = useDispatch();
    const employee = useSelector((store: any) => store.EmployeeReducer)
    const myUserId = localStorage.getItem('EmpGuid');
    const myEmpEmail = localStorage.getItem('UserName');
    const myOrgId = localStorage.getItem('OrgGuid');
    const myEmpId = localStorage.getItem('EmpId');
    const myEmpName = localStorage.getItem("FirstName");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [bookReminder, setbookReminder] = useState(new BookReminderForContact());
    const [meetingId, setMeetingId] = useState('');
    const [showMeetingLink, setShowMeetingLink] = useState(false);
    const [showMediaAttachments, setShowMediaAttachments] = useState(false);
    const [showMediaSoundTracks, setShowMediaSoundTracks] = useState(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [eventNameErrors, setEventnameErrors] = useState({} as formError);
    const minDate: Date = new Date();
    const app_url = process.env.REACT_APP_URL as string;
    var selected: string;
    let startTime: string = '';
    let endTime: string = '';
    console.log("========================================== SHOW ========================", props.show);
    useEffect(() => {
        if (props.EmpGuid && myUserId) {
            let defaultDate = formatDate(selectedDate);
            setbookReminder({ ...bookReminder, SelectedDate: defaultDate, EventName: '', StartTime: '', EndTime: '', EventDescription: '', Participants: [...bookReminder.Participants, myEmpEmail ? myEmpEmail : '', props.EmpOfficeEmail], EmpEmail: myEmpEmail ? myEmpEmail : '', OrgGuid: myOrgId ? myOrgId : '', EmpId: new Number(myEmpId) ? new Number(myEmpId) : 0, EmpName: myEmpName ? myEmpName : '' });
            dispatch(GetTimeslots(props.EmpGuid, defaultDate) as any);
            if (employee.events.length == 0) {
                dispatch(getEmployeeCalanderEvents(myUserId,new Date().getTimezoneOffset()) as any);
            }
        }
    }, [props.EmpGuid]);

    useEffect(() => {
        if (employee.internalEventCreate && employee.internalEventCreate.message === 'Event Created Successfully') {
            globalStore.showToast({
                type: 'Message',
                title: "Event",
                message: employee.internalEventCreate.message
            });
            dispatch(removeSuccessStatus() as any);
            dispatch(getEmployeeCalanderEvents(myUserId,new Date().getTimezoneOffset()) as any);
            //SaveActivity({ msgType: "CALENDAR_BLOCK", eventId: employee.internalEventCreate.eventId, reason: "Your calender blocked by " + localStorage.getItem("FirstName"), name: localStorage.getItem("FirstName"), fromEmpId: myUserId }, 'SCHEDULE', bookReminder.ContactGuid);
            props.close();
        }
    }, [employee.internalEventCreate, employee.events]);

    useEffect(() => {
        if (eventNameErrors.message == 'Select start time and End Time' && bookReminder.StartTime != '' && bookReminder.EndTime != '') {
            setEventnameErrors({ message: '' })
        }
    })

    const Submit = () => {
        if (bookReminder.StartTime != '' && bookReminder.EndTime != ''
            && bookReminder.EventName && bookReminder.SelectedDate && bookReminder.EventName !== '') {
            const reminderObj: any = bookReminder;
            let startHours: any = parseInt(reminderObj.StartTime.split(":")[0]);
            var startminutes = reminderObj.StartTime.split(":")[1];
            if (reminderObj.StartMeridian == 'PM') {
                startHours = startHours + 12;
            }
            let endHours: any = parseInt(reminderObj.EndTime.split(":")[0]);
            var endminutes = reminderObj.EndTime.split(":")[1];
            if (reminderObj.EndMeridian == 'PM') {
                endHours = endHours + 12;
            }
            let startDate = selectedDate;
            startDate.setHours(startHours);
            startDate.setMinutes(Number(startminutes));
            reminderObj.UTCStartTime = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes());
            let endDate = selectedDate;
            endDate.setHours(endHours);
            endDate.setMinutes(Number(endminutes));
            reminderObj.UTCEndTime = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes());
            reminderObj.AttachmentsContent = JSON.stringify(bookReminder.Content);
            dispatch(BookReminderForContct(bookReminder) as any);
            //bookReminder.ContactGuid
        }
        else {
            //  
            // globalStore.showToast({
            //     type: 'Message',
            //     title: "Event",
            //     message: "Please fill all the fields to schedule appointment."
            // });
            if (bookReminder.StartTime == '' && bookReminder.EndTime == '') {
                setEventnameErrors({ message: 'Select Start time and End Time' });
            }
            else if (bookReminder.EventName == '') {
                setEventnameErrors({ message: 'Enter Event Name' });
            }
        }
    }

    const TimeSlotSelection = (e: any) => {
        startTime = e.target.innerHTML.substr(0, e.target.innerHTML.indexOf('-'));
        endTime = e.target.innerHTML.substr(e.target.innerHTML.indexOf('-') + 1);
        let EmpGuid = myUserId ? myUserId : '';
        setbookReminder({ ...bookReminder, EmpGuid: props.EmpGuid, StartTime: startTime, EndTime: endTime });
        setEventnameErrors({ message: '' });
    }

    function formatDate(date: any) {
        var fullDate = date;
        var twoDigitMonth = fullDate.getMonth() + 1;
        if (twoDigitMonth.toLocaleString().length == 1)
            twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.toLocaleString().length == 1)
            twoDigitDate = "0" + twoDigitDate;
        var currentDate = fullDate.getFullYear() + '-' + twoDigitMonth + '-' + twoDigitDate;
        return currentDate;
    }
    const onChange = (e: any) => {
        //console.log("select date:", props);
        try {
            setSelectedDate(e);
            selected = formatDate(e);
            //var date = new Date(selected);
            setbookReminder({ ...bookReminder, SelectedDate: selected, StartTime: '' })
            dispatch(GetTimeslots(props.EmpGuid, selected) as any);
        }
        catch (ex) {
            console.log(ex);
        }
        console.log("selected date", selected)
    }

    const selectEmail = (email: any) => {
        setbookReminder({ ...bookReminder, Email: email.target.value })
    }

    useEffect(() => {
        if (meetingId != '') {
            setbookReminder({ ...bookReminder, MeetingLink: `${app_url}/customrm/${meetingId}` })
            setShowMeetingLink(true);
        }
    }, [meetingId]);

    const GenerateMeetingLink = (e: any) => {
        //e.preventdefault();
        setMeetingId(getRoomId());
    }

    const addContentClick = () => {
        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(true);
    }

    const closeAddContentClick = () => {
        setShowMediaSoundTracks(!showMediaSoundTracks);
        setShowAddPopup(false);
    }

    const AddMediaAttachments = (e: any) => {
        setShowAddPopup(false);
        console.log(e);
        setbookReminder({ ...bookReminder, Content: e })
    }

    const removeMediaAttachments = (index: number) => {
        const list = bookReminder.Content;
        list.splice(index, 1);
        setbookReminder({ ...bookReminder, Content: list });
    }

    const errormsg = (<div className='row'> <div className="col-12"><div className="alert alert-danger text-center">
        <strong>Error!</strong> {eventNameErrors.message}
    </div></div></div>);

    return (
        <Modal
            size='lg'
            ref={modelRef}
            show={props.show}
            onHide={props.close}
            className="modal fade"
            backdrop="static"
            keyboard={false}
        >            <Modal.Header>
                <h5 className="modal-title text-white">{t('calendar.schd_appointment')}</h5>
                <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <Calendar
                                minDate={minDate}
                                onChange={onChange}
                                value={selectedDate}
                            />
                        </div>
                        <div className="col-sm-6 text-white">

                            {/* {bookReminder.SelectedDate && <p><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M3.5 0v5m8-5v5m-10-2.5h12a1 1 0 011 1v10a1 1 0 01-1 1h-12a1 1 0 01-1-1v-10a1 1 0 011-1z" stroke="#fff"></path></svg> {bookReminder.SelectedDate} {bookReminder.StartTime && <svg className="ml-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 7.5H7a.5.5 0 00.146.354L7.5 7.5zm0 6.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM7 3v4.5h1V3H7zm.146 4.854l3 3 .708-.708-3-3-.708.708z" fill="#fff"></path></svg>} {bookReminder.StartTime} <select onChange={selectEmail} value={bookReminder.Email} className="SuteInputPrimary float-right emailSelectionDD">
                                 <option disabled selected>select Email</option>
                                <option value="select">{t('calendar.select')}</option>
                                <option value="Sute">Sute</option>
                                {(employee.events || []).map((obj: any, index: number) => {
                                    if (obj.IsShow) {
                                        return (<option key={index} value={obj.Email}>{obj.Email}</option>)
                                    }
                                })}
                            </select></p>} */}
                            <h6>{t('calendar.available_slots')}</h6>
                            {bookReminder.StartTime == '' && bookReminder.EndTime == '' && eventNameErrors.message && errormsg}
                            <ul className="timeslotsUL">
                                {/* <li className="active"><div className="timeslot"><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0zm7.072 3.21l4.318-5.398-.78-.624-3.682 4.601L4.32 7.116l-.64.768 3.392 2.827z" fill="#6264a5"></path></svg> 09:00</div></li> */}
                                {
                                    (employee.availableTimeSlots || []).map((obj: any, index: number) => {
                                        if (obj.StartTime >= '08:00' && (obj.StartTime < '21:00')) {
                                            return (
                                                <li key={'ts-' + index} className={clsx('', obj.StartTime === bookReminder.StartTime ? 'active' : '')} value={bookReminder.StartTime} onChange={e => { setbookReminder({ ...bookReminder }); setEventnameErrors({ message: '' }); }}><div className="timeslot" onClick={TimeSlotSelection}>{obj.StartTime}-{obj.EndTime}</div></li>
                                            )
                                        }
                                    })}
                            </ul>
                            <div className="col-sm-12 pl-0 addTeamEmails">
                                {(bookReminder.Participants || []).map((e = '') => e.trim()).filter((e: any) => e).map((obj: any, index: number) => {
                                    return (
                                        <div className="teamEmailHolder" key={index}>{obj}</div>)
                                })}
                            </div>
                            <div className="form-group mb-1 col-sm-12 pl-0 float-left">
                                <label className="col-form-label c-pointer" data-toggle="modal" onClick={addContentClick}><svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 6.5L1.328 9.672a2.828 2.828 0 104 4L8.5 10.5m2-2l3.172-3.172a2.829 2.829 0 00-4-4L6.5 4.5m-2 6l6-6" stroke="currentColor"></path></svg> Attach Content</label>
                            </div>
                            <div className="col-sm-12 pl-0 addTeamEmails">
                                {(bookReminder.Content || []).map((obj: any, index: number) => {
                                    return (
                                        <div className="teamEmailHolder" key={index}>{obj.TrackName} <img className="c-pointer" onClick={(e) => removeMediaAttachments(index)} src="assets/media/svg/onboarding/x-close.svg" /></div>)
                                })}
                            </div>
                            {/* <div className="form-group mb-1 col-sm-12 pl-0 float-left">
                                {!showMeetingLink ? <div className="row">
                                    <div className="col-sm-8">
                                        <button type="button" onClick={GenerateMeetingLink} className="btn btn-sm btn-SeaGreen btn-block">{t('calendar.add_sute_meeting_link')}</button>
                                    </div>
                                </div> : <></>}
                                {showMeetingLink ?
                                    <label className="col-form-label fs12"><a className="startMettingLink" href={bookReminder.MeetingLink}><b>{t('calendar.start_meeting')}</b></a> {t('calendar.copy_url')} {"https://" + bookReminder.MeetingLink}</label> : <></>}
                            </div> */}
                            {bookReminder.StartTime != '' && bookReminder.EndTime != '' && bookReminder.EventName == '' && eventNameErrors.message && errormsg}
                            <textarea className="w-100 SuteInputPrimary fs10" placeholder="Enter event name" value={bookReminder.EventName} onChange={e => { setbookReminder({ ...bookReminder, EventName: e.target.value }); setEventnameErrors({ message: '' }); }} style={{ 'height': '30px' }}></textarea>

                            <textarea className="w-100 SuteInputPrimary fs10" placeholder="Enter event description" value={bookReminder.EventDescription} onChange={e => setbookReminder({ ...bookReminder, EventDescription: e.target.value })} style={{ 'height': '50px' }}></textarea>

                            <div className="row">
                                <div className="col-12 text-center">
                                    <button type="button" onClick={Submit} className="btn btn-SeaGreen btn-sm">{t('button.submit')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MediaSoundTracks tracks={bookReminder.Content || []} show={showAddPopup} close={closeAddContentClick} AddMediaContent={(e: any) => AddMediaAttachments(e)} />
                </div>
            </Modal.Body>
        </Modal >
    );
}
export default TimeSlotPopup;