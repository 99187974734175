import React, { useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { t } from '../i18n';
import { ModalFooter } from 'reactstrap';

function ConfirmPopup(props: any) {
    const modelRef = useRef<HTMLDivElement>();
    useEffect(() => {

    }, [])
    return (
        <Modal ref={modelRef} show={props.show} onHide={props.close} className="text-light">
            <Modal.Header closeButton className="border-0">
                {/* <h6 className="modal-title" id="confirmPopupLabel">{t('roomloginpopup.are_you_sure')}</h6>
                <img onClick={props.close} className="float-right c-pointer" data-dismiss="modal" src="/assets/media/svg/x-close.svg" /> */}
                <Modal.Title className="modal-title h5" id="confirmPopupLabel">{t('roomloginpopup.are_you_sure')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: props.message }}></div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.okHandle} type="button" className="btn approve-btn">
                    {/* {t('button.yes')} */}
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
                    </button>
                <button onClick={props.close} type="button" className="btn reject-btn" data-dismiss="modal">
                    {/* {t('button.no')} */}
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg>
                    </button>

            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(ConfirmPopup);