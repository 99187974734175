import { 
  ClientAuthError, InteractionRequiredAuthError, UserAgentApplication
} from 'msal';
//import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import  Identity from './Identity';
import  InteractiveSignInRequired from './InteractiveSignInRequired';
import { config } from '../Config';

class AuthService {
  private signInOptions: any;
  private msalConfig: any;
  private msalClient: UserAgentApplication;

  constructor(configuration: any) {
    this.signInOptions = {
      scopes: configuration.scopes
    };
    var returnURL = window.location.hostname;
   // console.log(returnURL);
    if (returnURL === 'localhost') {
        returnURL = 'http://' + window.location.hostname + ':3000/login';
    } else {
        returnURL = 'https://' + window.location.hostname + '/login';
    }
    this.msalConfig = {
      auth: {
        authority: configuration.authority,
        clientId: configuration.appId,
        redirectUri: returnURL
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
        cacheExpiration: {
          // Set the refresh token expiration time to 1 hour (in seconds)
          refreshToken: 300
        }
      }
    };

    this.msalClient = new UserAgentApplication(this.msalConfig);
    //console.log('AuthService:: initialized: ', this.msalConfig);
  }

  get serviceName() { return 'Microsoft'; }

  //get icon() { return faMicrosoft; }

  async signIn() {
    const response = await this.msalClient.loginRedirect(this.signInOptions);
   // console.log(response);
    return new Identity(response);
  }
  async signInWithPopup() {
   // this.msalConfig.auth.redirectUri = "";
    this.msalClient = new UserAgentApplication(this.msalConfig);
    const response = await this.msalClient.loginPopup(this.signInOptions);
   // console.log(response);
    return new Identity(response);
  }

  signOut() {
    this.msalClient.logout();
  }

  async getIdentity() {
    const account = this.msalClient.getAccount();
    if (account) {
      try {
        const response = await this.msalClient.acquireTokenSilent(this.signInOptions);
        console.log(response);
        if(response.accessToken){
          localStorage.setItem('msAccessToken', response.accessToken);
          localStorage.setItem("isAuthenticated", 'true');
          localStorage.setItem("firstname", response.account.name);
          localStorage.setItem("email", response.account.userName);
          localStorage.setItem('userId', response.uniqueId);
          //window.location.href="/myoffice";
          
        }
        return new Identity(response);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          throw new InteractiveSignInRequired();
        }
        if (error instanceof ClientAuthError) {
          // On mobile devices, ClientAuthError is sometimes thrown when we

          // can't do silent auth - this isn't generally an issue here.

          if (error.errorCode === 'block_token_requests') {
            throw new InteractiveSignInRequired();
          }
          console.warn('ClientAuthError: error code = ', error.errorCode);
        }
        throw error;
      }
    }
    throw new InteractiveSignInRequired();
  }
}

const authService = new AuthService(config);

export default authService;