import {Subject} from 'rxjs';
import GlobalStorage from '../utils/custom-storage';


export type GlobalState = {
  loading: boolean
  lock: boolean
  windowFocus: boolean
  toast: {
    type: string
    message: string
    title?:string
    link?: string
  }
  DN: {
    icon: string
    message: string
    title?:string
    link?: string
  }
  dialog: {
    type: string
    message: string
    confirmText?: string
    cancelText?: string
  }
  nativeWindowInfo: {
    visible: boolean
    items: any[]
  },
  WebNotice: {
    message: string
    reason: string
    options?: any
  },
  active: string,
  language: string,
  success: string
}
export class Root {
  private subject: Subject<GlobalState> | null;
  public state: GlobalState;
  public readonly defaultState: GlobalState = {
    loading: false,
    windowFocus: false,
    success: "",
    toast: {
      type: '',
      message: '',
      title:'Message'
    },
    DN:{
      icon: '',
      message: '',
      title: 'Sute Message',
      link: ''
    },
    dialog: {
      type: '',
      message: '',
      confirmText: 'confirm',
      cancelText: 'cancel'
    },
    nativeWindowInfo: {
      visible: false,
      items: []
    },
    WebNotice: {
      message: '',
      reason: ''
    },
    lock: false,
    active: 'chatroom',
    ...GlobalStorage.getLanguage(),
  }

  constructor() {
    this.subject = null;
    this.state = this.defaultState;
  }

  initialize() {
    this.subject = new Subject<GlobalState>();
    this.state = {
      ...this.defaultState,
    }
    this.subject.next(this.state);
  }

  subscribe(updateState: any) {
    this.initialize();
    this.subject && this.subject.subscribe(updateState);
  }

  unsubscribe() {
    this.subject && this.subject.unsubscribe();
    this.subject = null;
  }

  commit (state: GlobalState) {
    this.subject && this.subject.next(state);
  }

  updateState(rootState: GlobalState) {
    this.state = {
      ...this.state,
      ...rootState,
    }
    this.commit(this.state);
  }

  setNativeWindowInfo({visible, items}: {visible: boolean, items: any[]}) {
    this.state = {
      ...this.state,
      nativeWindowInfo: {
        visible,
        items
      }
    }
    this.commit(this.state);
  }

  showToast({type, message,title, link}: {type: string, message: string, title?:string, link?:string}) {
    this.state = {
      ...this.state,
      toast: {
        type, message, title, link
      },
    }
    this.commit(this.state);
  }

  showWebNotice({reason, message, options}: {reason: string, message: string, options?:any}) {
    this.state = {
      ...this.state,
      WebNotice: {message, reason, options
      }
    }
    this.commit(this.state);
  }
 clearWebNotice() {
   console.log('state update');
  this.state = {
    ...this.state,
    WebNotice: {message:'', reason:''}
  }
  this.commit(this.state);
 }
  deskTopNotice({icon, message,title, link}: {icon: string, message: string, title?:string, link?:string}) {
    this.state = {
      ...this.state,
      DN: {
        icon, message, title, link
      }
    }
    this.commit(this.state);
  }

  showDialog({type, message}: {type: string, message: string}) {
    this.state = {
      ...this.state,
      dialog: {
        type,
        message
      },
    }
    this.commit(this.state);
  }

  removeDialog() {
    this.state = {
      ...this.state,
      dialog: {
        type: '',
        message: ''
      },
    }
    this.commit(this.state);
  }

  showLoading () {
    this.state = {
      ...this.state,
      loading: true
    }
    this.commit(this.state);
  }

  stopLoading () {
    this.state = {
      ...this.state,
      loading: false
    }
    this.commit(this.state);
  }

  setActive(active: string) {
      this.state = {
        ...this.state,
        active,
      }
    this.commit(this.state);
  }

  lock() {
    this.state = {
      ...this.state,
      lock: true
    }
    this.commit(this.state)
  }

  unlock() {
    this.state = {
      ...this.state,
      lock: false
    }
    this.commit(this.state)
  }
  focus(windowFocus: boolean) {
    this.state = {
      ...this.state,
      windowFocus
    }
    this.commit(this.state)
  }
}

export const globalStore = new Root();

// @ts-ignore
window.globalStore = globalStore;