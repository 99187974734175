import { useState, useEffect } from 'react';
import { t } from '../../i18n';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import WindowTitle from '../../layout/WindowTitle';
import { StoreClass } from '../../store/Store';
import HangoutMenu from '../onboarding/HangoutMenu';
import { link } from 'fs';


function HangoutRoom(_props: any) {
    const { user } = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
    const history = useNavigate();
    const [meetingId, setMeetingId] = useState('');
    const { meetingPId } = useParams();
    const Chill = [{ name: "Health Dashboard", link: "/assessment" }, { name: "Wellness hub", link: "/information" }, { name: "Wellness Patner", link: "/wellnesspartners" }];
    const community = [{ name: "Leader board", link: "/leaderboard" }, { name: "Challenges", link: "/curatedchallenges" }, { name: "Socialize", link: "/chatroulette" }]
    const dispatch = useDispatch();

    useEffect(() => {
        setMeetingId(meetingPId || '');
        // if (!user.groups || user.groups.length == 0) {
        //     dispatch(getGroupsByEmpId(empId) as any);
        // } else {
        //     const room = user.groups.filter((r: any) => r.RoomGuid === meetingPId);
        //     if (room.length > 0) {
        //         setRoomDtls(room[0]);
        //     }
        // }
        // dispatch(getOrgDetailsByOrgId(orgId) as any);
        // // chatClient.on('chatNotify', getChatNotify);
        // console.log("attaching event");
        return () => {
            // chatClient.off('chatNotify', getChatNotify);
        }
    }, []);

    // useEffect(() => {
    //     if (user.success && user.success === 'AddEmployeeToRoom') {
    //         globalStore.showToast({
    //             type: 'Rooms',
    //             title: "Room Message",
    //             message: "Joined room successfully"
    //         });
    //         dispatch(removeSuccessStatus() as any);
    //     } else if ("RemoveEmployeeFromRoom" === user.success) {
    //         globalStore.showToast({
    //             type: 'Rooms',
    //             title: "Room Message",
    //             message: "You left the room"
    //         });
    //         dispatch(removeSuccessStatus() as any);
    //         history('/pod');
    //     } else if ("UpdateRoomLock" === user.success) {
    //         globalStore.showToast({
    //             type: 'Rooms',
    //             title: "Room Message",
    //             message: `Room ${locked ? "locked" : "unlocked"} successfully`
    //         });
    //         dispatch(removeSuccessStatus() as any);
    //     }
    // }, [user.success]);

    // useEffect(() => {
    //     if (!suteClient.joined) {           
    //        // makeJoin(meetingPId);
    //         suteClient.on('playerStatuschanged', playerStatusChangeEvent);
    //     }
    //     return () => {
    //         suteClient.off('playerStatuschanged', playerStatusChangeEvent);
    //     }
    // }, [suteClient.joined]);

    // const playerStatusChangeEvent = (event: any) => {
    //     console.log('event>>', event);
    //     setAudio(suteClient.params.audio);
    // }

    // useEffect(() => {
    //     setLocalStream({ ...suteClient.localStream });
    //     setPublished(suteClient.published);
    //     setStreamsInfo(chatClient.streamsInfo);
    //     if (suteClient.published) {
    //         updateIntId(setInterval(postRoomStatus, 120000));
    //     }
    //     return () => {
    //         clearInterval(postRoomStatusInt._id);
    //     }
    // }, [suteClient.published, chatClient.streamsInfo]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLocalStream(suteClient.localStream);
    //     }, 500);
    // }, [joined]);

    // useEffect(() => {
    //     console.log(user.orgData)
    // }, [user.orgData]);
    // suteClient.onRemoteAdded = (newstream: any) => {
    //     setRemoteStreams([...suteClient.remoteStreams]);
    //     setStreamsInfo(chatClient.streamsInfo);
    // }
    // const leave = async ()=> {
    //     await suteClient.leave();
    //     const form = new FormData();
    //     form.append("GroupGuid", meetingId);
    //     form.append("EmployeeGuid", empId);
    //     form.append("no_toast", 'true');
    //     dispatch(leaveRoomById(form));
    //     setJoined(false);
    // }

    // const justLeave = async ()=> {
    //     await suteClient.leave();
    // }

    // const makeJoin = () => {
    //     if (joined && published) {
    //         //left from existing only rooms on navigation
    //         justLeave();
    //     }
    //     if (!suteClient.joined) {
    //         suteClient.join({ channel: meetingId, uid: empId, displayName: uname, type: 'onlyaudio' })
    //             .then(function (res: any) {
    //                 setJoined(true);
    //                 setAudio(true);
    //                 setStreamsInfo(chatClient.streamsInfo);
    //                 const form = new FormData();
    //                 form.append("GroupGuid", meetingId);
    //                 form.append("EmployeeGuid", empId);
    //                 dispatch(AddEmployeeToRoom(form));
    //             });
    //     } else {
    //         setPublished(suteClient.published);
    //         setRemoteStreams([...suteClient.remoteStreams]);
    //         setLocalStream({ ...suteClient.localStream });
    //         if (localStream) {
    //             setAudio(localStream.audio);
    //         }
    //     }
    // }
    // const onAudioClick = (evt: any) => {
    //     suteClient.onPlayerClick('audio', !suteClient.params.audio);
    //     setAudio(!suteClient.params.audio);
    // }
    // const sendJoinRequest = (empId: string, uname: string, mid: string) => {
    //     chatClient.sendNotification(mid, empId, "REQUEST_TO_JOIN");
    // }

    // const admitRequestedUsr = (empGuid: string) => {
    //     chatClient.sendNotification(empGuid, meetingId, "ROOM_ADMIT");
    //     const reqs = remoteRequests.filter(req => req.empGuid !== empGuid);
    //     setRemoteRequests([...reqs]);
    // }
    // const rejectRequestedUsr = (empGuid: string) => {
    //     chatClient.sendNotification(empGuid, meetingId, "ROOM_REJECT");
    //     const reqs = remoteRequests.filter(req => req.empGuid !== empGuid);
    //     setRemoteRequests([...reqs]);
    // }

    // const getChatNotify = (args: any) => {
    //    // const [msg, memberId] = args;
    //     const msgt = JSON.parse(args.text);
    //     console.log("hangout getChatNotify>empId=", empId, msgt, meetingPId);
    //     if (msgt.toUser && msgt.toUser === meetingPId) {
    //         if (msgt.t === 'ROOM_LOCKED' || msgt.t === 'ROOM_UNLOCK') {
    //             setLocked(msgt.t === 'ROOM_LOCKED' ? true : false);
    //         } else if (msgt.t === 'REQUEST_TO_JOIN') {
    //             getAsyncEmpDetailsByEmpGuid(msgt.from).then((usrDtls: any) => {
    //                 setRemoteRequests([...remoteRequests, { empGuid: msgt.from, ProfileImageURL: usrDtls.ProfileImageURL, name: usrDtls.FirstName }]);
    //             });
    //         } else {
    //             if (typingUser.length === 0)
    //                 setTypingUser(msgt.from);
    //             else
    //                 setTypingUser(typingUser + " and " + msgt.from);
    //             setTimeout(() => {
    //                 setTypingUser('');
    //             }, 2000);
    //         }
    //     } else if (msgt.from === meetingPId && msgt.toUser !== empId) {
    //         console.log("to other remote users");
    //         const reqs = remoteRequests.filter(req => req.empGuid !== msgt.toUser);
    //         setRemoteRequests([...reqs]);
    //     } else if (msgt.toUser === empId) {
    //         const reqs = remoteRequests.filter(req => req.empGuid !== empId);
    //         setRemoteRequests([...reqs]);
    //         console.log("to user waiiting to join the call", msgt);
    //         if (msgt.t === 'ROOM_ADMIT') {
    //             setWaitingMsg("Accepted your request, joining into the room...")
    //             makeJoin();
    //             setShowWaiting(false);
    //         } else if (msgt.t === 'ROOM_REJECT') {
    //             setShowWaiting(false);
    //             globalStore.showToast({
    //                 type: 'Error',
    //                 title: "Deny Message",
    //                 message: 'Denied to join the room'
    //             });
    //             history('/pod');
    //         }
    //     }
    // }
    // const toggleChat = () => {
    //     if (chatWindow) {
    //         setChatWindow(false);
    //         setPwindow(true);
    //     } else {
    //         setPwindow(false);

    //         setChatWindow(true);
    //         setNewMsg(false);
    //     }
    // }
    // const toggleParticipants = () => {
    //     if (pWindow) {
    //         setPwindow(false);
    //     } else {
    //         setPwindow(true);
    //         setChatWindow(false);
    //     }
    // }
    // const closeSideBar = () => {
    //     setChatWindow(false);
    //     setPwindow(false);
    // }
    // const postRoomStatus = async () => {
    //    // console.log('post last activity....')
    //     const form = new FormData();
    //     form.append("GroupGuid", roomDtls.RoomGuid);
    //     form.append("EmployeeGuid", empId);
    //     await UpdateLastActivityTime(form);
    // }

    const [showChillMenu, setshowChillMenu] = useState(false);
    const [showCommunityMenu, setshowCommunityMenu] = useState(false);

    const handleShowChillMenu = () => {
        setshowChillMenu(!showChillMenu);
        setshowCommunityMenu(false);
    }

    const handleShowCommunityMenu = () => {
        setshowCommunityMenu(!showCommunityMenu);
        setshowChillMenu(false);
    }

    function gotoPod() { history("/pod"); }
    return (
        <section className="overflow-hidden position-relative h-100 SocializeAndHang_PODBG hangout-bg"
            style={{ "backgroundSize": "cover", "backgroundPosition": "center" }}>
            <WindowTitle title="Hangout Room" />
            <div className="d-flex h-100 text-center w-100" >
                <div className="align-self-center m-auto position-relative w-100">
                    <div className="SocializeLogo c-pointer" onClick={gotoPod}>
                        <img src={user.orgData.LogoURL ? user.orgData.LogoURL : "/assets/media/images/sute-logo.png"} />
                    </div>
                    <ul className="SocializeMenu">
                        <li>
                        <Link  to={Chill[0].link}>
                            <div className={showChillMenu ? "SHMenuItem SHMenu-active" : "SHMenuItem"} >
                                <img src="./assets/media/images/hangouts/meditate.svg" alt="Chill Icon" />
                                <p>{t('hangoutroom.health')}</p>
                            </div>
                            </Link>
                        </li>
                        <li>
                        <Link  to={community[0].link}>
                            <div className={showCommunityMenu ? "SHMenuItem SHMenu-active" : "SHMenuItem"} >
                                <img src="./assets/media/images/hangouts/friends.svg" alt="Socialize Icon" />
                                <p>{t('hangoutroom.community')}</p>
                            </div>
                            </Link>
                        </li>
                        {/* <li>
                            <div className="SHMenuItem">
                                <img src="./assets/media/images/hangouts/gamepad.svg" alt="Play Icon" />
                                <p>{t('hangoutroom.play')}</p>
                            </div>
                        </li> */}
                    </ul>
                </div>

            </div>
            {/* {pWindow ? <Participants meetingId={meetingId} closeSideBar={closeSideBar}
                geLastModifedTime={geLastModifedTime}
                remoteStreams={remoteStreams} remoteReqs={remoteRequests}
                admitRequestedUsr={admitRequestedUsr}
                rejectRequestedUsr={rejectRequestedUsr} isCalendarMeeting={true} /> : ""}

            {chatWindow ? <MiniRoomChatWindow meetingId={props.meetingId} closeSideBar={closeSideBar} /> : ''} */}

            {/* {showChillMenu ? <HangoutMenu meetingId={meetingPId} arr={Chill} showChillMenu={showChillMenu} showCommunityMenu={showCommunityMenu} setShowChillMenu={setshowChillMenu} setShowCommunityMenu={setshowCommunityMenu} /> : (!showChillMenu && !showCommunityMenu)?<div className="open-sidemenu" onClick={() => { setshowChillMenu(true) }}>
               
                <span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
            </div>:''}
            {showCommunityMenu ? <HangoutMenu meetingId={meetingPId} arr={community} showChillMenu={showChillMenu} showCommunityMenu={showCommunityMenu} setShowChillMenu={setshowChillMenu} setShowCommunityMenu={setshowCommunityMenu} /> : ''} */}
        </section>
    );
}

export default HangoutRoom;