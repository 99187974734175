import WellnessMenu from "./wellnessMenu";
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { ShowToast } from "../../services/UserActions";
import * as T from "../../actions/Types";
import { getEmployeeGoals, getMetrics, SaveEmployeeGoals  } from "../../services/WellnessServices";
function HealthTracking(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const empGuid = localStorage.getItem('EmpGuid');
    const [EmployeeGoals, setEmployeeGoals] = useState<any[]>([]);
    const [metrics, setMetrics] = useState<any[]>([]);
    const [EmployeeData, setEmployeeData] = useState<any[]>([]);
    const [updateGoal, setUpdateGoal] = useState(false);
    const [handleSideNav,setHandleSideNav] = useState(true);
    var Goals = ['goals'];
    useEffect(() => {
        dispatch({
            type: T.ADD_LOADING
        })
        getMetrics().then((resp: any) => {
            if (resp.status == "success"){
                setMetrics([...resp.result])
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if(resp == "Error"){
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        });
        dispatch({
            type: T.ADD_LOADING
        })
        getEmployeeGoals(empGuid).then((resp: any) => {
            setEmployeeGoals([...resp.result])
            setEmployeeData([...resp.result])
            if (resp.status == "success"){
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if(resp == "Error"){
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        });
    }, [])

    useEffect(() => {
        dispatch({
            type: T.ADD_LOADING
        })
        getEmployeeGoals(empGuid).then((resp: any) => {
            setEmployeeGoals([...resp.result])
            if (resp.status == "success"){
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if(resp == "Error"){
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        });
        
    }, [updateGoal])



    function headerRender() {
        return (
            <tr>
                <th></th>
                <th>Metric</th>
                <th>Current</th>
                <th>Goal</th>
                <th>Update</th>
                <th>Status</th>
                <th>Completion</th>
            </tr>);
    }
    const MetricDropDown = (event: any, index: number) => {
        let value = event.target.value;
        let goal
        let current
        let metricId
        let goalId
        EmployeeGoals.filter((row: any) => {
            debugger;
            if (value == row.MetricId) {
                debugger
                goal = row.Goal
                current = row.Current
                metricId = value
                goalId = row.GoalId
            }
        })
        setEmployeeData([...EmployeeData.slice(0, index),
        {
            ...EmployeeData[index],
            Goal: goal,
            Current: current,
            MetricId: metricId,
            GoalId: goalId
        },
        ...EmployeeData.slice(index + 1)
        ] as any)
    }

    const isUnique = (Goalname: any) => {
        let unique = true;
        Goals.filter(item => {
            if (item == Goalname) {
                unique = false
            }
        });
        if (unique) {
            Goals.push(Goalname)
        }
        return unique
    }
    const UpdateEmployeeGoals = (index: number) => {
        dispatch({
            type: T.ADD_LOADING
        })
        SaveEmployeeGoals ([EmployeeData[index]]).then((resp: any) => {
            if (resp == 'success') {
                setUpdateGoal(!updateGoal);
                let toast = {
                    type: 'Message',
                    title: "Status",
                    message: 'Updated successfully'
                };
                dispatch(ShowToast(toast) as any)
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
            else if (resp == "Error"){
                let toast = {
                    type: 'Message',
                    title: "Status",
                    message: 'Something Went Wrong'
                };
                dispatch(ShowToast(toast) as any)
                dispatch({
                    type: T.REMOVE_LOADING
                })
            }
        });

    }
    function rowRender(row: any, index: number) {
        if (EmployeeGoals.length <= EmployeeData.length && isUnique(row.GoalName)) {
            return (<tr key={index}>
                <td>{row.GoalName}</td>
                <td className="w-m-content">
                    <select className="SuteInputPrimary h40px" onChange={(event) => MetricDropDown(event, index)} >
                        {metrics.map((metric: any) => {
                            if (metric.GoalId === row.GoalId) {
                                return <option key={metric.MetricId} value={metric.MetricId}>{metric.Name}</option>
                            }
                        }
                        )
                        }
                    </select></td>
                <td>
                    <input key={row.GoalId} value={EmployeeData[index].Current}
                        onChange={(event) => (EmployeeData[index].MetricId != 0) ? setEmployeeData([...EmployeeData.slice(0, index),
                        {
                            ...EmployeeData[index],
                            Current: (event.target.value <= EmployeeData[index].Goal) ? event.target.value : EmployeeData[index].Current,
                        },
                        ...EmployeeData.slice(index + 1)] as any) : null}
                        type="number" className="form-control" placeholder="Enter Current" />
                </td>
                <td>
                    <input key={row.GoalId} value={EmployeeData[index].Goal}
                        onChange={(event) => (EmployeeData[index].MetricId != 0) ? setEmployeeData([...EmployeeData.slice(0, index),
                        {
                            ...EmployeeData[index],
                            Goal: (event.target.value >= EmployeeData[index].Current) ? event.target.value : EmployeeData[index].Goal,
                        },
                        ...EmployeeData.slice(index + 1)
                        ] as any) : null} type="number" className="form-control" placeholder="Enter Goal" />
                </td>
                <td>
                    <button type="button" className="Update-btn" onClick={() => UpdateEmployeeGoals(index)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path></svg>
                    </button>
                </td>
                <td>
                    <span className={(Math.floor(((EmployeeData[index].Current / EmployeeData[index].Goal) * 100)) > 50) ? "status-bubble" : (Math.floor(((EmployeeData[index].Current / EmployeeData[index].Goal) * 100)) <= 0) ? "status-bubble status-bubble-0per" : "status-bubble status-bubble-50per"}></span>
                </td>
                <td>
                    {/* <span className={`Progress-bar progress-bar-${(row.Current/row.Goal)*100}per`}>{Math.floor((row.Current/row.Goal)*100)}%</span> */}
                    <div className="progress-bar" style={{ background: `radial-gradient(closest-side, #3b4163 75%, transparent 80% 100%), conic-gradient(${(Math.floor(((EmployeeData[index].Current / EmployeeData[index].Goal) * 100)) > 50) ? `green` : `orange`} ${(EmployeeData[index].Goal <= 0 || EmployeeData[index].Current > EmployeeData[index].Goal) ? 0 : Math.floor(((EmployeeData[index].Current / EmployeeData[index].Goal) * 100))}%, #fff 0)` }}>
                        <span>{(EmployeeData[index].Goal <= 0 || EmployeeData[index].Current > EmployeeData[index].Goal) ? 0 : Math.floor(((EmployeeData[index].Current / EmployeeData[index].Goal) * 100))}%</span><progress value="75" max="100" style={{ visibility: "hidden", height: "0", width: "0" }}></progress>
                    </div>
                </td>
            </tr>);
        }
        else {
            return;
        }
    }

    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            {(handleSideNav)?<WellnessMenu type={[{ name: "Goals", link: "/assessment", image: "assets/media/svg/sute_selections.svg", select: false }, { name: "Health Tracking", link: "/healthtracking", image: "assets/media/svg/wellness.svg", select: true }]} setHandleSideNav={setHandleSideNav}/>:<div className="open-sidemenu" onClick={() => { setHandleSideNav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>}
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to={"/hangoutroom/" + media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                                <div className="cRTitle p-3"><h5>Health & Wellness / Health Dashboard / Health Tracking</h5></div>
                            </div>
                            <div className="col-12 p-0">
                                <div className="card bgCadet mb-3 overflow-auto" style={{ height: "74vh" }}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <table id={props.id} className="table border-secondary small filesTable mb-0">
                                                    <thead>
                                                        {headerRender()}
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            (EmployeeGoals || []).map((row: any, index: number) => {

                                                                return rowRender(row, index)
                                                            })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex text-center">
                                    <ul className="fileTablePagination small"><li>
                                        <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fillRule="evenodd" clipRule="evenodd" d="M2.707 8l3.147 3.146-.708.707L.793 7.5l4.353-4.354.708.708L2.707 7H14v1H2.707z" fill="#fff"></path></svg></li>
                                        <li className="active">1</li>
                                        <li>
                                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.854 3.146L14.207 7.5l-4.353 4.354-.708-.708L12.293 8H1V7h11.293L9.146 3.854l.708-.708z" fill="#fff"></path>
                                            </svg>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="text-right mt-3 opacity color-white watermark">
                    <h4>Under Construction</h4>
                </div> */}
            </div>
        </section>
    </>)
}

export default HealthTracking;