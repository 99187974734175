import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import HangoutMenu from "../onboarding/HangoutMenu";
import { useDispatch, useSelector } from "react-redux";
import { GetMediaTracksList } from "../../services/MediaService";


function suteSelections(props: any) {
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);

    useEffect(() => {
        if (!media.mediaTrackList || media.mediaTrackList.length == 0)
        {
            dispatch(GetMediaTracksList() as any);
        }
    }, [])

    return (<>

        <section className="myProfilePage position-relative d-flex h-100">
            <HangoutMenu enableHangout="true" />
            <div className="SideNav text-left position-fixed d-none">
                <div className="sideNavHeader">
                    <div className="CloseNav"> <img src="assets/media/svg/x-close.svg" alt="Close Side Nav" title="Close" /> </div>
                </div>
                <div className="sideNavBody">
                    <div className="row mt-4 small">
                        <div className="col-12 mb-2 text-light"> <img src="assets/media/svg/builiten-board/the-well.svg" height="30" /> </div>
                    </div>
                    <div className="row small mt-4">
                        <div className="col-12 text-left text-light">
                            <h6 className="m-0">Build a stronger, healthier immune system</h6>
                            <p className="text-muted mb-2">Most of new line of immune suppliments five unique products - each designed to support your unique health needs, lifestyle, preferances and dietary restrictions. </p>
                        </div>
                    </div>
                    <div className="row mt-2 small c-pointer">
                        <div className="col-12 text-left c-SeaGreen"> View more
                            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                                <path d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1" stroke="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container align-self-center">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-10 pb-2">
                                <div className="cRoomHeader pt-2 mb-0">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/hangoutroom/"+media.meetingId}><img src="/assets/media/images/logo.png" /></Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Sute Selections</h5>
                                        {/* <p>All Partners</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (media.mediaTrackList.filter((media:any)=>media.Page == 'hangout')||[]).length > 0 ? 

                        <ul id="suteSelMenu" className="HangOutMenu mt-2">
                            
                        { (media.mediaTrackList.filter((media:any)=>media.Page == 'hangout')||[]).map((media:any) =>
                            <li className="OBhex">
                                <Link className="OBhexIn active" to={"/"+media.MediaType.replace(' ','-')} style={{ backgroundImage: 'url('+media.Background+')', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <p> <span className="align-self-center">
                                        <img src={media.Thumbnail} />
                                        <u className="OBhexTitle">{media.MediaType}</u> </span> </p>
                                </Link>
                            </li>
                            ) 
                        }
                        </ul>
                        :
                            <div className="info-center">
                                <p>No sute selections found</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>

    </>
    );
}


export default suteSelections;