import { useSelector, useDispatch } from "react-redux";
import HangoutMenu from "../onboarding/HangoutMenu";
import { Link, useNavigate , useLocation } from "react-router-dom";
import { GetChallengesStepList } from "./../../services/WellnessServices";
import { useState, useEffect } from "react";
import * as T from "../../actions/Types";
import challengesSteps from "./challengesSteps";
import Accordion from "react-bootstrap/Accordion";

 
function Challenges(props : any) {
    const arr = [
        { name: "Content", link: "/information" },
        { name: "Challenges", link: "/challenges" },
        { name: "Classes", link: "/classes" },
    ];
    const dispatch = useDispatch();
    const media = useSelector((store: any) => store.MediaReducer);
    const [challengesSidenav,setChallengesSidenav] = useState(true);
    const [GetChallenge,setGetChallenge] = useState([] as any)
    const myOrgId = localStorage.getItem('OrgGuid');
    
    
    useEffect(() => {
        if (GetChallenge.length < 0 || []) {
            dispatch({
                type: T.ADD_LOADING
            })
            GetChallengesStepList(myOrgId)
                 .then((resp: any) => {
                     const mergedData = mergeChallenges(resp.result)
                     setGetChallenge(mergedData)
                    console.log("getting data",resp.result)

                    if (resp.status === "success") {
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                    else if (resp === "error") {
                        dispatch({
                            type: T.REMOVE_LOADING
                        })
                    }
                })
                .catch((e : any) => {
                    dispatch({
                        type: T.REMOVE_LOADING
                    })

                    return "error"
                })
        }else {
            const mergedData = mergeChallenges(GetChallenge);
            setGetChallenge(mergedData)

        }
        console.log("Getting data in challenges",GetChallenge)
    }, [ ])

    function mergeChallenges(data : any) {
        const mergedChallenges = new Map();
      
        data.forEach((item: any) => {
          if (mergedChallenges.has(item.ChallengeId)) {
            mergedChallenges.get(item.ChallengeId).ChallengesInfo.push(item.ChallengesInfo);
          } else {
            mergedChallenges.set(item.ChallengeId, {
              ...item,
              ChallengesInfo: [item.ChallengesInfo]
            });
          }
        });
      
        return Array.from(mergedChallenges.values());
      }
    return (
        <>
        
            <section className="myProfilePage position-relative d-flex mih-100">
                {/* {(challengesSidenav)?<HangoutMenu arr={arr} type="Challenges" setChallengesSidenav={setChallengesSidenav}/>:<div className="open-sidemenu" onClick={() => { setChallengesSidenav(true) }}>

<span><svg className="menu-icon" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 18h12v-2H9v2zM3 6v2h18V6H3zm6 7h12v-2H9v2z"></path></svg></span>
</div>} */}
                <div className="container">
                    <div className="container-fluid">
                        <div className="row mt-4">
                            <div className="col-10">
                                <div className="cRoomHeader pt-2">
                                    <div className="cRsuteLogo align-self-center">
                                        <Link to={"/assessment"}>
                                            <img src="/assets/media/images/logo.png" />
                                        </Link>
                                    </div>
                                    <div className="cRTitle p-3">
                                        <h5>Health & Wellness / Wellness Hub / Wellness initiatives</h5>
                                    </div>
                                </div>
                                <div className="col-10 ml-auto">                       
                                {GetChallenge && GetChallenge.length > 0 ? (
                                    GetChallenge.map((item: any, index: number) => (
                                    <Accordion key={index}>
                                        <Accordion.Item eventKey={item.ChallengeId}>
                                        <Accordion.Header>
                                            {item.ChallengeId ? (
                                            <>
                                                <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeWidth="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                xmlns="http://www.w3.org/2000/svg"
                                                >
                                                <path d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A1 1 0 0 0 5 3v18a1 1 0 0 0 .536.886z"></path>
                                                </svg>
                                                {item.ChallengeName}
                                            </>
                                            ) : null}                                   
                                        </Accordion.Header>
                                        {item.ChallengesInfo.length > 0 && (
                                            <Accordion.Body>  
                                            {item.ChallengesInfo.map((challenge: any) => (
                                                <div key={challenge.StepId}>
                                                <Link
                                                    to='/challengesSteps'
                                                    state={{ ChallengeGuid: item.ChallengeGuid, stepId: challenge.StepId }}
                                                >
                                                    {challenge.Description}
                                                </Link>
                                                </div>
                                            ))}                                                        
                                            </Accordion.Body>
                                        )}
                                        </Accordion.Item>                                 
                                    </Accordion>
                                    ))
                                        ) : (
                                            <div className="text-center no-data-text-center">
                                                 <h3 className="color-white">No challenges found</h3>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             </section>
        </>
    );
}
export default Challenges;

  {/* {GetChallenge?.length > 0 && GetChallenge.map((item: any) =>(
                                            <div key={item.ChallengeId}>
                                                        <Link
                                                        to={GetChallenge[index] ? '/challengesSteps' : '/'}
                                                        state={{ ChallengeGuid: item.ChallengeGuid, stepId: item.ChallengesInfo.StepId }}
                                                        style={{ color: 'green' }}
                                                        >
                                                        {item.ChallengesInfo.Description}
                                                        </Link>
                                                    </div>
                                        ))} */}
