import WellnessMenu from "./wellnessMenu";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
function YourActivity(props: any) {
    return (<>
        <section className="myProfilePage position-relative d-flex mih-100">
            <WellnessMenu />
            <div className="container">
                <div className="container-fluid">
                    <div className="row mt-4">
                        <div className="col-10">
                            <div className="cRoomHeader pt-2">
                                <div className="cRsuteLogo align-self-center">
                                    <Link to="/sute-selections"><img src="/assets/media/images/logo.png" /></Link>
                                </div>
                                <div className="cRTitle p-3"><h5>Tracking</h5></div>
                            </div>
                            <div className="col-12 p-0 row m-0" style={{ justifyContent: "end" }}>

                                <div className=" row m-0 tracking-section">
                                    <div className="col-12 row m-0 mb-3">
                                        <h5 className="col-4 mt-auto mb-auto text-right">YOUR ACTIVITY</h5>
                                        <div className="col-6 m-0 activity">
                                            <select className="SuteInputPrimary h40px mr-3">
                                                <option value="select">Daily</option>
                                                <option value="select">Weekly</option>
                                                <option value="select">Monthly</option>
                                            </select>
                                            <select className="SuteInputPrimary h40px">
                                                <option value="select">Steps</option>
                                                <option value="select">Minutes</option>
                                                <option value="select">Calories</option>
                                                <option value="select">Distance</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 row m-0"><h5 className="col-4 m-auto text-right p-0">CONNECTED DEVICES AND APPS</h5><div className="col-8"><img src="assets/media/images/devices-image.png" className="device-img"/><span className="color-white">No Devices/Apps have been connected</span></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-right mt-3 opacity color-white watermark">
                    <h4>Under Construction</h4>
                </div>
            </div>
        </section>
    </>)
}

export default YourActivity;