import { Table } from "../../components/Table";
import { FilesTableBody } from "../../interfaces/EmployeeData";
import ConfirmPopup from "../../layout/ConfirmPopup";
import { GetPreferredContacts, removeSuccessStatus } from "../../services/ContactsService";
import { globalStore } from "../../store/global_store";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import clsx from 'classnames';
import { t } from '../../i18n';
import { getOrgList, DeleteOrgByGuid, ShowToast } from "../../services/UserActions";
import FileIcon from "../../components/FileIcon";
import { useNavigate, useParams } from "react-router-dom";
import { result } from "lodash";

var deleteOrgGuid: string;
var deleteText: string = '';
const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer,
    employee: state.EmployeeReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    getOrgList: (params: FilesTableBody, IsDeleted: any) => {
        dispatch(getOrgList(params, IsDeleted))
    },
    DeleteOrgByGuid: (orgGuid: any) => {
        dispatch(DeleteOrgByGuid(orgGuid))
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    },
    ShowToast: (toast: any) => {
        dispatch(ShowToast(toast));
    },
})

function Companies(props: any) {
    let params: any = useParams();
   // const URL = process.env.REACT_APP_API as string;
    const [orgList, setorgList] = useState('');
    const [filesTableParams, setFilesTableParams] = useState(new FilesTableBody());
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const [showConfirmDeleteOrg, setShowConfirmDeleteOrg] = useState(false);
    const [apiStatus, setApiStatus] = useState('');
    const history = useNavigate();

    useEffect(() => {
        props.getOrgList(new FilesTableBody());
    }, [])

    useEffect(() => {
        if (props.user.orgList) {
            props.user.orgList.forEach((org: any, i: any) => {
                org.TotalCount = props.user.orgList.length, org.RowNumber = i + 1
            });

            setorgList(props.user.orgList.filter((org: any) =>
                (org.RowNumber <= 8)));
        }
        else
            setorgList(props.user.orgList);
    }, [props.user.orgList])

    useEffect(() => {
        if (props.user.orgList) {
            const orgs = props.user.orgList.filter((org: any) =>
                (org.OrgName.toLowerCase().includes(filesTableParams.Search.toLowerCase())) ||
                org.TagLine.toLowerCase().includes(filesTableParams.Search.toLowerCase()) ||
                (org.IsDeleted ? 'inactive'.startsWith(filesTableParams.Search.toLowerCase())  
                         : 'live'.startsWith(filesTableParams.Search.toLowerCase()))
            );
            orgs.forEach((org: any, i: any) => {
                org.TotalCount = orgs.length, org.RowNumber = i + 1
            });

            setorgList(orgs.filter((org: any) =>
                org.RowNumber > ((filesTableParams.PageNo - 1) * filesTableParams.PageSize)
                && org.RowNumber <= (filesTableParams.PageNo * filesTableParams.PageSize))
            );
        }
    }, [filesTableParams])

    useEffect(() => {
        if (props.user.success) {
            props.ShowToast({
                type: 'Message',
                title: deleteText + " Company",
                message: props.user.success
            });
            props.RemoveSuccessStatus();
            //setApiStatus(props.user.success);
            if (props.user.success == 'Updated successfully.') {
                const orgData = getOrg(deleteOrgGuid);
                orgData.IsDeleted = !orgData.IsDeleted
            }
            //props.ShowToast('Message', props.user.success, deleteText+ " Company");
        }
    }, [props.user.success])

    function getOrg(orgGuid: any): any {
        const orgData = props.user.orgList.find((org: any) => org.OrgGuid === orgGuid);
        return orgData;
    }

    const handleShowDeleteOrg = (e: any, org: any) => {
        deleteOrgGuid = org.OrgGuid;
        deleteText = org.IsDeleted ? "Live" : "Inactive";
        setShowConfirmDeleteOrg(!showConfirmDeleteOrg);
    }

    const handleCloseDeleteOrg = (e: any) => {
        setShowConfirmDeleteOrg(!showConfirmDeleteOrg);
    }
    const deleteOrg = (e: any) => {
        props.DeleteOrgByGuid(deleteOrgGuid);
        setShowConfirmDeleteOrg(!showConfirmDeleteOrg);
    }

    const editCompany = (e: any, row: any) => {
        let path = `/company?id=${row.OrgGuid}`;
        history(path);
    }

    function headerRender() {
        return (
            <tr>
                <th>Company</th>
                <th>Tag Line</th>
                <th>Created Date</th>
                <th>Status</th>
                <th className="mobile-none"></th>
            </tr>);
    }

    function rowRender(row: any, index: number) {
        return (<tr key={index}>
            <td>{row.OrgName}</td>
            <td>{row.TagLine}</td>
            <td>{row.CreatedDateStr}</td>
            <td>{row.IsDeleted ? "Inactive" : "Live"}</td>
            <td className="text-right dropdown mobile-none">
                <svg id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.5 7.5a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0zm5 0a1 1 0 112 0 1 1 0 01-2 0z" fill="#fff"></path>
                </svg>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    {row.IsDeleted?  null : <a onClick={e => editCompany(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M.5 9.5l-.354-.354L0 9.293V9.5h.5zm9-9l.354-.354a.5.5 0 00-.708 0L9.5.5zm5 5l.354.354a.5.5 0 000-.708L14.5 5.5zm-9 9v.5h.207l.147-.146L5.5 14.5zm-5 0H0a.5.5 0 00.5.5v-.5zm.354-4.646l9-9-.708-.708-9 9 .708.708zm8.292-9l5 5 .708-.708-5-5-.708.708zm5 4.292l-9 9 .708.708 9-9-.708-.708zM5.5 14h-5v1h5v-1zm-4.5.5v-5H0v5h1zM6.146 3.854l5 5 .708-.708-5-5-.708.708zM8 15h7v-1H8v1z" fill="currentColor"></path></svg> Edit</a>}
                    <a onClick={e => handleShowDeleteOrg(e, row)} className="dropdown-item small pl-3"><svg className="mr-2" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4.5 3V1.5a1 1 0 011-1h4a1 1 0 011 1V3M0 3.5h15m-13.5 0v10a1 1 0 001 1h10a1 1 0 001-1v-10M7.5 7v5m-3-3v3m6-3v3" stroke="currentColor"></path></svg> {row.IsDeleted ? "Live" : "Inactive"}</a>
                </div>
            </td>
        </tr>);
    }

    function CreateCompany( ) {
        history('/company');
    }

    return (<>
        <ConfirmPopup show={showConfirmDeleteOrg} close={handleCloseDeleteOrg} okHandle={deleteOrg} message={"Do you want to " + deleteText + " this company?"} />
        <section className="myProfilePage h-100 overflow-hidden position-relative d-flex">
            <div className="container-fluid">
                {apiStatus ? <p className="alert alert-danger col-2 ml-auto mr-auto mt-3 text-center">{apiStatus}</p> : ''}
                <div className="row mt-4 justify-content-center">
                    <Table
                        id='companies'
                        data={orgList || []}
                        headerRender={headerRender}
                        rowRender={rowRender}
                        onSearch={(value: string) => setFilesTableParams({ ...filesTableParams, Search: value || '' })}
                        onPageChange={(value: number) => setFilesTableParams({ ...filesTableParams, PageNo: value || 1 })}
                        createLabel="Create Company"
                        rootUrl="/pod"
                        //createUrl="/company"
                        onCreate={() => CreateCompany()}
                        header="Companies"
                        subHeader=""
                        noDataMsg="No companies found"
                        {...props}>
                    </Table>
                </div>
            </div>
        </section>
    </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);