import React, { useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

function CallPopup(props: any) {
    
    const modelRef = useRef<HTMLDivElement>();  
    
    let temp = props.hasAudio && props.hasCamera ? 'Camera and Microphone' : (props.hasAudio ? 'Microphone' : 'Camera');
    let modelHeader = props.hasAudio && props.hasCamera ? 'Camera and Microphone are blocked' : (props.hasAudio ? 'Microphone is blocked' : 'Camera is blocked');

    let modelMsg = `Check your browser or device permissions: Ensure that your browser or device is allowing the application to access your `+temp+`. You may need to check your browser settings <img className="actived" src="/assets/media/images/collaborate/camera-block.svg" alt="" /> or device settings to confirm that allowed to access your `+temp;
    if(props.errType > 2){ 
         temp = props.errType == 5 ? 'Camera and Microphone' : (props.errType == 3 ? 'Microphone' : 'Camera');
        // modelHeader = props.errType == 5 ? ' Can\'t find Camera and Microphone ' : (props.errType == 3 ? 'Can\'t find Microphone' : 'Can\'t find Camera');
         modelHeader = `Can't find `+ temp;
         modelMsg = `Check `+temp +` in your device: Ensure that your device's `+ temp +` settings are configured correctly.Ensure that the `+temp+` drivers are up to date and installed correctly on your device`;        
    }
    useEffect(() => {
        

    }, [])
    return (
        
        <Modal ref={modelRef} show={props.show} onHide={props.close} backdrop="static" keyboard={false} className="text-light">
            <Modal.Body>
                <div className="modal-header border-0">
                    <h5 className="modal-title" id="confirmPopupLabel">{modelHeader}</h5>
                    {/* <img onClick={props.close} className="float-right c-pointer" data-dismiss="modal" src="/assets/media/svg/x-close.svg" />*/} </div> 
                    <div className="modal-body" dangerouslySetInnerHTML={{__html:modelMsg}}></div>
                <div className="modal-footer border-0">
                    <button onClick={props.close} type="button" className="btn btn-secondary" data-dismiss="modal">{'OK'}</button>
                    
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default React.memo(CallPopup);