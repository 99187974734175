/**
 * Encapsulation of the identity of the user.
 */
export default class Identity {
    private account: any;
    private rawIdToken: any;
    public accessToken:any;
    public tokenType: string;

    constructor(tokenResponse: any) {
      this.account = tokenResponse.account;
      this.rawIdToken = tokenResponse.idToken.rawIdToken;
      this.accessToken = tokenResponse.accessToken
      this.tokenType = tokenResponse.tokenType;
    }
  
    get userId() {
      return this.account.accountIdentifier;
    }
  
    get emailAddress() {
      return this.account.userName;
    }
  
    get name() {
      return this.account.name;
    }
  
    get idToken() {
      return this.rawIdToken;
    }
  }