import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { t } from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
function getRoomId() {
    return uuidv4();
}

function ShareMeetingLink(props: any) {    
	const [meetingId, setMeetingId] = useState('');
 	const api_URL = process.env.REACT_APP_URL as string;


	const history = useNavigate();
	useEffect(()=>{
		setMeetingId(getRoomId());
	},[]);

    const closeActivityWindows = ()=>{
        props.handleShowSML();        
	}
	const startMeeting = ()=>{
		history(`/customrm/${meetingId}`);
	}
    return (<>
    <div className={props.showSML ? "SideNav text-left position-fixed" : "SideNav text-left position-fixed d-none"}>
        <div className="sideNavHeader">
            <div className="CloseNav" onClick={closeActivityWindows}> <img src="/assets/media/svg/x-close.svg" /> </div>
        </div>
        <div className="sideNavBody" id="inviteParticipant">
					<div className="row mt-4 small">
						<div className="col-12 mb-2 text-light">
						{t('title.share_sute_link')}
						</div>
					</div>
					<div className="row">
						<div className="col-12 mt-3">
							<p className="text-light m-0">{t('invitetomeeting.share_msg_link')}</p>
							<p className="fs12 text-muted">{t('invitetomeeting.share_msg')}</p>
						</div>
					</div>
					<div className="row mt-3 small">
						<div className="col-12 mb-2 position-relative">
							<input type="text" className="SuteInputPrimary fs12" disabled value={`${api_URL}/customrm/${meetingId}`} />
							<span className="inviteBtn"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" fill="#30D5C8"/></svg></span>
						</div>
						<div className="col-12 mb-2">
							<input type="text" className="SuteInputPrimary fs12" placeholder={t('login.email')} />
						</div>
						<div className="col-12 mb-2 mt-1">
							<button className="btn-SeaGreen col-7 mr-3 btn-sm btn fw600 fs12"><img className="inviteBtnShare" src="/assets/media/svg/share-alt.svg" /> {t('button.send_invite')}</button>
							<button onClick={startMeeting} className="btn-SeaGreen col-4 btn-sm btn fw600 fs12"> {t('button.start')}</button>
						</div>
					</div>
				</div>
    </div>
   
    </>);
}


export default ShareMeetingLink;