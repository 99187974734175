import { Loader } from "../../components/Loader";
import { removeSuccessStatus } from "../../services/ContactsService";
import { SendForgetPasswordEmail, ShowToast } from "../../services/UserActions";
import { globalStore } from "../../store/global_store";
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { Login as Login1 } from "../../actions/AuthActions";
import { t } from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';

function ForgetPassword(props: any) {
    const [userEmail, setUserEmail] = useState('')
    const [error, setError] = useState('');
    const [isSaveInprogress, setIsSaveInprogress] = useState(false);
    const [showError, setShowError] = useState(false)
    const history = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.user.success) {
            dispatch(ShowToast({
                type: 'Password',
                title: "Reset Password",
                message: props.user.success
            }) as any);
            props.RemoveSuccessStatus();
            if(props.user.success=='Mail sent successfully'){
            setTimeout(() => history('/pod'), 1000);
            }
        }
    }, [props.user.success])

    const sendEmail = (e: any) => {
        setIsSaveInprogress(true);
        e.preventDefault();
        if (userEmail.length > 0) {
            if (validateEmail(userEmail)) {
            props.SendForgetPasswordEmail(userEmail);
            }
            else{
                dispatch(ShowToast({
                    type: 'Password',
                    title: "Reset Password",
                    message: "Enter valid Email"
                }) as any); 
            }
        }
        else if (userEmail == '') {
             dispatch(ShowToast({
                type: 'Password',
                title: "Reset Password",
                message: "Enter Email"
            }) as any); 
        }
        setTimeout(() => {
            setIsSaveInprogress(false);
        }, 1000);
    }

    const validateEmail = (email: string) => {
        var validEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return validEmail.test(email);
    }

    return (<>
        <section className="bgCadet h-100 overflow-hidden position-relative d-flex">
            <div className="container align-self-center">
                {/* <PublicHeader title="Login" /> */}
                <div className="row">
                    <div className="col-sm-4 m-auto">
                        <div className="card loginBox">
                            <div className="card-body pl-5 pr-5">
                                <div className="row">
                                    <div className="col-12 text-center"> <img className="loginLogo" src="/assets/media/images/logo-sm.png" alt="Sute" /> </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-4 mt-4 text-center text-white">
                                        <h5 className="font-weight-normal">{t('forgetpassword.forget_password')}</h5>
                                        <p>{t('forgetpassword.reset_msg')}</p>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="text-light small m-0">{t('forgetpassword.email')}</label>
                                    <input onChange={(e: any) => [setUserEmail(e.target.value), setError('')]} className="form-control" type="email" name="email" placeholder="Enter your email" />
                                </div>
                                <div className="form-group mt-4">
                                    <button onClick={sendEmail} className="btn btn-SeaGreen btn-block font-weight-bold pl-5 pr-5 float-right" disabled={isSaveInprogress}>Get Link{props.user.loading ? <Loader /> : null}</button> </div>
                                <p className="col-12 text-white">{error}</p>
                            </div>
                        </div>

                        {/* <div className="form-group mt-3">
                            <p className="text-light small text-center">{t('forgetpassword.dont_have_an_account')} <Link className="text-light ml-1" to="/signup">{t('forgetpassword.sign_up')}</Link></p>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    </>)
}

const mapStateToProps = (state: any) => ({
    user: state.AuthReducer,
    UI: state.uiReducer
});

const mapDispatchToProps = (dispatch: any) => ({
    SendForgetPasswordEmail: (email: string) => {
        dispatch(SendForgetPasswordEmail(email))
    },
    RemoveSuccessStatus: () => {
        dispatch(removeSuccessStatus());
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
