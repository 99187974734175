import React, { useEffect } from 'react'
import { NavLink as Link } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel';  
// import 'owl.carousel/dist/assets/owl.carousel.css';  
// import 'owl.carousel/dist/assets/owl.theme.default.css';
 import PublicHeader from '../layout/PublicHeader';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

import { t } from '../i18n';
import { useSelector } from 'react-redux';
import { StoreClass } from '../store/Store';

function Home(props: any){
	const {user} = useSelector((state: StoreClass) => ({ user: state?.AuthReducer }));
	user.isAuthenticated = false;
console.log("isAuthenticated home",user.isAuthenticated);
    return (<>
        <section className="bgCadet h-100 overflow-hidden position-relative">
        <svg className="homesideimg" viewBox="0 0 1579 1775" fill="none" 
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
			<path d="M835.5 43.3013C881.91 16.5064 939.09 16.5064 985.5 43.3013L1624.02 411.949C1670.43 438.744 1699.02 488.263 1699.02 541.853V1279.15C1699.02 1332.74 1670.43 1382.26 1624.02 1409.05L985.5 1777.7C939.09 1804.49 881.91 1804.49 835.5 1777.7L196.984 1409.05C150.574 1382.26 121.984 1332.74 121.984 1279.15V541.853C121.984 488.263 150.574 438.744 196.984 411.949L835.5 43.3013Z" fill="url(#pattern0)" />
			<path d="M835.5 43.3013C881.91 16.5064 939.09 16.5064 985.5 43.3013L1624.02 411.949C1670.43 438.744 1699.02 488.263 1699.02 541.853V1279.15C1699.02 1332.74 1670.43 1382.26 1624.02 1409.05L985.5 1777.7C939.09 1804.49 881.91 1804.49 835.5 1777.7L196.984 1409.05C150.574 1382.26 121.984 1332.74 121.984 1279.15V541.853C121.984 488.263 150.574 438.744 196.984 411.949L835.5 43.3013Z" fill="#2E3151" fillOpacity="0.6" />
			<defs>
				<pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
					<use xlinkHref="#image0" />
				</pattern>
				<image id="image0" height="1" xlinkHref="/assets/media/images/EarnandLearn_POD.png" />
			</defs>
		</svg>
		<div className="container" style={{"height":"657px"}}>
			<PublicHeader title={t('index.head_line')} />
			<div className="row">
				<div className="col-sm-6">
					<h1 className="font-weight-light mt-5 pt-5 text-white fs72 mbf">{t('index.head_line')}.</h1>
					<Link to="/login" className="btn btn-SeaGreen btn-lg mt-4 font-weight-bold">{t('button.member_login')}</Link>
				</div>
			</div>
		</div>
	</section>

	<section className="bgPlatinum">

		<div className="container">

			<div className="row">
				<div className="col-sm-12">
					<h1 className="font-weight-light pt-5">{t('index.take_look')}</h1>
				</div>

			</div>
		</div>

		<div className="container-fluid">
		{/* <OwlCarousel 
          className="owl-theme"  
          loop  
          autoplay autoplayHoverPause center
          margin={10}>
				<img src="/assets/media/images/Collaborate_POD.jpg" />
				<img src="/assets/media/images/EarnandLearn_POD.png" />
				<img src="/assets/media/images/Produce-POD.png" />
				<img src="/assets/media/images/SocializeAndHang_POD.jpg" />
		</OwlCarousel> */}

			<Swiper 
				slidesPerView={3} spaceBetween={10} navigation={true}
				pagination={{
					clickable: true,
				  }}
				loop={true}   
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Pagination]} className="mySwiper"
				>
				<SwiperSlide><img src="/assets/media/images/Collaborate_POD.jpg" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/EarnandLearn_POD.png" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/Produce-POD.png" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/SocializeAndHang_POD.jpg" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/Collaborate_POD.jpg" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/EarnandLearn_POD.png" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/Produce-POD.png" width="100%"/></SwiperSlide>
				<SwiperSlide><img src="/assets/media/images/SocializeAndHang_POD.jpg" width="100%"/></SwiperSlide>
			</Swiper>			
	  		<div className="col-sm-12 text-center mt-5 pb-100">
				<h4 className="fw600 c-cadet m-0">{t('index.visit')}</h4>
				<p className="c-cadet mb-5">{t('index.visit_line')}</p>
				<div className="hexagonBtn c-pointer">
					<svg viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M71 2.3094C73.4752 0.88034 76.5248 0.880339 79 2.3094L135.952 35.1906C138.427 36.6197 139.952 39.2607 139.952 42.1188V107.881C139.952 110.739 138.427 113.38 135.952 114.809L79 147.691C76.5248 149.12 73.4752 149.12 71 147.691L14.0481 114.809C11.5729 113.38 10.0481 110.739 10.0481 107.881V42.1188C10.0481 39.2607 11.5729 36.6197 14.0481 35.1906L71 2.3094Z" fill="#2E3151" />
					</svg>
					<span className="hexaBtnText">{t('index.book_tour')}</span>
				</div>
			</div>

		</div>
	</section></>)
}

export default Home;