import { Dispatch } from "redux";
import {postRequest, getRequest, putRequest} from "../utils/http";
import * as T  from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;
const api_port = process.env.REACT_APP_API_PORT as string;

/**JSON: {Type": "chat", "Isviewed": 0, "Data": {}, "OrgId": "fdsaf-dfas-fdas-ss",
"EmployeeGuid":string"
}*/
export const saveCheckin = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        
        postRequest(`${api_domian}${api_port}/api/Checkins/Create`,params)
            .then((resp: any) => {
                
                if (resp.status == 'error') {
                    dispatch({
                        type: T.CREATE_CHECKIN_FAILURE,
                        payload: resp
                    })
                } else {
                    dispatch({
                        type: T.CREATE_CHECKIN_SUCCESS,
                        payload: "checkin success"
                    });
                }
            }).catch(e => {
                
                dispatch({
                    type: T.CREATE_CHECKIN_FAILURE,
                    payload: { "status": "error", "message": e}
                })
            });
    } catch (e) {
        
        dispatch({
            type: T.CREATE_CHECKIN_FAILURE,
            payload: { "status": "error", "message": e}
        })
    }
};

export const GetCheckins = (empGuid: string) => async (dispatch: Dispatch) => {
    try {        
        
        getRequest(`${api_domian}${api_port}/api/checkins/GetCheckins?EmployeeGuid=${empGuid}`)
       .then((resp: any)=>{
        
            dispatch({
                type: T.GET_CHECKIN_SUCCESS,
                payload: resp.result
            });           
        }).catch(e=>{
            
            dispatch({
                type: T.SET_ERRORS,
                payload: e
            })
        });
    } catch (e) {
        
        dispatch({
            type: T.SET_ERRORS,
            payload: e
        })
    }
 }
 
 export const updateRating = (params: any) => async (dispatch: Dispatch) => {
    // ;
    try {
        dispatch({
            type: T.ADD_LOADING
        })
        
        postRequest(`${api_domian}${api_port}/api/Checkins/UpdateRating`,params)
            .then((resp: any) => {
                
                if (resp.status == 'error') {
                    dispatch({
                        type: T.UPDATE_RATING_FAILURE,
                        payload: resp
                    })
                } else {
                    dispatch({
                        type: T.UPDATE_RATING_SUCCESS,
                        payload: "update rating success"
                    });
                }
                dispatch({
                    type: T.REMOVE_LOADING
                })    
            }).catch(e => {
                
                dispatch({
                    type: T.UPDATE_RATING_FAILURE,
                    payload: { "status": "error", "message": e}
                })
                dispatch({
                    type: T.ADD_LOADING
                })
            });
    } catch (e) {
        
        dispatch({
            type: T.CREATE_CHECKIN_FAILURE,
            payload: { "status": "error", "message": e}
        })
    }
};
